import React, { useEffect, useState } from 'react';
import burger from '../../../assets/images/burger-menu.svg';
import UserSidebar from '../../../Layout/userSidebar';
import eye1 from '../../../assets/images/eye (1)-grey.svg';
import deleted from '../../../assets/images/delete-grey.svg'
import addTag from '../../../assets/images/add-tag.svg'
import insta from '../../../assets/images/Insta-grey.svg'
import socialCloase from '../../../assets/images/social-close.svg'
import twitter from '../../../assets/images/twiter-grey.svg'
import facebooks from '../../../assets/images/user-social1.svg'
import { putApi, postApi } from '../../../Services/apiService';
import { toast } from 'react-toastify';
import facebookLight from '../../../assets/images/creator-facebook.svg'
import twitterLight from '../../../assets/images/creator-twitter.svg'
import instagramLight from '../../../assets/images/Instagram-Logo.wine 1.svg'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import GoogleCalendar from '../../../assets/images/g-calendar.svg'
import Check from '../../../assets/images/check-circle.svg'
import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg'
import DiscoveryCallModal from '../../CreatorComponents/CreatorSetting/discoveryCallModal'
import BasicsGallery from './basicsGallery';
import BasicsAccount from './basicsAccount';
import BasicsSpecialization from './basicsSpecialization';
import BasicsBilling from './basicsBilling';
import BasicsEmailNotification from './emailNotification';
import dummyProfile from '../../../assets/images/profile3.svg'
import CreatorTags from '../DashboardCreator/creatorTags';

function Basics() {

  const userData = JSON.parse(localStorage.getItem('full_user_detail'));
  const userSocialLink = JSON.parse(localStorage.getItem('user_social_links'))
  const discoveryCall = Number(userData?.discovery_call)
  const [classState, setClassState] = useState(false);
  const [condition, setCondition] = useState('Basics');
  const [connectData, setConnectData] = useState([])
  const [socialLink, setSocialLink] = useState({
    instagram: userSocialLink[0]?.instagram_link || '',
    twitter: userSocialLink[0]?.twitter_link || '',
    facebook: userSocialLink[0]?.facebook_link || '',
  })
  const [userAboutData, setUSerAboutData] = useState({about: '',})
  const [imagePreview, setImagePreview] = useState('');
  const [isModal, setIsModal] = useState(false)
  const [discoveryModal,setDiscoveryModal] = useState(false || discoveryCall == '1' && true)
  console.log("isModalisModalisModalisModal",isModal);
  
  const [calendarModal, setCalendarModal] = useState(false)

  const [showImagePreview, setShowImagePreview] = useState('');
  const [updateData, setUpdateData] = useState([])
  const [updateProfileData, setUpdateProfileData] = useState(false);
  const [socialLinkUpdate, setSocialLinkUpdate] = useState({
    instagram: '',
    twitter: '',
    facebook: '',
  })
  const [loader, setLoader] = useState(false)
  const [creatorTag,setCreatorTag] = useState(false)
  const [creatorSettingTag,setCreatorSettingTag] = useState(false)
  const [editData,setEditData] = useState(false)
  const closeCalendarModal = () => {
    setCalendarModal(false)
  }
  const closeModal = () => {
    setIsModal(false)
  }
  const closeModals = () => {
    setCreatorTag(false)
  }
  
  const initialValues = {
    name: userData.name || '',
    email: userData.email || '',
    country: userData.country || 'USA',
    about: userData.about || '',
    instagram: userSocialLink[0]?.instagram_link || '',
    twitter: userSocialLink[0]?.twitter_link || '',
    facebook: userSocialLink[0]?.facebook_link || ''
  };

  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const handleFileChange = (e) => {
    setUpdateProfileData(true)
    const file = e.target.files[0];
    if (file) {
      setImagePreview(file)
      const reader = new FileReader();

      reader.onloadend = () => {
        setShowImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleConnectData = async (value) => {
    setConnectData((prevData) => {
      if (prevData.includes(value)) {
        // Remove 'instagram' from connectData
        return prevData.filter((data) => data !== value);
      } else {
        // Add 'instagram' to connectData
        return [...prevData, value];
      }
    });

  }

  const handleLink = async (value) => {
    let data;
    if (value === 'instagram') {
      data = {
        instagram_link: socialLink?.instagram
      }
    }
    if (value === 'twitter') {
      data = {
        twitter_link: socialLink?.twitter
      }
    }
    if (value === 'facebook') {
      data = {
        facebook_link: socialLink?.facebook
      }
    }

    const response = await postApi('/social', data);
    if (response?.statusCode === 200) {
      handleConnectData(value)
      setUpdateData(response?.data)
      const data = [{
        facebook_link: response?.data?.facebook_link,
        instagram_link: response?.data?.instagram_link,
        twitter_link: response?.data?.twitter_link
      }]
      localStorage.setItem('user_social_links', JSON.stringify(data))

    }
    else {    }
  }
  const handleChange = (e, type) => {
    const { name, value } = e.target;
    if (type === 'social') {
      setSocialLink((prevLinks) => ({
        ...prevLinks,
        [name]: value,
      }));
    }
    if (type === 'profile') {
      setUpdateProfileData(true)
      setUSerAboutData((prevLinks) => ({
        ...prevLinks,
        [name]: value,
      }));
    }
  };
  const handleSubmitData = async (val) => {
    // e.preventDefault();
    setLoader(true)
    let formData = new FormData();
    formData.append('about', val?.about);
    formData.append('profile_image', imagePreview);
    formData.append('name', val?.name);
    let response = await putApi(`/profile-update`, formData);
    if (response?.statusCode === 200) {
      setLoader(false)
      toast.success(response?.message)
      localStorage.setItem("userDetail", JSON.stringify(response?.data))
    } else {
      toast.error(response?.message)
    }
  }

  return (
    <div className="users meal-suggestion">
      <div className="HomeMainDiv">
        <UserSidebar classState={classState} />
        <div className="RightSideDiv right-main">
          <div className="members-top">
            <h2>
              <button className="burger-menu" onClick={handleSidebarClick}>
                <img src={burger} alt="burger menu" />
              </button>
              Settings
            </h2>
            {condition === 'Category/Popular tags' && (
              <div className="category-tab">
                <ul>
                  <li>
                    <a className="active">Category</a>
                  </li>
                  <li>
                    <a>Category</a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="setting-links">
            <ul>
              <li onClick={() => setCondition('Basics')}><a className={condition === 'Basics' ? 'active' : ''}>Basics</a></li>
              <li onClick={() => setCondition('Gallery')}><a className={condition === 'Gallery' ? 'active' : ''}>Gallery</a></li>
              <li onClick={() => setCondition('Account')}><a className={condition === 'Account' ? 'active' : ''}>Account</a></li>
              <li onClick={() => setCondition('Email Notification')}><a className={condition === 'Email Notification' ? 'active' : ''}>Email Notification</a></li>
              <li onClick={() => setCondition('Specialization')}><a className={condition === 'Specialization' ? 'active' : ''}>Specialization</a></li>
              <li onClick={() => setCondition('Billing and payouts')}><a className={condition === 'Billing and payouts' ? 'active' : ''}>Billing and payouts</a></li>
            </ul>
          </div>
          {condition === 'Basics' && (
            <Formik
              initialValues={initialValues}
                // validationSchema={validationSchema}
              onSubmit={handleSubmitData}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="setting-main bg-white">
                    <h2>Profile</h2>
                    <div className="setting-img">
                      <div className='setting-left'>
                        <div className="setting-profile">
                          {(showImagePreview || userData?.user_profile_image) ? (
                            <img
                              src={showImagePreview || userData?.user_profile_image}
                              alt="preview"
                              style={{ width: '100px', height: '100px' }}
                            />
                          )
                          :
                          <img src={dummyProfile} alt="preview" style={{ width: '100px', height: '100px' }}/>
                          }
                        </div>
                        <div className="upload-btn-wrapper">
                          {editData && <button className="btn" type="button">Upload new photo</button>}
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, setFieldValue)}
                          />
                        </div>
                      </div>
                      <div className='setting-right'>
                        <h3>Discovery call</h3>
                        <div class="switch-button"  >
                          <label class="switch">
                            <input type="checkbox" onClick={() => {editData===true && setDiscoveryModal(true);setIsModal(true)}} disabled={!editData || discoveryCall === 1} checked={discoveryModal}/>
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <Field
                        name="name"
                        type="text"
                        id="name"
                        placeholder="Marley Westervelt"
                        className="form-control"
                        validate={(value) => {
                          if (!value.trim()) {
                            return "Name cannot be empty ";
                          }
                        }}
                        disabled={!editData}
                        // disabled
                      />
                      <ErrorMessage name="name" component="div" className="text-danger" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="about">About me</label>
                      <Field
                        as="textarea"
                        name="about"
                        className="form-control textarea"
                        // validate={(value) => {
                        //   const wordCount = value.trim().split(/\s+/).length;
                        //   if (wordCount > 600) {
                        //     return `About me too large`;
                        //   }
                        // }}
                        value={values.about}
                        onChange={(e) => {
                          const wordCount = e.target.value.trim().split(/\s+/).length;
                          if (wordCount <= 600) {
                            setFieldValue('about', e.target.value);
                          }
                          else{
                            return `You can only use a maximum of 600 words. You have used ${wordCount} words.`;
                          }
                        }}
                        disabled={!editData}
                      />
                      <ErrorMessage name="about" component="div" className="text-danger" />
                    </div>
                    <div className="form-group email">
                      <label htmlFor="email">Email</label>
                      <Field
                        name="email"
                        type="email"
                        id="email"
                        placeholder="marley@gmail.com"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                      <div className='email-content'>
                        <span><img src={Check}></img> </span><h6>Verified</h6>
                      </div>
                    </div>
                    <div className="my-tags social">
                      <h3>Social</h3>
                      <ul>
                        <li>
                          <span><img src={updateData?.instagram_link || userSocialLink[0]?.instagram_link ? instagramLight : insta} alt="" /></span>
                          <h5>Instagram</h5>
                          {!connectData.includes('instagram') && (
                            <button type='button'
                              onClick={() => {editData===true && handleConnectData('instagram')}}
                              //   className={'colorchange'}
                              className={(userSocialLink || updateData) && (userSocialLink[0]?.instagram_link || updateData?.instagram_link) && 'colorchange'}
                            >
                              Connect
                            </button>
                          )}
                          <div className="add-social">
                            {connectData.includes('instagram') && (
                              <>
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="instagram.com/microcosm"
                                  name="instagram"
                                  onChange={(e) => { setFieldValue('instagram', e.target.value); handleChange(e, 'social') }}
                                />
                                <div className="close add" onClick={() => handleLink('instagram')}>Add</div>
                                <div className="close" onClick={() => handleConnectData('instagram')}><img src={socialCloase} alt="" /></div>
                              </>
                            )}
                          </div>
                        </li>
                        <li>
                          <span><img src={userSocialLink && userSocialLink[0]?.twitter_link ? twitterLight : twitter} alt="" /></span>
                          <h5>Twitter</h5>
                          {!connectData.includes('twitter') && (
                            <button type='button'
                              onClick={() => {editData===true && handleConnectData('twitter')}}
                              className={(userSocialLink || updateData) && (userSocialLink[0]?.twitter_link || updateData?.twitter_link) && 'colorchange'}
                            >
                              Connect
                            </button>
                          )}
                          <div className="add-social">
                            {connectData.includes('twitter') && (
                              <>
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="twitter.com/microcosm"
                                  name="twitter"
                                  onChange={(e) => { setFieldValue('twitter', e.target.value); handleChange(e, 'social') }}
                                />
                                <div className="close add" onClick={() => handleLink('twitter')}>Add</div>
                                <div className="close" onClick={() => handleConnectData('twitter')}><img src={socialCloase} alt="" /></div>
                              </>
                            )}
                          </div>
                        </li>
                        <li>
                          <span><img src={userSocialLink && userSocialLink[0]?.facebook_link ? facebookLight : facebooks} alt="" /></span>
                          <h5>Facebook</h5>
                          {!connectData.includes('facebook') && (
                            <button type='button'
                              onClick={() => {editData===true && handleConnectData('facebook')}}
                              className={(userSocialLink || updateData) && (userSocialLink[0]?.facebook_link || updateData?.facebook_link) && 'colorchange'}
                            >
                              Connect
                            </button>
                          )}
                          <div className="add-social">
                            {connectData.includes('facebook') && (
                              <>
                                <Field
                                  type="text"
                                  className="form-control"
                                  placeholder="facebook.com/microcosm"
                                  name="facebook"
                                  onChange={(e) => { setFieldValue('facebook', e.target.value); handleChange(e, 'social') }}
                                />
                                <div className="close add" onClick={() => handleLink('facebook')}>Add</div>
                                <div className="close" onClick={() => handleConnectData('facebook')}><img src={socialCloase} alt="" /></div>
                              </>
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className='calendar'>
                      <h3>Calendar</h3>
                      <ul>
                        <li>
                          <span><img src={GoogleCalendar}></img> </span>
                          <h5>Google calendar</h5>
                          <button className='connect' onClick={() => {editData===true && setCalendarModal(true)}} type='button'>
                            Connect
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className='location'>
                      <h3>Location</h3>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <div class="form-group">
                            <label for="country">Country of residence</label>
                            <select id="country" name="country" disabled={!editData}>
                              <option value="">Select your country</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div class="form-group">
                            <label for="state">State</label>
                            <select id="state" name="state" disabled={!editData}>
                              <option value="">Select your state</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div class="form-group">
                            <label for="city">Your City</label>
                            <select id="city" name="city" disabled={!editData}>
                              <option value="">Select your city</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-sm-6'>
                          <div class="form-group">
                            <label for="zip">Zip code</label>
                            <input type="text" id="zip" name="zip" placeholder="Type Code" disabled={!editData}></input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="discovery2-right">
                      <section className="creators-top">
                        <div className="saved-address">
                          <h3>Saved Locations</h3>
                          <ul>
                            <li><strong>Location 1:</strong> 123 Main Street, Anytown, California, 12345
                              <div className="address-btn">
                                <button type='button'><img src={eye1} alt="" /></button>
                                <button type='button'><img src={deleted} alt="" /></button>
                              </div>
                            </li>
                            <li><strong>Location 2:</strong> 123 Main Street, Anytown, California, 12345
                              <div className="address-btn">
                                <button type='button'><img src={eye1} alt="" /></button>
                                <button type='button'><img src={deleted} alt="" /></button>
                              </div>
                            </li>
                            <li><strong>Location 3:</strong> Downtown Austin, North Austin
                              <div className="address-btn">
                                <button type='button'><img src={eye1} alt="" /></button>
                                <button type='button'><img src={deleted} alt="" /></button>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className='timing-zone'>
                          <h3>Timing zone</h3>
                          <div className='row'>
                            <div className='col-sm-12'>
                              <div class="form-group">
                                <select id="state" name="state" disabled={!editData}>
                                  <option value="">Choose your time</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="my-tags">
                          <h3>My Tags</h3>
                          {/* <ul>
                            <li className='active'>Fitness</li>
                            <li >Yoga</li>
                            <li className='active'>Language Learning</li>
                            <li className='active'>Art</li>
                          </ul> */}
                            <ul>
                          {userData?.tags && userData?.tags?.length>0 && userData?.tags.map((element,index) => {
                            return(
                              index <4 &&
                              <li className='active'>{element}</li>
                            )
                          })}
                          </ul>
                          <div onClick={()=>{editData===true && setCreatorTag(true)}}><img src={addTag} alt="" /><span className='px-2'>Add new Tags</span> </div>
                        </div>
                        {/* <div className="my-tags my-interests">
                          <h3>My Interests</h3>
                          <ul>
                            <li className='active'>Art</li>
                            <li>Yoga</li>
                            <li>Language Learning</li>
                            <li>Handmade</li>
                          </ul>
                          <button><img src={addTag} alt="" />Add new Tags</button>
                        </div> */}
                      </section>
                    </div>
                    <div className="setting-btns">
                      {!editData ?
                      <>
                        <button className='setting-btn' type='button' onClick={()=>setEditData(true)}>Edit</button>
                        </> 
                        :
                        <>
                      <button className="setting-btn" type="button" onClick={()=>setEditData(false)}>Cancel</button>
                      <button type="submit" className="setting-btn save">{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save</button>
                      </>
}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}

          {/* Gallery  starts*/}
          {condition === 'Gallery' && (
            <BasicsGallery />
          )}
          {/* Gallery  ends*/}

          {/* Account  starts*/}
          {condition === 'Account' && (
            <BasicsAccount />
          )}
          {/* Account  ends*/}

          {/* Email Notification  starts*/}
          {condition === 'Email Notification' && (
            <BasicsEmailNotification />
          )}
          {/* Email NOtification  ends*/}

          {/* Specialization  starts*/}
          {condition === 'Specialization' && (
            <BasicsSpecialization />
          )}
          {/* Specialization  ends*/}

          {/* Billing and payouts  starts*/}
          {condition === 'Billing and payouts' && (
            <BasicsBilling />
          )}
          {/* Billing and payouts  ends*/}
        </div>
      </div>

      <Modal
        isOpen={creatorTag}
        onRequestClose={() => setCreatorTag(false)}
        contentLabel="Tag Modal"
        shouldCloseOnOverlayClick={true}
        portalClassName="add-tag-modal"
      >
        {/* <button className='close-button' onClick={() =>  setCreatorTag(false)}><img src={cross} alt='' /></button> */}
        <CreatorTags closeModal={closeModals} setCreatorSettingTag={setCreatorSettingTag}/>
      </Modal>

      <Modal
        isOpen={isModal}
        onRequestClose={() => closeModal()}
        contentLabel="Login Modal"
        portalClassName="bookingModal discover-call"
      >
        <button className='close-button' onClick={() => closeModal()}><img src={cross} alt='' /></button>
        <DiscoveryCallModal data={''} closeModal={closeModal} />
      </Modal>

      <Modal
        isOpen={calendarModal}
        onRequestClose={() => closeCalendarModal()}
        contentLabel="Login Modal"
        portalClassName="google-calendar"
      >
        <div className="header">
          <h1>Calendar Integrations</h1>
          <div className="top-buttons">
            <button className="cancel" onClick={() => closeCalendarModal()}>Cancel</button>
            <button className="save">{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save</button>
          </div>
        </div>
        <div className='middle'>
          <h2>Stay organized effortlessly!</h2>
          <button className='connect-calendar'>Connect Google Calendar</button>
        </div>
        <div className='bottom'>
          <h4>Want to use a different calendar? </h4>
          <h5>No problem! Simply add it using the provided link.</h5>
          <div class="form-group">
            <label for="calendar-subscription-url">Calendar Subscription URL</label>
            <input type="url" class="form-control" id="calendar-subscription-url" placeholder="https://" />
          </div>

        </div>
      </Modal>

    </div>
  );
}

export default Basics;
