import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Layout/sidebar'
import annete from '../../../assets/images/annete.png'
import discoveryCall from '../../../assets/images/discovery-call.svg'
import facebook from '../../../assets/images/creator-facebook.svg'
import twitter from '../../../assets/images/creator-twitter.svg'
import instagram from '../../../assets/images/Instagram-Logo.wine 1.svg'
import facebookgrey from '../../../assets/images/user-social1.svg'
import twittergrey from '../../../assets/images/twiter-grey.svg'
import instagramgrey from '../../../assets/images/Insta-grey.svg'
import yogaHero from '../../../assets/images/yoga-hero.svg'
import healPlatform from '../../../assets/images/heal-platform.svg'
import Gallery from '../../HomeComponent/CreatorComponent/gallery'
import { getApi } from '../../../Services/apiService'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import { Link, useNavigate } from 'react-router-dom'
import arrowShare from '../../../assets/images/arrow-share.png'
import videoDummy from '../../../assets/images/video-profile-dummy.svg'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import hearts from '../../../assets/images/heart.svg'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'


function DetailCreator() {
	const [classState, setClassState] = useState(false)
	const [userDetails, setUserDetails] = useState('')
	const [apiResponse, setApiResponse] = useState('')
	const [offeringData, setOfferingData] = useState('')
	const [page, setPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleText = () => {
		setIsExpanded(!isExpanded);
	};
  
	// Ensure description is defined and is a string
	
	const navigate = useNavigate()

	// const [userData,setUserData] = useState([])
	const localDataUser = JSON.parse(localStorage.getItem('full_user_detail'));
	const id = localDataUser?.id;
	useEffect(() => {
		fetchUserDeatils()
		fetchUserOfferings()
		const handleClick = () => setClassState(false);

		document.addEventListener('click', handleClick);

		// Cleanup event listener on component unmount
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [page])
	const userData = JSON.parse(localStorage.getItem('full_user_detail'));
	const userSocialLink = JSON.parse(localStorage.getItem('user_social_links'))
	console.log('this is user details from local storage', userData);
	const handleSidebarClick = (event) => {
		event.stopPropagation();
		setClassState(!classState);
	};

	const fetchUserDeatils = async () => {
		const response = await getApi('/creator/home');
		if (response?.statusCode === 200) {
			setUserDetails(response.data)
		}
		else {
			localStorage.clear();
			navigate('/')
		}
	}
	const singleService = (id) => {
		navigate(`/user/offering/${id}`)
	}
	const fetchUserOfferings = async () => {
		const response = await getApi(`/creator/home-services?page=${page}&pageSize=9`);
		if (response?.statusCode === 200) {
			setApiResponse(response)
			setTotalCount(response.totalCount); // Set the total count from API response
			setOfferingData((prevOfferings) => [...prevOfferings, ...response.data]); // Append new data to existing offerings
		} 
		// else {
		// 	localStorage.clear();
		// 	navigate('/');
		// }
	};

	//   / Function to handle loading more offerings
	  const loadMoreOfferings = () => {
		  setPage((prevPage) => prevPage + 1); // Increment the page number
	  };
	console.log('this is user details', userDetails);
	
  
	// Ensure description is defined and is a string
	
  

	const navSetting = () => {
		navigate('/creator/settings')
	  }
	  const description = userData?.about || '';
	const isLongDescription = description.length > 400;
	const truncatedDescription = description.length > 400 ? `${description.slice(0, 400)}...` : description;
	return (
		<div class="creators-detail">
			<UserSidebar classState={classState} name={userData} />
			<div class="right-main creators-detail-right">
				<section class="creators-top detail">

					<div class="my-page"> <button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>My Page</div>
					<div class="row">
						<div class="col-sm-3">
							<div class="creator-detail-left">
								<div class="creator-detail-img"><img src={userData?.user_profile_image || dummyProfile} />
								</div>
								<button class="discovery" onClick={() => navSetting()}>Discovery call <img src={discoveryCall} /></button>
								<div class="creator-soial">
									<ul>
										<li><Link to={userSocialLink && userSocialLink[0]?.facebook_link ? userSocialLink[0]?.facebook_link : ''}><img src={userSocialLink && userSocialLink[0]?.facebook_link ? facebook : facebookgrey} /></Link></li>
										<li><Link to={userSocialLink && userSocialLink[0]?.twitter_link ? userSocialLink[0]?.twitter_link : ''}><img src={userSocialLink && userSocialLink[0]?.twitter_link ? twitter : twittergrey} /></Link></li>
										<li><Link to={userSocialLink && userSocialLink[0]?.instagram_link ? userSocialLink[0]?.instagram_link : ''}><img src={userSocialLink && userSocialLink[0]?.instagram_link ? instagram : instagramgrey} /></Link></li>
									</ul>
								</div>
								<div class="associated">
									<h4>Associated with</h4>
									<ul>
										<li><span><img src={yogaHero} /> </span>
											<h4>“Yoga here studio”</h4>
										</li>
										<li><span><img src={healPlatform} /></span>
											<h4>“Heal platform”</h4>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-sm-6">
							<div class="creator-detail-right">
								<h3>{userData?.name}</h3>
								<h4>{userData?.specializations && userData?.specializations?.length > 0 && userData?.specializations[0]?.experience}</h4>
								{localDataUser?.tags && localDataUser?.tags?.length > 0 && localDataUser?.tags?.map((x, index) => {
									return (
										<label key={index}>{x}</label>
									)
								})}
								<h5>About Me</h5>
								<h6>{userData?.specializations && userData?.specializations?.length > 0 && userData?.specializations[0]?.experience && 'Experience:'} {userData?.specializations && userData?.specializations?.length > 0 && userData?.specializations[0]?.experience}</h6>
								<p>
                                        {isExpanded || !isLongDescription ? description : truncatedDescription}
                                        <br />
                                        {isLongDescription && (
                                            <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </strong>
                                        )}
                                    </p>
							</div>
						</div>
						<div className='col-sm-3'>
							<div className={`upload-video uploaded  ${userData?.introduction_video ? 'choose-video' : ''}`}>
								{userData?.introduction_video ? (
									<video controls width="100%">
										<source src={userData.introduction_video} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								) : (
									<>
										<span><img src={videoDummy} alt="Video Placeholder" /></span>
										<h5>Upload introduction video <img src={arrowShare} alt="Share Icon" /></h5>
										
									</>
								)}
							</div>
						</div>
					</div>
				</section>
				<section class="offerings">
					<h3 class="offering">Offerings by {userDetails?.name}</h3>

					<div class="links mention-tags">
						<h4>Mention tags</h4>

						<ul>
							{apiResponse?.tag && apiResponse?.tag?.length > 0 && apiResponse?.tag.map((x, index) => {
								return (
									<>
										<li><a >{x?.tag_name}</a> </li>
									</>
								)
							}
							)
							}
						</ul>
					</div>


					<div class="row">
						{offeringData && offeringData?.length > 0 && offeringData.map((x, index) => {
							return (
								<div class="col-sm-4" onClick={() => singleService(x?.id)} key={index} >
									<div class="product-box">
										<div class="product-img">
											<img src={x?.cover_image} alt='' />
											<div class="product-image-data">
												<span><img src={x?.category && x?.category?.category_image} alt='' /></span>
												<h5>Offline</h5>
												<button><img src={hearts} alt='' /></button>
											</div>
										</div>
										<div class="product-data">
											<h3>{x?.name} </h3>
											<ul>
												{x?.tags && x?.tags?.length > 0 && x?.tags.map((y, i) => {
													return (
														<li class={i === 0 && "active"}>{y?.tag_name}</li>
													)
												})}
											</ul>
											<p>
												{x?.description}
											</p>
											<div class="product-data-bottom">
												<span><img src={x?.user?.user_profile_image && x?.user?. user_profile_image || bannerRight}  alt='' /></span>
												<h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
											</div>
										</div>
									</div>
								</div>
							)
						}
						)
						}

				
		  </div>
		  {offeringData?.length < totalCount && (
            <button className="explore" onClick={loadMoreOfferings}>
              Load more Offerings
            </button>
          )}	

				</section>
				{userDetails?.userGallery?.length !==0 && (
					<Gallery galleryImages={userDetails?.userGallery || []} />
				)}
			</div>
		</div>
	)
}

export default DetailCreator