import React, { useEffect, useState } from 'react'
import search from '../../../assets/images/search.svg'
import { getApi, postApi } from '../../../Services/apiService'
import { toast } from 'react-toastify'

function ViewTag({ closeModal,types,creatorTag,setCreatorTag,tagName,setTagName,setNameTag }) {
  const [tagData, setTagData] = useState([])
//   const [tagName, setTagName] = useState('')
  const [interestName, setInterestName] = useState('')
//   const [creatorTag, setCreatorTag] = useState(creatorTags || [])
  const [popularTags, setPopularTags] = useState([])
  const [popularInterest, setPopularInterest] = useState([])
  const [filterValue, setFilterValue] = useState([])
  const [loader, setLoader] = useState(false)
  const [addTagName,setAddTagName]= useState('')
  const [addTags,setAddTags] = useState([])
  const [loadNumber, setLoadNumber] = useState(10)

  console.log("popularTagspopularTags",popularTags);
  console.log("creatorTagcreatorTag>>>>>>>>>>>>>>>>>>>>",creatorTag);
  console.log("tagNametagName",tagName);
  console.log("addTagsaddTags",addTags);
  
  
  // const popularTags = ['Drawing', 'Painting', 'Photography', 'Music', 'Handcrafts', 'Arts', 'DIY Projects', 'Reading', 'Writing', 'Knitting', 'Fitness', 'Running']
  // const popularInterest = ['Fitness', 'Language Learning', 'Handmade', 'Health and Fitness', 'Cooking', 'Reading', 'Gardening', 'Reading', 'Gaming', 'Outdoor Activities', 'Fashion']

  // creatorTa
  useEffect(() => {
    fetchTagData()
  }, [])

  const fetchTagData = async () => {
    const response = await getApi('/creator/tag')
    
    if (response?.statusCode === 200) {
    //   setCreatorTag(response?.data?.creatorData)
      setPopularTags(response?.data?.tagData)
      setPopularInterest(response?.data?.popularData)
    }

  }
  const addTagData = async (val) => {
    let data;
    if (val === 'save') {
      data = {
        tagName: addTagName
      }
    }
    else {
      setLoader(true)
      if (tagName === '') {
        setLoader(false)
        return toast.error('enter the value')
      }
      else {
        data = {
          tagName: [tagName]
        }
      }
    }
    setLoader(true)
    const response = await postApi('/creator/tag', data);
    if (response?.statusCode === 200) {
      fetchTagData()
      const response = await getApi('/creator/dashboard');
    //   if (response?.statusCode === 200) {
    //     localStorage.setItem('full_user_detail', JSON.stringify(response?.data))
    //     localStorage.setItem('user_role', response?.data?.roles[0]?.user_role)
    //     localStorage.setItem('user_social_links', JSON.stringify(response?.data?.social_links))
    //     // setUserData(response?.data);
    //   }
      closeModal()
      // else {
      //   localStorage.clear();
      //   navigate('/')
      // }
    }
    setLoader(false)
    fetchTagData()
  }

//   const handleTagData = async(newTag, type) => {
// let data;
//     if (type === 'add') {
//       newTag = addTags
      
//       // newTag = addTagName
//       // if(newTag !=='' && type === 'add' && newTag.trim().length !== 0){
//         data = {
//           tagName: addTags
//         }
//       const respons = await postApi('/creator/tag', data);
//       if (respons?.statusCode === 200) {
//         respons?.data?.length >0 && respons?.data.map((y,ind)=>{
//           setCreatorTag((prevTagData) => {

//             if (prevTagData.includes(y?.id)) {
//               return prevTagData.filter(tag => tag !== y?.id);
//             } else {
//               return [...prevTagData, y?.id];
//             }
//           });
//             setNameTag((prevTagName) => {
//               if (prevTagName.includes(y?.tag_name)) {
//                 return prevTagName.filter(tag => tag !== y?.tag_name);
//               } else {
//                 return [...prevTagName, y?.tag_name];
//               }
//             });
//           setTagName((prevTagData) => {
//               if (prevTagData.some(tag => tag.name === y?.tag_name)) {
//                 return prevTagData.filter(tag => tag.name !== y?.tag_name);
//               } else {
//                 return [...prevTagData, { name: y?.tag_name, ids: y?.id }];
//               }
//             });
//         })
       
//         fetchTagData()
//         const response = await getApi('/creator/dashboard');
//         if (response?.statusCode === 200) {
//           localStorage.setItem('full_user_detail', JSON.stringify(response?.data))
//           localStorage.setItem('user_role', response?.data?.roles[0]?.user_role)
//           localStorage.setItem('user_social_links', JSON.stringify(response?.data?.social_links))
//         }
//         setCreatorTag((prevTagData) => {

//           if (prevTagData.includes(newTag.id)) {
//             return prevTagData.filter(tag => tag !== newTag?.id);
//           } else {
//             return [...prevTagData, newTag?.id];
//           }
//         });
//           setNameTag((prevTagName) => {
//             if (prevTagName.includes(newTag?.tag_name)) {
//               return prevTagName.filter(tag => tag !== newTag?.tag_name);
//             } else {
//               return [...prevTagName, newTag?.tag_name];
//             }
//           });
//         setTagName((prevTagData) => {
//             if (prevTagData.some(tag => tag.name === newTag?.tag_name)) {
//               return prevTagData.filter(tag => tag.name !== newTag?.tag_name);
//             } else {
//               return [...prevTagData, { name: newTag?.tag_name, ids: newTag?.id }];
//             }
//           });
//         closeModal()
//       }
//     // }
//     }
   
//     setCreatorTag((prevTagData) => {

//       if (prevTagData.includes(newTag.id)) {
//         return prevTagData.filter(tag => tag !== newTag?.id);
//       } else {
//         return [...prevTagData, newTag?.id];
//       }
//     });
//     // setTagName((prevTagData) => {
//     //     if (prevTagData.includes(newTag)) {
//     //       return prevTagData.filter(tag => tag !== newTag?.tag_name);
//     //     } else {
//     //       return [...prevTagData, {name:newTag?.tag_name,ids:newTag?.id}];
//     //     }
//     //   });
//       setNameTag((prevTagName) => {
//         if (prevTagName.includes(newTag?.tag_name)) {
//           return prevTagName.filter(tag => tag !== newTag?.tag_name);
//         } else {
//           return [...prevTagName, newTag?.tag_name];
//         }
//       });
//     setTagName((prevTagData) => {
//         if (prevTagData.some(tag => tag.name === newTag?.tag_name)) {
//           return prevTagData.filter(tag => tag.name !== newTag?.tag_name);
//         } else {
//           return [...prevTagData, { name: newTag?.tag_name, ids: newTag?.id }];
//         }
//       });
//   };

const handleTagData = async (newTag, type) => {
  let data;
  if (type === 'add') {
    newTag = addTags;

    data = { tagName: addTags };

    // Optimistically update the state before the API call
    // setCreatorTag((prevTagData) => {
    //   if (prevTagData.includes(newTag?.id)) {
    //     return prevTagData.filter(tag => tag !== newTag?.id);
    //   } else {
    //     return [...prevTagData, newTag?.id];
    //   }
    // });

    // setNameTag((prevTagName) => {
    //   if (prevTagName.includes(newTag?.tag_name)) {
    //     return prevTagName.filter(tag => tag !== newTag?.tag_name);
    //   } else {
    //     return [...prevTagName, newTag?.tag_name];
    //   }
    // });

    // setTagName((prevTagData) => {
    //   if (prevTagData.some(tag => tag.name === newTag?.tag_name)) {
    //     return prevTagData.filter(tag => tag.name !== newTag?.tag_name);
    //   } else {
    //     return [...prevTagData, { name: newTag?.tag_name, ids: newTag?.id }];
    //   }
    // });

    // Make the API call after updating the state
    const response = await postApi('/creator/tag', data);

    if (response?.statusCode === 200) {
      response?.data?.forEach((y) => {
        setCreatorTag((prevTagData) => {
          if (prevTagData.includes(y?.id)) {
            return prevTagData.filter(tag => tag !== y?.id);
          } else {
            return [...prevTagData, y?.id];
          }
        });

        setNameTag((prevTagName) => {
          if (prevTagName.includes(y?.tag_name)) {
            return prevTagName.filter(tag => tag !== y?.tag_name);
          } else {
            return [...prevTagName, y?.tag_name];
          }
        });

        setTagName((prevTagData) => {
          if (prevTagData.some(tag => tag.name === y?.tag_name)) {
            return prevTagData.filter(tag => tag.name !== y?.tag_name);
          } else {
            return [...prevTagData, { name: y?.tag_name, ids: y?.id }];
          }
        });
      });

      // Fetch updated data after success
      await fetchTagData();
      const dashboardResponse = await getApi('/creator/dashboard');
      if (dashboardResponse?.statusCode === 200) {
        localStorage.setItem('full_user_detail', JSON.stringify(dashboardResponse?.data));
        localStorage.setItem('user_role', dashboardResponse?.data?.roles[0]?.user_role);
        localStorage.setItem('user_social_links', JSON.stringify(dashboardResponse?.data?.social_links));
      }
      
      closeModal();
    } else {
      // Handle API failure and optionally revert the state changes if needed
    }
  }else{
    setCreatorTag((prevTagData) => {
      if (prevTagData.includes(newTag?.id)) {
        return prevTagData.filter(tag => tag !== newTag?.id);
      } else {
        return [...prevTagData, newTag?.id];
      }
    });

    setNameTag((prevTagName) => {
      if (prevTagName.includes(newTag?.tag_name)) {
        return prevTagName.filter(tag => tag !== newTag?.tag_name);
      } else {
        return [...prevTagName, newTag?.tag_name];
      }
    });

    setTagName((prevTagData) => {
      if (prevTagData.some(tag => tag.name === newTag?.tag_name)) {
        return prevTagData.filter(tag => tag.name !== newTag?.tag_name);
      } else {
        return [...prevTagData, { name: newTag?.tag_name, ids: newTag?.id }];
      }
    });
  }
};


  const handleInterestData = (newTag) => {
    setInterestName((prevTagData) => {
      if (prevTagData.includes(newTag)) {
        return prevTagData.filter(tag => tag !== newTag);
      } else {
        return [...prevTagData, newTag];
      }
    });
  };

  useEffect(() => {
    // const intervalId = setInterval(() => {
      filterTagData(filterValue);
    // }, 500);

    // return () => clearInterval(intervalId);
  }, [filterValue]);

  const filterTagData = async (value) => {
    // setLoader(true)
    const response = await getApi(`/creator/tag?tagName=${value}`)
    if (response?.statusCode === 200) {
      // setLoader(false)
      // setCreatorTag(response?.data?.creatorData)
      setPopularTags(response?.data?.tagData)
      setPopularInterest(response?.data?.popularData)
    }
  }

  const handleShowData = () => {

    setLoadNumber(loadNumber + 10)
  }

  const handleTags = (x,type) => {
    let addTagValue;
    if(type =='remove'){
      addTagValue = x
    } else{
      if(addTagName !=='' && addTagName.trim().length !== 0){
      addTagValue = addTagName
      }
    }
    if(addTagValue !==undefined){
    setAddTags((prevTags) => {
      if (prevTags.includes(addTagValue)) {
        return prevTags.filter((tag) => tag !== addTagValue);
      } else {
        return [...prevTags, addTagValue];
      }
    });
  }
    setAddTagName('')
  };

  return (
    <div className="interest">
      <div className='modal-scroll'>
      <div className="header">
        {/* <h1>Add new Interest</h1> */}
        <div className="top-buttons">
          <button onClick={() => closeModal()} className="cancel">Cancel</button>
          <button onClick={() => handleTagData('', 'add')} className="save">Save</button>
          {/* <button onClick={() => addTagData('save')} className="save">{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save</button> */}
        </div>
      </div>
      <section className="added-tags">
        <h6>My Added Tags</h6>
        <ul>
          {tagName?.map((x, index) => (
            <li key={index}>
              <label className="radio-check3">
                <input
                  type="checkbox"
                  checked={tagName.includes(x)}
                //   checked={interestName.includes(x?.tag?.tag_name)}
                  onChange={() => handleInterestData(x)}
                  name="radio"
                />
                <span className="checkmark">{x?.name || x?.tag_name}</span>
              </label>
            </li>
          ))}
          {addTags && addTags?.length>0 && addTags.map((x,index)=>{
            return(
            <li key={index}>
            <label className="radio-check3">
              <input
                type="checkbox"
                checked={addTags.includes(x)}
              //   checked={interestName.includes(x?.tag?.tag_name)}
                onChange={() => handleTags(x,'remove')}
                name="radio"
              />
              <span className="checkmark">{x}</span>
            </label>
          </li>
            )
          })}
        </ul>
      </section>
      {types !=='offering' &&
      <>
      <section className="more-tags">
        <h6>More Tags</h6>
        <div className="input-section">
          <input type="text" id="input" placeholder="Find Tag" onChange={(e) => setFilterValue(e.target.value)} />
          <img src={search} alt='' className="input-img" />
        </div>
      </section>
      <section className="added-tags popular-interest">
        <h6>Popular Interests</h6>
        <ul>
          {popularInterest?.map((x, index) => (
            <li key={index}>
              <label className="radio-check3">
                <input
                  type="checkbox"
                  // checked={interestName.includes(x)}
                  // onChange={() => handleInterestData(x?.tag_name)}
                  checked={creatorTag.includes(x?.tag_name)}
                //   onChange={() => handleTagData(x?.tag_name, '')}
                  name="radio"
                />
                <span className="checkmark">{x?.tag_name}</span>
              </label>
            </li>
          ))}
        </ul>
      </section>
      </>
}
      <section className="added-tags all-tags">
        <h6>All Tags</h6>
        <ul>
          {popularTags?.map((x, index) => (
            index < loadNumber &&
            <li key={index}>
            {/* <li key={index} onClick={() => handleTagData(x, '')}> */}

              <label className="radio-check3">
                <input
                  type="checkbox"
                  checked={creatorTag.includes(x?.id)}
                //   checked={creatorTag.includes(x?.id)}
                  onChange={() => handleTagData(x, '')}
                  name="radio"
                />
                <span className="checkmark">{x?.tag_name}</span>
              </label>
            </li>
          ))}
     
        </ul>
        <div className="load-more">

{loadNumber < popularTags.length && <button className='button' onClick={handleShowData}> Load more tags</button>}

</div>
      </section>
      <section className="personal-tag">
        <label htmlFor="personal-tag">Add Your Personal Tag</label>
        <input type="text" id="personal-tag" placeholder="skiing" value={addTagName} onChange={(e) => setAddTagName(e?.target?.value)} />
        <button onClick={() => handleTags()} className="add" disabled={loader}>{loader === true && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Add</button>
      </section>
        {/* <button onClick={addTagData} className="add" disabled={loader}>{loader === true && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Add</button> */}
    </div>
    </div>
  )
}

export default ViewTag