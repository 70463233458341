// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {getAuth} from 'firebase/auth'
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqKiOJFsgcifskDHxvnWT2yePaqSfOdvw",
  authDomain: "chat-3ce09.firebaseapp.com",
  projectId: "chat-3ce09",
  storageBucket: "chat-3ce09.appspot.com",
  messagingSenderId: "907090563688",
  appId: "1:907090563688:web:69f9e305fba9c15de9a1dd"
  };

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { auth };

export default db;