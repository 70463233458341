import React from 'react'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import hearts from '../../../assets/images/heart.svg'
import { useNavigate } from 'react-router-dom'
import bannerRight from '../../../assets/images/banner-right1.png'

function DiscoverOffering() {

	const navigate = useNavigate()
  const singleService = () =>{
    navigate('/discovering/4')
  }

  return (
    <>
    <section class="creators-mid">
			<div class="container">
				<div class="row">
					<div class="col-sm-4" onClick={singleService}>
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={hearts} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
								<div class="product-data-bottom">
									<span><img src={bannerRight} alt=''/></span>
									<h6><strong>Annete Black</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4" onClick={singleService}>
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={hearts} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
								<div class="product-data-bottom">
									<span><img src={bannerRight} alt=''/></span>
									<h6><strong>Annete Black</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4" onClick={singleService}>
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={hearts} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
								<div class="product-data-bottom">
									<span><img src={bannerRight} alt=''/></span>
									<h6><strong>Annete Black</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4" onClick={singleService}>
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={hearts} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
								<div class="product-data-bottom">
									<span><img src={bannerRight} alt=''/></span>
									<h6><strong>Annete Black</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4" onClick={singleService}>
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={hearts} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
								<div class="product-data-bottom">
									<span><img src={bannerRight} alt=''/></span>
									<h6><strong>Annete Black</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4" onClick={singleService}>
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={hearts} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
								<div class="product-data-bottom">
									<span><img src={bannerRight} alt=''/></span>
									<h6><strong>Annete Black</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			<button class="explore">Load more Offerings</button>
			</div>
		</section>
    </>
  )
}

export default DiscoverOffering