import React, { useEffect } from 'react'
import bannerRight from '../../../assets/images/banner-right1.png'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import Slider from 'react-slick';
import yoga_boy from '../../../assets/images/yoga-boy.svg'
// import yoga_boy2 from '../../../assets/images/yoga-boy2.svg'
import Main_foto from '../../../assets/images/Main_foto.png'
import yoga_girl from '../../../assets/images/yoga-girl.svg'
import yoga_girl2 from '../../../assets/images/yoga-girl2.svg'
import yoga_group from '../../../assets/images/yoga-group.svg'
import yoga_hero from '../../../assets/images/yoga-hero.svg'
import dummy from '../../../assets/images/dummy-profile.jpg'

function DiscoverDetail() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const items = [
    {
      title: "Morning Yoga",
      category: ["Workshop", "Fitness", "Yoga"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Annete Black",
    },
    {
      title: "Evening Meditation",
      category: ["Workshop", "Wellness", "Meditation"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "John Doe",
    },
    {
      title: "Cardio Blast",
      category: ["Fitness", "Cardio", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Jane Smith",
    },
    {
      title: "Strength Training",
      category: ["Fitness", "Strength", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Mike Johnson",
    },
    {
      title: "Pilates Class",
      category: ["Fitness", "Pilates", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Emily Davis",
    },
    {
      title: "Dance Workout",
      category: ["Fitness", "Dance", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Sophia Brown",
    },
    {
      title: "HIIT Session",
      category: ["Fitness", "HIIT", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Chris Wilson",
    },
    {
      title: "Mindfulness Yoga",
      category: ["Workshop", "Wellness", "Yoga"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Ava Martinez",
    },
    {
      title: "Bootcamp",
      category: ["Fitness", "Bootcamp", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Liam Anderson",
    },
    {
      title: "Bootcamp",
      category: ["Fitness", "Bootcamp", "Health"],
      description:
        "Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.",
      instructor: "Liam Anderson",
    },
  ];

  return (
    <div className='discover-offering'>
      <section class="creators-top">
        <div class="container">
          <div class="row">
            <div class="col-sm-8">
              <div class="offering-left">
                <h3>Yoga class on the roof, NY city</h3>
                <label>Yoga</label>
                <label>Fitness</label>
                <label>Meditation</label>

                <div class="annete">
                  <span><img src={bannerRight} alt='' /></span>
                  <div class="annete-text">
                    <h3>Annete Black</h3>
                    <h4>Yoga instructor</h4>
                    <h5>2 years of experience</h5>
                  </div>
                </div>

                <div class="about-left">
                  <h3>About Class</h3>
                  <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                    sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                    est velitolm.<br />
                    Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                    facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                    elit semper dalaracc lacus vel facilisis volutpat est velitolm. <br />
                    <strong>Read less</strong>
                  </p>
                </div>

              </div>
            </div>

            <div class="col-sm-4">
              <div class="offering-top-right">
                <h2>Morning Yoga</h2>
                <div class="offering-share">
                  <ul>
                    <li><a href=""><img src={share} alt=''/></a></li>
                    <li><a href=""><img src={heart} alt=''/></a></li>
                  </ul>
                </div>
                <div class="week">
                  <ul>
                    <li><img src={clock} alt='' /> 60 min</li>
                    <li><img src={calendar} alt=''/> 2x per week, 12 weeks</li>
                  </ul>
                </div>
                <div class="week">
                  <ul>
                    <li><img src={people} alt=''/> 8-12 people</li>
                    <li><img src={mapPin} alt='' /> Broome St, New York</li>
                  </ul>
                </div>
                <div class="time">
                  <h3>April 6(GMT+)</h3>
                  <button>12:00pm</button>
                  <button>03:00pm</button>
                </div>
                <div class="time">
                  <h3>April 12(GMT+)</h3>
                  <button>12:00pm</button>
                  <button>04:00pm</button>
                  <button>06:00pm</button>
                </div>
                <div class="offering-right-bottom">
                  <h4>Price <strong>$25</strong></h4>
                  <button>Proceed to checkout</button>
                </div>
              </div>
            </div>

            <div class="col-sm-8">
              <div class="offering-gallery">
                <div class="gallery-left">
                  <img src={Main_foto} alt=''/>
                </div>
                <div class="gallery-small-main">
                  <div class="gallery-small">
                    <img src={yoga_boy} alt="" />
                  </div>
                  <div class="gallery-small">
                    <img src={yoga_girl} alt="" />
                  </div>
                  <div class="gallery-small">
                    <img src={yoga_girl2} alt="" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="other-class-main">
        <div class="container">
          <h3>Other classes by Annete Black</h3>
          <button class="see-all">See all offers<img src={arrow} alt=''/></button>

          {/* <div class="owl-carousel">
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="product-box">
                                <div class="product-img">
                                    <img src={productImg}/>
                                    <div class="product-image-data">
                                        <span><img src={wellness}/></span>
                                        <h5>Offline</h5>
                                        <button><img src={heart}/></button>
                                    </div>
                                </div>
                                <div class="product-data">
                                    <h3>Morning Yoga </h3>
                                    <ul>
                                        <li class="active">Workshop</li>
                                        <li>Fitness</li>
                                        <li>Yoga</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                        facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
                                        elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
                                        sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
                                        est velitolm.</p>
                                    <div class="product-data-bottom">
                                        <span></span>
                                        <h6><strong>Annete Black</strong> Yoga instructor</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          <Slider {...settings}>
            {items.map((item, index) => (
              <div className="item p-3" key={index}>
                <div className="product-box">
                  <div className="product-img">
                    <img src={productImg} alt="Product" />
                    <div className="product-image-data">
                      <span>
                        <img src={wellness} alt="Wellness" />
                      </span>
                      <h5>Offline</h5>
                      <button>
                        <img src={heart} alt="Heart" />
                      </button>
                    </div>
                  </div>
                  <div className="product-data">
                    <h3>{item.title}</h3>
                    <ul>
                      {item.category.map((cat, idx) => (
                        <li className={idx === 0 ? "active" : ""} key={idx}>
                          {cat}
                        </li>
                      ))}
                    </ul>
                    <p>{item.description}</p>
                    <div className="product-data-bottom">
                      <span><img src={item?.user?.user_profile_image && item?.user?. user_profile_image || dummy} alt=''/></span>
                      <h6>
                        <strong>{item.instructor}</strong> Yoga instructor
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      <section class="other-class-main">
        <div class="container">
          <h3>Similar classes</h3>
          <button class="see-all">See all offers<img src={arrow} /></button>

          <Slider {...settings}>
            {items.map((item, index) => (
              <div className="item p-3" key={index}>
                <div className="product-box">
                  <div className="product-img">
                    <img src={productImg} alt="Product" />
                    <div className="product-image-data">
                      <span>
                        <img src={wellness} alt="Wellness" />
                      </span>
                      <h5>Offline</h5>
                      <button>
                        <img src={heart} alt="Heart" />
                      </button>
                    </div>
                  </div>
                  <div className="product-data">
                    <h3>{item.title}</h3>
                    <ul>
                      {item.category.map((cat, idx) => (
                        <li className={idx === 0 ? "active" : ""} key={idx}>
                          {cat}
                        </li>
                      ))}
                    </ul>
                    <p>{item.description}</p>
                    <div className="product-data-bottom">
                      <span><img src={item?.user?.user_profile_image && item?.user?. user_profile_image || dummy} /></span>
                      <h6>
                        <strong>{item.instructor}</strong> Yoga instructor
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

    </div>
  )
}

export default DiscoverDetail