import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/logo.svg'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { getApi } from '../../../Services/apiService'
import Header from '../../CreatorComponents/CreatorJoin/header'

function Review({nextStep,prevStep,data,submitForm,loader}) {
  const[imageData,setImageData] = useState([])
    const onSubmit = () =>{
        submitForm('booking')
    }
    const images = data?.selectedImage || []

    const fetchUserDetails = async () => {
      const response = await getApi('/interest');
      if (response?.statusCode === 200) {
        setImageData(response?.data);
      }
    };
  
    useEffect(() => {
      const fetchData = async () => {
        await fetchUserDetails();
      };
  
      fetchData();
    }, []);
    
  return (
    <div class="detail-page onbording signup-cs">
       <Header/>
            {/* <header>
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="#">
                         <img src={logo}/>
                        </a>  
                        </nav>
                </header> */}
                <div class="main">
                    {/* <h1>Tell Us What You Are Interested In</h1> */}
                    <div className="select-section">
          <div className="row">
          {imageData
              .filter((data) => images.includes(data.id))
              .map((filteredImage, index) => (
                <div className="col-sm-3" key={index}>
                  <div className="image-section">
                    <span className='sign-img'><img src='https://caseus.s3.ap-south-1.amazonaws.com/fdb5ea7d9f41445fc3e2b40f9ea68bc2.png' alt={filteredImage?.tag_name} /></span>
                    <div className="image-content">{filteredImage?.tag_name}</div>
                  </div>
                </div>
              ))}
          {/* {images.map((image, index) => (
              <div className="col-sm-3" key={index}>
                <div className={`image-section `} >
                  <span><img src={image.src} alt={image.label} /></span>
                  <div className="image-content">{image.label}</div>
                </div>
                 /// {selectedItems.some(item => item.src === image.src) && <button className="checkbox"><img src={checkboxs} alt="Checkbox" /></button>} 
              </div>
            ))} */}
          </div>
        </div>
                    <button onClick={onSubmit} class="pick-more" disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Submit</button>
                    
                </div>
                <div className="back-button">
  <button className="button" onClick={()=>prevStep()}><img src={leftArrow} alt=''/> </button>
  </div>
                </div>
  ) 
}

export default Review