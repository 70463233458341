import React, { useEffect, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar'
// import jennny from '../../../assets/images/jenny.svg'
import facebook from '../../../assets/images/facebook-logo.svg'
import twitter from '../../../assets/images/creator-twitter.svg'
import instagram from '../../../assets/images/Instagram-Logo.wine 1.svg'
import noUser from '../../../assets/images/no-user.svg'
import arrowWhite from '../../../assets/images/arrow_white.svg'
import noUserBox from '../../../assets/images/no-user-box.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import heart from '../../../assets/images/heart.svg'
import date from '../../../assets/images/date.svg'
import clock from '../../../assets/images/clock.svg'
import buttonXAxis from '../../../assets/images/button-x-axis.svg'
import annete from '../../../assets/images/annete.png'
import burger from '../../../assets/images/burger-menu.svg'
import { getApi } from '../../../Services/apiService'
import dummy from '../../../assets/images/dummy-profile.jpg'
import facebookgrey from '../../../assets/images/user-social1.svg'
import instagramgrey from '../../../assets/images/Insta-grey.svg'
import twittergrey from '../../../assets/images/twiter-grey.svg'
import { Link } from 'react-router-dom'
// import annete from '../../assets/images/annete.png'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import BookInterview from '../../CreatorComponents/CreatorJoin/bookInterview'
import SubmitForm from '../../CreatorComponents/CreatorJoin/submitForm'
import Modal from 'react-modal'

function UserDetail() {
	const [classState,setClassState] = useState(false)
	const [bookingInterview,setBookingInterviw] = useState(false)
    const [dashboardModal,setDashboardModal] = useState(false)
	// const [userData,setUserData] = useState([])
	const handleSidebarClick = (event) => {
		event.stopPropagation();
		setClassState(!classState);
	  };
	  useEffect(()=>{
		// fetchUserDeatils()
		const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  },[])
  const userData = JSON.parse(localStorage.getItem('full_user_detail'));
  const userSocialLink = JSON.parse(localStorage.getItem('user_social_links'))
	// let userRole =(localStorage.getItem('user_role'));

// const fetchUserDeatils = async() =>{
//   const response = await getApi('/user/dashboard');
//   if(response?.statusCode === 200){
// 	  setUserData(response?.data);
//   }
// }

  return (
    <div class="creators-detail user-home">
		<UserSidebar classState={classState} name={userData}/>
<div class="right-main creators-detail-right user-home">
		<section class="creators-top detail">
				<div class="my-page"> <button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt=''/></button> My Page</div>
				<button class="become-creator" onClick={()=> setBookingInterviw(true)}>Become a creator</button>
				<div class="row">
					<div class="col-sm-3">
						<div class="creator-detail-left">
							<div class="creator-detail-img"><img src={userData?.user_profile_image || dummyProfile} alt=''/></div>
							<div class="creator-social home-user">
								<ul>
								<li><Link to={userSocialLink && userSocialLink[0]?.facebook_link ? userSocialLink[0]?.facebook_link : '' }><img src={userSocialLink && userSocialLink[0]?.facebook_link ? facebook : facebookgrey}/></Link></li>
									<li><Link to={userSocialLink && userSocialLink[0]?.twitter_link ? userSocialLink[0]?.twitter_link : ''}><img src={userSocialLink && userSocialLink[0]?.twitter_link ? twitter : twittergrey}/></Link></li>
									<li><Link to={userSocialLink && userSocialLink[0]?.instagram_link ? userSocialLink[0]?.instagram_link : ''}><img src={userSocialLink && userSocialLink[0]?.instagram_link ? instagram : instagramgrey}/></Link></li>
								</ul>
							</div>
						
						</div>
					</div>
					<div class="col-sm-8">
					 <div class="creator-detail-right user-home">
							<h3>{userData?.name}</h3>
							<h4>USER</h4>
							<h5>About Me</h5>
							<p>
							{userData?.about || "Don't forget to complete the 'About Me' section"}
							</p>
							<div class="links mention-tags user-home">
						 
							<ul>
							{userData && userData?.categories && userData?.categories?.length>0 && userData?.categories.map((x,index)=>{
									return(
									<li key={index}><a href="#">{x}</a> </li>
									)
								})}
		
		
							</ul>
						</div>
						</div>
					</div>
				</div>
		</section>


		<section class="offerings">
			<h3 class="journey">Journey</h3>
				<div class="row">
					<div class="col-sm-4">
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={heart} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
									<div class="date-time">
										<ul>
											<li><img src={date} alt=''/>01.04.2024 </li>
											<li><img src={clock} alt=''/>8:00 AM </li>
										</ul>
										{/* <button class="button"><img src={buttonXAxis}/> </button> */}
									</div>
								<div class="product-data-bottom">
									<span> <img src={annete} alt=''/> </span>
									<h6><strong>Marley Westervelt</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={heart} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
									<div class="date-time">
										<ul>
											<li><img src={date} alt=''/>01.04.2024 </li>
											<li><img src={clock} alt=''/>8:00 AM </li>
										</ul>
										{/* <button class="button"><img src={buttonXAxis}/> </button> */}
									</div>
								<div class="product-data-bottom">
									<span> <img src={annete} alt=''/> </span>
									<h6><strong>Marley Westervelt</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-4">
						<div class="product-box">
							<div class="product-img">
								<img src={productImg} alt=''/>
								<div class="product-image-data">
									<span><img src={wellness} alt=''/></span>
									<h5>Offline</h5>
									<button><img src={heart} alt=''/></button>
								</div>
							</div>
							<div class="product-data">
								<h3>Morning Yoga </h3>
								<ul>
									<li class="active">Workshop</li>
									<li>Fitness</li>
									<li>Yoga</li>
								</ul>
								<p>Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
									facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing
									elit semper dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor
									sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat
									est velitolm.</p>
									<div class="date-time">
										<ul>
											<li><img src={date} alt=''/>01.04.2024 </li>
											<li><img src={clock} alt=''/>8:00 AM </li>
										</ul>
										{/* <button class="button"><img src={buttonXAxis}/> </button> */}
									</div>
								<div class="product-data-bottom">
									<span> <img src={annete} alt=''/> </span>
									<h6><strong>Marley Westervelt</strong> Yoga instructor</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
		</section>

		
</div>
<Modal
        isOpen={bookingInterview}
        onRequestClose={() => setBookingInterviw(false)}
        contentLabel="Login Modal"
        portalClassName="Login-modal-tag"
        shouldCloseOnOverlayClick={true}
      >
  <BookInterview nextStep={''} prevStep={''} data={''} submitForm={''} loader={''} loginStatus={'googleLogin'} setDashboardModal={setDashboardModal} setBookingInterviw={setBookingInterviw}/> {/* Pass selectedDays as data */}
      </Modal>
            <Modal
        isOpen={dashboardModal}
        onRequestClose={() => setDashboardModal(false)}
        contentLabel="Login Modal"
        portalClassName="google-login-modal"
        shouldCloseOnOverlayClick={false}
      >
  <SubmitForm prevStep={''} submitForm={''} loader={''} loginStatus={'googleLogin'}/> {/* Pass selectedDays as data */}
      </Modal>
	</div>
  )
}

export default UserDetail