import React from 'react'
import upDownArrow from '../../../assets/images/up-down-arrow.svg'

function RadioButton({radioType,setRadioType,handleChangeData}) {
    return (
        <div class="end-class">
                  <h2>End class</h2>
                  <ul>
                    <li onClick={()=>setRadioType('never')}><label class="container">Never
                    <input type="radio" checked={radioType === 'never'} name="radio" />
                    <span class="checkmark"></span>
                  </label> </li>
                  <li onClick={()=>setRadioType('date')}><label class="container">Date
                    <input type="radio" checked={radioType === 'date'} name="radio"/>
                    <span class="checkmark"></span>
                  </label> </li>
                  <li onClick={()=>setRadioType('after')}><label class="container">After
                    <input type="radio" checked={radioType === 'after'} name="radio" />
                    <span class="checkmark"></span>
                  </label> </li>
                  </ul>
                  <div class="date-section">
                    <input type="date" class="date" onChange={(e) => handleChangeData(e, 'date')} />
                    <div class="time-img">
                      <input type="text" class="time" placeholder="12 times" onChange={(e) => handleChangeData(e, 'after')} />
                      <img src={upDownArrow} alt='' />
                    </div>
                  </div>
                  <h5 class="class-repeat">Your class repeats from Monday to Friday.</h5>
                </div>
    )
}

export default RadioButton