import React, { useEffect, useState,useRef } from 'react'
import logo_black from '../assets/images/Logo_black.svg'
import home1 from '../assets/images/home-1.svg'
import search_custom from '../assets/images/search custom.svg'
import journey from '../assets/images/journey.svg'
import heart_2v from '../assets/images/heart 2v.svg'
import creator_icon from '../assets/images/creator icon.svg'
import message from '../assets/images/message.svg'
import notification from '../assets/images/notification.svg'
import settings from '../assets/images/settings.svg'
import dotted_btn from '../assets/images/dotted-btn.svg'
import { getApi } from '../Services/apiService'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import logout from '../assets/images/logout_icon 1.svg'
import dummy from '../assets/images/dummy-profile.jpg'
import member from '../assets/images/members.svg'
import offerings from '../assets/images/offering.svg'
import requests from '../assets/images/request.svg'
import insight from '../assets/images/insights.svg'
import bookings from '../assets/images/bookings.svg'
import orders from '../assets/images/orders.svg'
import setAvailability from '../assets/images/availability.svg'
import offering from '../assets/images/offering (1).svg'
import insightSidebar from '../assets/images/insightSidebar.svg'
import session from '../assets/images/sessionSidebar.svg'
import order from '../assets/images/orderSidebar.svg'
import logouticon from '../assets/images/logout.svg'
import Modal from 'react-modal';
import cross from '../assets/images/gray-close5.svg'
import unauthorized from '../assets/images/unauthorizedUsed.png'
import dropDown from '../assets/images/drop-arrow.svg'


function UserSidebar({ classState, name }) {
  const [loader, setLoader] = useState(false)
  const [logoutModal, setLogoutModal] = useState(false);
  const [unAuthorizedModal, setunAuthorizedModal] = useState(false);
  const [userDetails, setUserDetails] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false); // New state to handle dropdown
  const navigate = useNavigate()
  const dropdownRef = useRef(null); // Ref for the dropdown

  const location = useLocation(); // Get the current location
  const handleLogout = () => {
    localStorage.clear();
    navigate('/')
  }
  // const userRole = name?.roles?.length>0 && name?.roles[0]?.user_role
  const closeModal = (type) => {
    setLogoutModal(false);
    setunAuthorizedModal(false);

  };
  const openModal = (id) => {
    setLogoutModal(true);
  };
  const localDataUser = JSON.parse(localStorage.getItem('userDetail'));
  let userRole = (localStorage.getItem('user_role'));
  // console.log('this is userdata**********', userRole);
  const userDatass = JSON.parse(localStorage.getItem('full_user_detail'));
  const isUser = localDataUser?.roles.some(role => role.user_role === "user");  
  // console.log('this is admin details------',localDataUser);
  // userRole = userRole || null
  // const userRole = localDataUser?.roles?.length>0 && localDataUser?.roles[0]?.user_role;
  const isActive = (path) => location.pathname.startsWith(path);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
  };
 
  // Close dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

      
  useEffect(() => {
    const currentUrl = window.location.href;
    if(userRole==='creator'){
      fetchDetails();
    }
  }, [userRole])

  const fetchDetails = async () => {
    const response = await getApi(`/creator/dashboard`)
    if (response?.statusCode === 200) {
      setUserDetails(response?.data)
    }
  }


  function handleClick(e, path) {
    // console.log('this is onboarding detail',userDetails);
    // console.log('this is onboarding detail',userRole);
    if (userDetails?.onboarding_interview === 0) {
      e.preventDefault(); // Prevent navigation
      setunAuthorizedModal(true)
      // alert('You are not a verified user.');
    } else {
      navigate(path); // Allow navigation
    }
  }



  return (
    <div className={`creators-detail-left ${classState === true && 'responsiveNav'}`}>
      {/* <div class="creators-detail-left {}"> */}
      <div className="home-logo">
        <img alt='' src={logo_black} />
      </div>
      <ul>
        {userRole === 'creator' &&
          <>
            <li className={isActive('/creator/home') ? 'active' : ''}><Link to='/creator/home'><span><img alt='' src={home1} />Home</span></Link></li>
            <li className={isActive('/creator/offering/home') ? 'active' : ''} onClick={(e) => handleClick(e, '/creator/offering/home')}><span><img alt='' src={offering} />Offerings</span></li>
            <li className={isActive('/creator/session') ? 'active' : ''} ><Link to={'/creator/session'}><span><img alt='' src={session} />Session</span></Link></li>
            <li className={isActive('/creator/order') ? 'active' : ''} ><Link to='/creator/orders'><span><img alt='' src={order} />Orders</span></Link></li>
            <li className={isActive('/creator/message') ? 'active' : ''} ><Link to='/creator/message'><span><img alt='' src={message} />Messages</span></Link></li>
            {isUser &&
            <>
            <li className={isActive('/creator/dashboard') ? 'active' : ''} ><Link to='/user/offering'><span><img alt='' src={search_custom} />Discovery</span></Link></li>
            <li className={isActive('/creator/allCreator') ? 'active' : ''}><span><img alt='' src={creator_icon} />Creators</span></li>
            <li className={isActive('/creator/journey') ? 'active' : ''} ><span><img alt='' src={journey} />My Journey</span></li>
            <li className={isActive('/creator/favourites') ? 'active' : ''}><span><img alt='' src={heart_2v} />Favourites</span></li>
            </>
}
            <li className={isActive('/creator/insights') ? 'active' : ''} ><span><img alt='' src={insightSidebar} />Insights</span></li>
            <li className={isActive('/creator/notifications') ? 'active' : ''} ><span><img alt='' src={notification} />Notifications</span></li>
            <li className={isActive('/creator/settings') ? 'active' : ''} onClick={(e) => handleClick(e, '/creator/settings')} ><span><img alt='' src={settings} />Settings</span></li>
            {/* <li onClick={() => openModal()}><span><img alt='' src={logout} />Logout</span></li> */}
          </>
        }


        {userRole === 'user' &&
          <>
            <li className={isActive('/user/home') ? 'active' : ''}><Link to='/user/home'><span><img alt='' src={home1} /><a>Home</a></span></Link></li>
            {/* <li className={isActive('/user/dashboard') ? 'active' : ''}><Link to='/user/dashboard'><span><img alt='' src={search_custom} /><a>Discovery</a></span></Link></li> */}
            <li className={isActive('/user/offering') ? 'active' : ''}><Link to='/user/offering'><span><img alt='' src={offering} />Discovery</span></Link></li>
            <li className={isActive('/user/journey') ? 'active' : ''}><Link to='/user/home'><span><img alt='' src={journey} /><a>My Journey</a></span></Link></li>
            <li className={isActive('/user/favourites') ? 'active' : ''}><Link to='/user/favourites'><span><img alt='' src={heart_2v} /><a>Favourites</a></span></Link></li>
            <li className={isActive('/user/creators') ? 'active' : ''}><Link to='/user/creators'><span><img alt='' src={creator_icon} /><a>Creators</a></span></Link></li>
            <li className={isActive('/user/messages') ? 'active' : ''}><Link to='/user/home'><span><img alt='' src={message} /><a>Messages</a></span></Link></li>
            <li className={isActive('/user/notifications') ? 'active' : ''}><Link to='/user/home'><span><img alt='' src={notification} /><a>Notifications</a></span></Link></li>
            <li className={isActive('/user/settings') ? 'active' : ''}><Link to='/user/settings'><span><img alt='' src={settings} /><a>Settings</a></span></Link></li>
            {/* <li onClick={() => openModal()}><Link to=''><span><img alt='' src={logout} />Logout</span></Link></li> */}
          </>
        }

        {userRole === 'admin' &&
          <>
            <li className={isActive('/admin/members') ? 'active' : ''}><Link to='/admin/members'><span><img alt='' src={member} /><a>Members</a></span></Link></li>
            <li className={isActive('/admin/offering') ? 'active' : ''}><Link to='/admin/offering'><span><img alt='' src={offerings} />Offerings</span></Link></li>
            <li className={isActive('/admin/request') ? 'active' : ''}><Link to='/admin/request'><span><img alt='' src={requests} /><a>Requests</a></span></Link></li>
            <li className={isActive('/admin/insights') ? 'active' : ''}><Link to=''><span><img alt='' src={insight} /><a>Insights</a></span></Link></li>
            <li className={isActive('/admin/booking') ? 'active' : ''}><Link to=''><span><img alt='' src={bookings} /><a>Booking</a></span></Link></li>
            <li className={isActive('/admin/orders') ? 'active' : ''}><Link to=''><span><img alt='' src={orders} /><a>Orders</a></span></Link></li>
            <li className={isActive('/admin/availability') ? 'active' : ''}><Link to='/admin/availability'><span><img alt='' src={setAvailability} /><a>Set Availability</a></span></Link></li>
            <li className={isActive('/admin/notifications') ? 'active' : ''}><Link to=''><span><img alt='' src={notification} /><a>Notifications</a></span></Link></li>
            <li className={isActive('/admin/settings') ? 'active' : ''}><Link to='/admin/settings'><span><img alt='' src={settings} /><a>Settings</a></span></Link></li>
            {/* <li onClick={() => openModal()}><Link to=''><span><img alt='' src={logout} />Logout</span></Link></li> */}
          </>
        }
      </ul>
      {/* <Link to='/'><button >Logout</button></Link> */}
      <div class="user-left-profile">
        <span><img alt='' src={userDatass?.user_profile_image || localDataUser?.user_profile_image || dummy} /> </span>
        <div class="user-content">
          <h1>{localDataUser?.name}</h1>
          <p>{userRole?.charAt(0)?.toUpperCase() + userRole?.slice(1)?.toLowerCase()}</p>
        </div>
        <button className="button" onClick={toggleDropdown}>
          <img alt='' src={dotted_btn} /> </button>
        {dropdownOpen && (
          <div className="dropdown-menu" ref={dropdownRef}>
          {/* <span className='arrow'><img src={dropDown} /></span> */}
            <ul>
              <li style={{cursor:'pointer'}} onClick={() => openModal()}><img alt='' src={logout} />Logout</li>
              {/* <li onClick={() => navigate('/user/settings')}>Settings</li> */}
               
            </ul>
          </div>
        )}
      </div>

      <Modal
        isOpen={logoutModal}
        onRequestClose={closeModal}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay add-category delete logout `} 
        portalClassName="logout-modal-lg">
        
        <span> <img src={logouticon} /></span>
        <h2>Are you sure you want to logout?</h2>
        <p></p>
        <div className="modal-buttons">
          <button className="cancel-btn" onClick={closeModal}>Cancel</button>
          <button className="save-btn" onClick={handleLogout} disabled={loader}>
            {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Yes, I'm sure</button>
        </div>
      </Modal>

      <Modal
        isOpen={unAuthorizedModal}
        onRequestClose={closeModal}
        contentLabel="Delete Modal"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        overlayClassName={`modal-overlay add-category delete logout unauthorize verification-modal`} >
          <button className='close-button' onClick={() => setunAuthorizedModal(false)}><img src={cross} alt='' /></button>
        <h1>Verification Required</h1>
        <h2>You can’t create a session or product until your specialization is verified by our team. If you've already submitted your request, we'll get back to you soon!</h2>
        <span> <img src={unauthorized} /></span>
        <p></p>
      </Modal>
    </div>
  )
}

export default UserSidebar