
import React, { useEffect, useState, useCallback } from 'react';
import NoDataImg from '../../assets/images/no-data-img.svg'

function NoDataFound() {
    

    return (
        <div className="no-data-main">
           <img src={NoDataImg}/>
                    <h5>Sorry, no data found matching your search.</h5>
                  
        </div>
    );
}
export default NoDataFound;