// stripeService.js

import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe('your-publishable-key-here');

// Function to create a payment intent
export const createPaymentIntent = async (amount) => {
    const stripe = await stripePromise;  // Ensure stripe.js is loaded

  try {
    const response = await fetch('/create-payment-intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error || 'Failed to create payment intent');
    }

    return data.client_secret;
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
};

// Function to confirm payment
export const confirmPayment = async (stripe, cardElement, clientSecret) => {
  try {
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      throw new Error(error.message);
    }

    return paymentIntent;
  } catch (error) {
    console.error('Error confirming payment:', error);
    throw error;
  }
};
