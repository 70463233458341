import { useEffect, useState } from "react";
import { getApi, putApi } from "../../../Services/apiService";

function BasicsEmailNotification({ }) {
  const [creatorEmailUpdate, setCreatorEmailUpdate] = useState({
    product_updates: false,
    account_modifications: false,
    new_registrations: false,
    free_consultation: false,
    new_messages: false,
    comments: false,
    payment_transactions: false,
    product_status: false,
    session_updates: false,
  })
  const [userEmailUpdate, setUserEmailUpdate] = useState({
    booking_updates: false,
    account_modifications: false,
    booking_status_updates: false,
    free_consultation: false,
    new_messages: false,
    payment_transactions: false,
    session_updates: false,
  })
  const [adminEmailUpdate, setAdminEmailUpdate] = useState({
    creator_registration: false,
    user_registration: false,
    new_registrations: false,
    free_consultation: false,
    new_messages: false,
    payment_transactions: false,
    comments: false,
  })

  const userDetails = localStorage.getItem('user_role')

  const handleSetData = (e) => {
    const { name, checked } = e.target;
    if (userDetails === 'creator') {
      setCreatorEmailUpdate(prevData => {
        const updatedData = {
          ...prevData,
          [name]: checked
        };
        handleSaveData(updatedData);
        return updatedData;
      });
    }
    if (userDetails === 'user') {
      setUserEmailUpdate(prevData => {
        const updatedData = {
          ...prevData,
          [name]: checked
        };
        handleSaveData(updatedData);
        return updatedData;
      })
    }
    if (userDetails === 'admin') {
      setAdminEmailUpdate(prevData => {
        const updatedData = {
          ...prevData,
          [name]: checked
        };
        handleSaveData(updatedData);
        return updatedData;
      })
    }
  }

  useEffect(() => {
    handleGetData()
  }, [])

  const handleSaveData = async (updatedData) => {
    const response = await putApi('/notification-settings', updatedData)
    if (response?.statusCode === 200) {
      handleGetData()
    }
  }

  const handleGetData = async () => {
    const response = await getApi('/notification-settings')
    if (response?.statusCode === 200) {
      if (userDetails === 'creator') {
        setCreatorEmailUpdate({
          product_updates: response?.data?.product_updates,
          account_modifications: response?.data?.account_modifications,
          new_registrations: response?.data?.new_registrations,
          free_consultation: response?.data?.free_consultation,
          new_messages: response?.data?.new_messages,
          comments: response?.data?.comments,
          payment_transactions: response?.data?.payment_transactions,
          product_status: response?.data?.product_status,
          session_updates: response?.data?.session_updates,
        })
      }
      if (userDetails === 'user') {
        setUserEmailUpdate({
          booking_updates: response?.data?.booking_updates,
          account_modifications: response?.data?.account_modifications,
          booking_status_updates: response?.data?.booking_status_updates,
          free_consultation: response?.data?.free_consultation,
          new_messages: response?.data?.new_messages,
          payment_transactions: response?.data?.payment_transactions,
          session_updates: response?.data?.session_updates,
        })
      }
      if (userDetails === 'admin') {
        setAdminEmailUpdate({
          creator_registration: response?.data?.creator_registration,
          user_registration: response?.data?.user_registration,
          new_registrations: response?.data?.new_registrations,
          free_consultation: response?.data?.free_consultation,
          new_messages: response?.data?.new_messages,
          payment_transactions: response?.data?.payment_transactions,
          comments: response?.data?.comments,
        })
      }

    }
  }

  return (
    <div class="email">
      {userDetails === 'creator' &&
        <>
          <div class="section first">
            <h1>Announcements and resources</h1>

            <div class="content">
              <h3>Product updates and community announcements</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle1" checked={creatorEmailUpdate?.product_updates} name="product_updates" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Account modifications</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle2" checked={creatorEmailUpdate?.account_modifications} name="account_modifications" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="section second">
            <h1>Notification</h1>

            <div class="content">
              <h3>New registrations of participants in the course</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle3" checked={creatorEmailUpdate?.new_registrations} name="new_registrations" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Requests for free consultation</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle4" checked={creatorEmailUpdate?.free_consultation} name="free_consultation" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>New messages</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle5" checked={creatorEmailUpdate?.new_messages} name="new_messages" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Comments or questions from course participants</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle6" checked={creatorEmailUpdate?.comments} name="comments" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Completed payments or other financial transactions</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle7" checked={creatorEmailUpdate?.payment_transactions} name="payment_transactions" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Product status information</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle8" checked={creatorEmailUpdate?.product_status} name="product_status" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Session updates information</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle9" checked={creatorEmailUpdate?.session_updates} name="session_updates" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
        </>
      }
      {userDetails === 'user' &&
        <>
          <div class="section first">
            <h1>Announcements and resources</h1>

            <div class="content">
              <h3>Booking/orders  updates</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle1" checked={userEmailUpdate?.booking_updates} name="booking_updates" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Account modifications</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle2" checked={userEmailUpdate?.account_modifications} name="account_modifications" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="section second">
            <h1>Notification</h1>

            <div class="content">
              <h3>Requests for free consultation</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle4" checked={userEmailUpdate?.free_consultation} name="free_consultation" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>New messages</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle5" checked={userEmailUpdate?.new_messages} name="new_messages" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Completed payments or other financial transactions</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle7" checked={userEmailUpdate?.payment_transactions} name="payment_transactions" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Booking/orders status information</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle8" checked={userEmailUpdate?.booking_status_updates} name="booking_status_updates" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Session updates information</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle9" checked={userEmailUpdate?.session_updates} name="session_updates" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
        </>
      }
      {userDetails === 'admin' &&
        <>
          <div class="section first">
            <h1>Accounts</h1>

            <div class="content">
              <h3>Registration of creator</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle1" checked={adminEmailUpdate?.creator_registration} name="creator_registration" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Registration of user</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle2" checked={adminEmailUpdate?.user_registration} name="user_registration" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="section second">
            <h1>Notification</h1>

            <div class="content">
              <h3>New registrations of participants in the course</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle4" checked={adminEmailUpdate?.new_registrations} name="new_registrations" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Requests for free consultation</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle5" checked={adminEmailUpdate?.free_consultation} name="free_consultation" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>New messages</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle7" checked={adminEmailUpdate?.new_messages} name="new_messages" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Comments or questions from course participants</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle8" checked={adminEmailUpdate?.comments} name="comments" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
            <div class="content">
              <h3>Completed payments or other financial transactions</h3>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" id="toggle9" checked={adminEmailUpdate?.payment_transactions} name="payment_transactions" onClick={(e) => handleSetData(e)} />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
        </>
      }


    </div>
  );
}

export default BasicsEmailNotification;