import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../assets/images/logo.svg';
import logo_mobile from '../assets/images/logo-mobile.svg'
import Burger from '../assets/images/burger.svg'
import close from '../assets/images/close.svg'
import insta from '../assets/images/insta.svg'
import fb1 from '../assets/images/fb1.svg'
import linked from '../assets/images/linked.svg'
import twiter from '../assets/images/twiter.svg'
import you from '../assets/images/you.svg'
import Modal from 'react-modal';
import UserMultiStep from '../Component/UserComponents/UserJoin/userMultistep'
import MultiStep from '../Component/CreatorComponents/CreatorJoin/multiStep';
import cross from '../assets/images/cross-button.svg'
import { useDispatch } from 'react-redux';
import { clearUserData } from '../Counter/userSignUpSlice';
import { clearData } from '../Counter/signupSlice';

const Header = () => {
	const [show, setShow] = useState(false)
	const [userModalIsOpen, setUserModalIsOpen] = useState(false);
	const [creatorModalIsOpen, setCreatorModalIsOpen] = useState(false);
	const dispatch = useDispatch();
const navigate = useNavigate();
	const location = useLocation();
	const isActive = (path) => {
		return location.pathname === path ? 'active' : '';
	};
	const buttonShow = () => {
		setShow(true)
	}
	const buttonHide = () => {
		setShow(false)
	}
	const openModal = (value) => {
		dispatch(clearUserData())
		dispatch(clearData())
		if (value === 'creator')
			setCreatorModalIsOpen(true);

		if (value === 'user')
			setUserModalIsOpen(true)
	};

	const closeModal = (value) => {
		{
			if (value === 'creator')
				setCreatorModalIsOpen(false);
	
			if (value === 'user')
				setUserModalIsOpen(false)
			// value === 'creator' ?
			// setCreatorModalIsOpen(false) :
			// setUserModalIsOpen(false)
		}
	};
	return (
		<header>
			<div class="container">
				<nav class="navbar navbar-expand-lg navbar-light">
					<Link class="navbar-brand" href="#">
						<img className='web-view' src={logo} alt='logo' />
						<img className='mobile-view' src={logo_mobile} alt='logo' />
						{/* <Link to='/user/login'> */}
						<Link to='/user/login'><button className='mobile-login' >Login</button></Link>
						{/* </Link> */}
					</Link>
					<button className='burger' onClick={buttonShow}>
						<img src={Burger}  alt=''/>
					</button>
					{show === true && <div className='resposive-menu' onClick={buttonHide}>
						<div className='mobile-links'>
							<button className='close' ><img src={close} alt='logo' /></button>
							<ul>
								<li><Link class="nav-link" to="/">Home</Link></li>
								<li><Link class="nav-link" to="/creator">Creators</Link></li>
								<li><Link class="nav-link" to='/discovering'>Discover offerings</Link></li>
							</ul>
						</div>
						<div className='social'>
							<ul>
								<li><a href=''><img src={insta} alt='icon' /></a></li>
								<li><a href=''><img src={fb1} alt='icon' /></a></li>
								<li><a href=''><img src={linked} alt='icon' /></a></li>
								<li><a href=''><img src={twiter} alt='icon' /></a></li>
								<li><a href=''><img src={you} alt='icon' /></a></li>

							</ul>
							<h4>hello@microcosm.com</h4>
						</div>
					</div>}
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav mr-auto">
							<li className={`nav-item ${isActive('/')}`}>
								<Link class="nav-link" to="/">Home</Link>
							</li>
							<li className={`nav-item ${isActive('/creator')}`}>
								<Link class="nav-link" to="/creator">Creators</Link>
							</li>
							<li className={`nav-item ${isActive('/discovering')}`}>
								<Link class="nav-link" to='/discovering'>Discover offerings</Link>
							</li>
						</ul>



						<div class="form-inline my-2 my-lg-0">
							{/* <Link to='/creator/login'><button class="join-creator">Join as a creator</button></Link> */}
							{/* <button class="join-creator" onClick={() => openModal('creator')}>Join as a creator</button> */}
							<button class="join-creator" onClick={() => navigate('/creator/login')}>Join as a creator</button>
							<Link to='/user/login'><button>Log In</button></Link>
							{/* <button onClick={() => openModal('user')}>Log In</button> */}
						</div>
					</div>
				</nav>
			</div>
			{/* <Modal
				isOpen={userModalIsOpen}
				onRequestClose={() => closeModal('user')}
				contentLabel="Login Modal"
				shouldCloseOnOverlayClick={false}
				portalClassName="Login-modal-tag"
			>
				<button className='close-button' onClick={() => closeModal('user')}><img src={cross} alt=''/></button>
				<UserMultiStep userModalIsOpen={userModalIsOpen} setUserModalIsOpen={setUserModalIsOpen} />
			</Modal> */}
			{/* <Modal
				isOpen={creatorModalIsOpen}
				onRequestClose={() => closeModal('creator')}
				contentLabel="Login Modal"
				shouldCloseOnOverlayClick={false}
				portalClassName="Login-modal-tags"

			>
				<button className='close-button' onClick={() => closeModal('creator')}><img src={cross} alt=''/></button>
				<MultiStep creatorModalIsOpen={creatorModalIsOpen} setCreatorModalIsOpen={setCreatorModalIsOpen} />
			</Modal> */}
		</header>
	);
}

export default Header;
