import React, { useState, useEffect } from 'react';
import { getApi, postApi } from '../../Services/apiService';

const AvailabilityModal = ({ data, handleModalClose, modalStartDate }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [sessionDuration, setSessionDuration] = useState('');
    const [availability, setAvailability] = useState('available'); // Manage availability state
    const [loader, setLoader] = useState(false);


    console.log('this is start date form page',modalStartDate);

useEffect(() => {
    console.log('this is start date form page',modalStartDate);
    // Set default start date to today
    if (data.startDate) {
        // Set default start date to the start of the month passed from props
        const startOfMonth = new Date(data.startDate.getFullYear(), data.startDate.getMonth(), 1);
        setStartDate(startOfMonth.toISOString().split('T')[0]); // Format YYYY-MM-DD
    }   
    else {
        const today = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
        setStartDate(today);
    }
}, [data.startDate]);


const handleSave = async() => {
    setLoader(true)
    // Function to convert 12-hour time to 24-hour format
    const convertTo24HourFormat = (time12h) => {
        const [time, modifier] = time12h.split(' '); // Split time and AM/PM
        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00'; // If it's 12 AM, set hours to 00
        }
        if (modifier === 'PM' && hours !== '12') {
            hours = String(parseInt(hours, 10) + 12); // Convert PM to 24-hour format
        }

        return `${hours}:${minutes}`; // Return time in HH:MM format
    };

    // Convert date to YYYYMMDDTHHMMSSZ format
    const formatDate = (date) => {
        const d = new Date(date);
        const year = d.getUTCFullYear();
        const month = String(d.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(d.getUTCDate()).padStart(2, '0');
        const hours = String(d.getUTCHours()).padStart(2, '0');
        const minutes = String(d.getUTCMinutes()).padStart(2, '0');
        const seconds = String(d.getUTCSeconds()).padStart(2, '0');
        return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
    };

    // Map selected days to BYDAY format
    const dayMapping = {
        'Monday': 'MO',
        'Tuesday': 'TU',
        'Wednesday': 'WE',
        'Thursday': 'TH',
        'Friday': 'FR',
        'Saturday': 'SA',
        'Sunday': 'SU'
    };
    const byDay = data.map(day => dayMapping[day]).join(',');

    // Construct the rruleString
    const rruleString = `FREQ=WEEKLY;DTSTART=${formatDate(startDate)};BYDAY=${byDay};UNTIL=${formatDate(endDate)}`;

    // Convert start and end times to 24-hour format
    const convertedStartTime = convertTo24HourFormat(startTime);
    const convertedEndTime = convertTo24HourFormat(endTime);

    try {
        // Pass the availability data to the parent and close the modal
        const availabilityData = {
            startTime: convertedStartTime,
            endTime: convertedEndTime,
            sessionDuration: parseInt(sessionDuration, 10),
            // availability: availability,
            rruleString
        };

        const response = await postApi('/admin/availbility', availabilityData);
        
        if (response.statusCode === 200) {
            setLoader(false)
            handleModalClose(true);
        } else {
            // Handle error
        }
    } catch (error) {
        console.error('Error submitting booking:', error);
    }
};


    return (
        <div className="availability-inner">
            <h3>Update Availability</h3>
            <div className="row">
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Start date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>End Date</label>
                        <input
                            type="date"
                            className="form-control"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Earliest start time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Latest start time</label>
                        <input
                            type="time"
                            className="form-control"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label>Duration session </label>
                        <input
                            type="text"
                            className="form-control"
                            value={sessionDuration}
                            onChange={(e) => setSessionDuration(e.target.value)}
                        />
                    </div>
                </div>
                {/* <div className="col-sm-12">
                    <div className="form-group">
                        <label>Are you available on the above dates?</label>
                        <ul>
                            <li>
                                <label className="available">
                                    <input
                                        type="radio"
                                        name="availability"
                                        value="available"
                                        checked={availability === 'available'}
                                        onChange={() => setAvailability('available')}
                                    />
                                    <span className="checkmark"></span>
                                    Available
                                </label>
                            </li>
                            <li>
                                <label className="available">
                                    <input
                                        type="radio"
                                        name="availability"
                                        value="unavailable"
                                        checked={availability === 'unavailable'}
                                        onChange={() => setAvailability('unavailable')}
                                    />
                                    <span className="checkmark"></span>
                                    Unavailable
                                </label>
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div className="col-sm-12">
                    <button className="cancel ms-0" onClick={()=>{handleModalClose(false)}}>Cancel</button>
                    <button className="save" onClick={handleSave} disabled={loader}>
                    {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save</button>
                </div>
            </div>
        </div>
    );
};

export default AvailabilityModal;
