import React, { useEffect, useState } from 'react'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import Slider from 'react-slick';
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import { useParams } from 'react-router-dom'
import { getApi } from '../../../Services/apiService'
import Modal from 'react-modal';
import BookingModal from './bookingModal'
import cross from '../../../assets/images/gray-closebtn12.svg'
import graycross from '../../../assets/images/gray-cross.svg'
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import { useNavigate } from 'react-router-dom'
import location from '../../../assets/images/map-pin1.svg'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import discoveryCall from '../../../assets/images/discovery-call.svg'
import checkTravel from '../../../assets/images/check-travel.svg'
import session from '../../../assets/images/session-calender.svg'
import PaymentModal from './payment'
import CheckoutSuccess from './checkoutSuccess'
import hearts from '../../../assets/images/heart.svg'
import CustomBookingModal from './customBookingModal'
import { stripePromise } from '../../../Config/stripeService';  // Import stripePromise from the service
import { Elements } from '@stripe/react-stripe-js';



function SingleOfferingData() {

    const [classState, setClassState] = useState(false)
    const [userDetails, setUserDetails] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [customBookingModal, setCustomBookingModal] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [filtersModal, setFiltersModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [showHideComp, setshowHideComp] = useState(false);
    const [similarClass, setSimilarClass] = useState([])

    const userROle = localStorage.getItem('user_role')


    const openModal = (src) => {
        setModalContent(src);
        setModalIsOpen(true);
    };

    const closeImgModal = () => {
        setModalIsOpen(false);
        setModalContent('');
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    // Ensure description is defined and is a string
    const description = userDetails?.description || '';
    const isLongDescription = description.length > 300;
    const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;

    const params = useParams();
    console.log("params>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", params?.id)

    useEffect(() => {
        const currentUrl = window.location.href;

        // Check if the URL contains 'creator/offering'
        if (currentUrl.includes('creator/offering')) {
            setshowHideComp(true)
        }
        fetchDetails();

    }, [])

    const navigate = useNavigate();
    const singleService = (id) => {
        if (userROle === 'creator') {
            navigate(`/creator/offering/home`)
        }
        if (userROle === 'user') {
            navigate(`/user/offering/${id}`)
        }
    }
    const fetchDetails = async () => {
        const response = await getApi(`/user/Service?offerId=${params?.id}`)
        if (response?.statusCode === 200) {
            setUserDetails(response?.data)
        }
    }

    // Fetch similar classes once userDetails.id is available
    useEffect(() => {
        if (userDetails?.id) {
            similarClasses();
        }
    }, [userDetails?.id]); // This effect will trigger when userDetails.id is updated


    const similarClasses = async () => {
        const response = await getApi(`/user/similar-class?userId=${userDetails?.userData?.id}`)
        if (response?.statusCode === 200) {
            setSimilarClass(response?.data)
        }
    }


    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 1500,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 1,
    //                 infinite: true,
    //                 dots: true,
    //             },
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //     ],
    // };
    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const closeModal = () => {
        setIsModal(false)
        fetchDetails()
    }
    const closeCustomBookingModal = () => {
        setCustomBookingModal(false)
        fetchDetails()
    }

    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [])
    const openBookingModal = () => {
        if ((userDetails?.session_offer_type === '2' && userDetails?.session_type === '1') && (userDetails?.package_type === '3' || userDetails?.package_type === '2') ) {
            setCustomBookingModal(true)
        }
        else if (((userDetails?.session_offer_type === '1' && userDetails?.session_type === '1') && (userDetails?.availbility_period === '1') )){
            setCustomBookingModal(true)
        }
        else {
            setIsModal(true)
        }
    }

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };
const back = () => {

    navigate('/user/offering')

}

    return (
        <div class="creators-detail offering-user-side">
            <UserSidebar classState={classState} />
            <div className='discover-offering right-main'>
                <section class="creators-top">
                    <h2 onClick={() => back()}><span><img src={arrowBtn} /> {userDetails?.title}</span></h2>

                    <div class="row">
                        <div class="col-sm-7 col-xl-8 col-lg-7">
                            <div class="offering-left">
                                <h3><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button></h3>
                                {userDetails?.tags && userDetails?.tags?.length > 0 && userDetails?.tags?.map((x, index) => {
                                    return (
                                        <label key={index}>{x?.tag_name}</label>
                                    )
                                })}
                                {/* <label>Yoga</label>
                                    <label>Fitness</label>
                                    <label>Meditation</label> */}

                                <div class="annete">
                                    <span><img src={userDetails?.userData?.user_profile_image || dummyProfile} alt='' /></span>
                                    <div class="annete-text">
                                        <h3>{userDetails?.userData && userDetails?.userData?.name}</h3>
                                        {userDetails?.userData?.specializations.length ? (
                                            <>
                                                <h4 className={userDetails?.userData?.specializations[0]?.area_of_work === '' && 'area-of-work'}>
                                                    {userDetails?.userData?.specializations[0]?.area_of_work}
                                                </h4>
                                                <h5 className={userDetails?.userData?.specializations[0]?.experience === '' && 'area-of-work'}>
                                                    {userDetails?.userData?.specializations[0]?.experience} of experience
                                                </h5>
                                            </>) :
                                            <>
                                            </>
                                        }
                                        <button class="discovery">Discovery call <img src={discoveryCall} /></button>
                                    </div>
                                </div>
                                <div className="about-left">
                                    <h3>About Class</h3>
                                    <p>
                                        {isExpanded || !isLongDescription ? description : truncatedDescription}
                                        <br />
                                        {isLongDescription && (
                                            <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </strong>
                                        )}
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div class="col-sm-5 col-xl-4 col-lg-5">
                            <div class="offering-top-right">
                                <h2>{userDetails?.offer_name || 'Morning Yoga'}</h2>
                                <div class="offering-share">
                                    <ul>
                                        <li><a href=""><img src={share} alt='' /></a></li>
                                        <li><a href=""><img src={heart} alt='' /></a></li>
                                    </ul>
                                </div>
                                <div class="week">
                                    <ul>
                                        {(userDetails?.session_type !== '2' && userDetails?.session_offer_type !== 2) && (
                                            <li><img src={clock} alt='' /> {userDetails?.session_duration} min</li>
                                        )}
                                        {(userDetails?.session_type === '1' && userDetails?.session_offer_type == 1) && (
                                            <li><img src={session} alt='' /> 1 session</li>
                                        )}
                                        {userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 && (
                                            <li><img src={calendar} alt='' /> {userDetails?.no_of_sessions}x per {userDetails?.package_period}, {userDetails?.package_timeframe} {userDetails?.package_period}</li>
                                        )
                                        }
                                    </ul>
                                </div>
                                <div class="week">
                                    <ul>
                                        <li><img src={people} alt='' /> {userDetails?.session_type === '1' && userDetails?.session_offer_type == 1 ? (
                                            <p>Private Session</p>
                                        ) : userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 ? (
                                            <p>Private Multiple sessions</p>
                                        ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 1 ? (
                                            <p>{(userDetails?.max_group_size - userDetails?.seats) || '-'} booked, {userDetails?.max_group_size} max </p>
                                        ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 2 ? (
                                            <p>{(userDetails?.max_group_size - userDetails?.seats) || '-'} booked, {userDetails?.max_group_size} max </p>
                                        ) : null}</li>

                                        <li><img src={mapPin} alt='' /> Broome St, New York</li>
                                        <li><img src={checkTravel} alt='' /> Creator can travel</li>
                                    </ul>
                                </div>

                                <div class="offering-right-bottom">
                                    <h4>Price <strong>${userDetails?.price || userDetails?.minimum_price || userDetails?.maximum_price}</strong></h4>
                                    {((((userDetails?.session_offer_type === '2' && userDetails?.session_type === '2')||(userDetails?.session_offer_type === '1' && userDetails?.session_type === '2')) && userDetails?.seats === 0)) ? (
                                        <button>Session slots are full</button>
                                    ):(
                                        <button onClick={() => { openBookingModal() }}>Select time slots</button>

                                    )
                                    }
                                </div>
                            </div>
                        </div>
                        {(userDetails.offerMaterials?.length || userDetails.cover_image) ? (
                            <>
                                <div className="col-sm-8 mt-4 publish-after-offer">
                                    <div className="row">
                                        <div className="col-sm-10 col-xl-10 col-lg-10">
                                            {userDetails.cover_image && (
                                                <>
                                                <div className='main-image offiering-img-cs'>
                                                    <img
                                                        src={userDetails.cover_image}
                                                        alt="Main"
                                                        onClick={() => openModal(userDetails?.cover_image)}
                                                    />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="col-sm-2 col-xl-2 col-lg-2">
                                            <div className='gallery-small-main offiering-multiple image'>
                                            {userDetails.offerMaterials && userDetails.offerMaterials.map((material, index) => (
                                                material.media_type === 'image' ? (
                                                    // <div className="gallery-small" key={index} onClick={() => openModal(material.file_url)}>
                                                    <div className="gallery-small-one" key={index}>
                                                        <img src={material.file_url} alt={`Gallery ${index + 1}`} />
                                                    </div>
                                                ) : material.media_type === 'video' ? (
                                                    // <div className="gallery-small" key={index} onClick={() => openModal(material.file_url)}>
                                                    <div className="gallery-small-one" key={index}>
                                                        <video controls>
                                                            <source src={material.file_url} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                ) : null
                                            ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                        ) :
                            <></>
                        }





                    </div>

                </section>
                {userDetails?.id && userDetails?.otherClassess?.length > 0 && (

                    <section class="other-class-main  mt-5 pt-3 border-top-creator">

                        <h3 className='mt-3'>Other classes by {userDetails?.userData?.name}</h3>
                        <button class="see-all mt-3">See all offers<img src={arrow} alt='' /></button>
                        <div>
                            <Slider {...settings}>
                                {userDetails?.id && (userDetails?.otherClassess.map((x, index) => {
                                    return (

                                        <div className="col-sm-4 p-2" onClick={() => singleService(x?.id)} key={index}>
                                            <div className="product-box">
                                                <div className="product-img">
                                                    <img src={x?.cover_image} alt='' />
                                                    <div className="product-image-data">
                                                    {x?.service_type === "1" && (
                                                            <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                        )}
                                                            <h5>
                                                                {x?.service_type === "2"
                                                                    ? (x?.product_kind === "0" ? 'Physical' : 'Digital')
                                                                    : (x?.service_type === "1"
                                                                        ? (x?.location_type === "1" ? 'Online'
                                                                            : (x?.location_type === "2" ? 'Offline'
                                                                                : (x?.location_type === "3" ? 'Hybrid' : '')))
                                                                        : 'Offline')}
                                                            </h5>
                                                            <button><img src={hearts} alt='' /></button>
                                                        <h6><img src={location} alt='' />Austin, Texas</h6>
                                                    </div>
                                                </div>
                                                <div className="product-data">
                                                    <h3>{x?.name} </h3>
                                                    <ul className='product-data-tags'>
                                                        {x?.tags && x?.tags.length > 0 && x?.tags.slice(0, 3).map((y, i) => {
                                                            return (
                                                                <li className={i === 0 && "active"}>{y?.tag_name}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                    <p>
                                                        {x?.description}
                                                    </p>
                                                    <div className="product-data-bottom">
                                                        <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                        <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }))}
                            </Slider>
                        </div>
                    </section>
                )}


                {userDetails?.id && similarClass.length > 0 && (

                    <section class="other-class-main">

                        <h3>Similar classes</h3>
                        <button class="see-all">See all offers<img src={arrow} /></button>

                        <Slider {...settings}>
                            {userDetails?.id && (
                                similarClass?.map((x, index) => (
                                    <div className="col-sm-4" onClick={() => singleService(x?.id, x)} key={index}>
                                        <div className="product-box">
                                            <div className="product-img">
                                                <img src={x?.cover_image} alt='' />
                                                <div className="product-image-data">
                                                    <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                    <h5>Offline</h5>
                                                    <button><img src={hearts} alt='' /></button>
                                                </div>
                                            </div>
                                            <div className="product-data">
                                                <h3>{x?.name} </h3>
                                                <ul>
                                                    {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                                                        <li className={i === 0 ? "active" : ''} key={i}>
                                                            {y?.tag_name}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p>{x?.description}</p>
                                                <div className="product-data-bottom">
                                                    <span><img src={x?.user?.user_profile_image && x?.user?.user_profile_image || bannerRight} alt='' /></span>
                                                    <h6><strong>{x?.user && x?.user?.name}</strong>  {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </Slider>

                    </section>
                )}

                <Modal
                    isOpen={isModal}
                    onRequestClose={() => closeModal()}
                    contentLabel="Login Modal"
                    portalClassName="boookingModal"
                    shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => closeModal()}><img src={cross} alt='' /></button>
                    <BookingModal data={userDetails} closeModal={closeModal} setFiltersModal={setFiltersModal} />
                </Modal>

                {/* for one year monthly and custom starts */}

                <Modal
                    isOpen={customBookingModal}
                    onRequestClose={() => closeCustomBookingModal()}
                    contentLabel="Login Modal"
                    portalClassName="boookingModal custom"
                shouldCloseOnOverlayClick={false}
                >
                    <button className='close-button' onClick={() => closeCustomBookingModal()}><img src={cross} alt='' /></button>
                    <CustomBookingModal data={userDetails} closeModal={closeCustomBookingModal} setFiltersModal={setFiltersModal} />
                </Modal>

                {/* for one year monthly and custom ends*/}


                <Modal
                    isOpen={filtersModal} onRequestClose={() => setFiltersModal(false)} contentLabel="Payment Method"
                    overlayClassName={`modal-overlay checkout-pay`}
                    portalClassName="checkout">
                    <button className='close-button' onClick={() => setFiltersModal(false)}><img src={graycross} alt='' /></button>
          <Elements stripe={stripePromise}>

                    <PaymentModal setFiltersModal={setFiltersModal} setSuccessModal={setSuccessModal}></PaymentModal>
          </Elements>

                </Modal>

                <Modal
                    isOpen={successModal}
                    onRequestClose={() => setSuccessModal(false)}
                    contentLabel="Payment Method"
                    portalClassName="checkout success"
                    overlayClassName={`modal-overlay thanks-pay`}
                    shouldCloseOnOverlayClick={false}

                >
                    <button className='close-button' onClick={() => setSuccessModal(false)}><img src={graycross} alt='' /></button>
                    <CheckoutSuccess setFiltersModal={setFiltersModal}></CheckoutSuccess>
                </Modal>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal media-modal-clicks"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}><img src={cross} alt='' /></button>

                    {modalContent && (modalContent.endsWith('.png') || modalContent.endsWith('.jpg') || modalContent.endsWith('.jpeg') || modalContent.endsWith('.svg')) ? (
                    <div className='media-click-img'><img src={modalContent} alt="Modal Content" /></div>
                    ) : modalContent.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={modalContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </Modal>


            </div>
        </div>
    )
}

export default SingleOfferingData