import React, { useState } from 'react';
import checkCircle from '../../../../assets/images/check-mark.svg';

function ProductDetailsModal({ setProductDetailsModal, setProductDeliveryAddressModal, setFiltersModal, type, setSelectedOption }) {
    const [formData, setFormData] = useState({
        quantity: '',
        selectedOption: '', // Add selectedOption to formData
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let validationErrors = {};

        if (!formData.quantity) {
            validationErrors.quantity = 'Quantity is required';
        } else if (formData.quantity.trim().length !== formData.quantity.length) {
            validationErrors.quantity = 'Quantity cannot contain spaces';
        }

        if (!formData.selectedOption) {
            validationErrors.selectedOption = 'Please select a delivery option';
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const continueNext = () => {
        if (validateForm()) {
            setSelectedOption(formData?.selectedOption);
            if (formData?.selectedOption === 'Pickup') {
                setProductDetailsModal(false); // Close ProductDetails Modal
                setFiltersModal(true)
            }
            else {
                setProductDetailsModal(false); // Close ProductDetails Modal
                setProductDeliveryAddressModal(true); // Open next Modal
            }
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleOptionChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            selectedOption: e.target.value // Update selectedOption when dropdown changes
        }));
    };

    return (
        <>
            <div className='modal-header'>
                {/* Add header content if necessary */}
            </div>
            <div>
                <div className="payment-modal">
                    {/* Add content for payment modal if necessary */}
                </div>
            </div>
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-body">
                        <div className="thanks">
                            <div className="top">
                                <h1 className='text-center'>Product Details</h1>
                                <p className='mb-3'>Please select quantity and delivery method.
                                    If choosing pick up, details will follow after ordering.</p>

                            </div>
                            <div className='row'>
                                <div className='col-sm-3'>
                                    <div className='form-group'>
                                        <label htmlFor="quantity">Quantity</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            id='quantity'
                                            value={formData.quantity}
                                            onChange={handleChange}
                                            placeholder="Enter quantity"
                                        />
                                        {errors.quantity && <div className='error'>{errors.quantity}</div>}
                                    </div>
                                </div>
                                <div className='col-sm-9'>
                                    <div className='form-group'>
                                        <label htmlFor="selectedOption">Delivery Option</label>
                                        <select
                                            className='form-control'
                                            id='selectedOption'
                                            value={formData.selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            <option value="">Select an option</option>
                                            <option value="Delivery">Delivery</option>
                                            <option value="Pickup">Pick up</option>
                                        </select>
                                        {errors.selectedOption && <div className='error'>{errors.selectedOption}</div>}
                                    </div>
                                </div>

                                <div className='product-detail-list'>
                                    <ul className='p-0'>
                                    {(formData?.selectedOption === 'Delivery' || formData?.selectedOption === 'Pickup') && (
                                        <>
                                        <li className='aproxi'>  Approximate Delivery date: <span>April 17</span></li>
                                        </>
                                    )}
                                            
                                    {formData?.selectedOption === 'Pickup' && (
                                        <>
                                        <li className='pt-2 pick-up'>Pickup area: <span>North Austin</span></li>
                                        <li className='receive'>You will receive the exact address after payment.</li>
                                        </>
                                    )}

                                    </ul>

                                </div>
                                <div className='sub-total-details'>
                                    <div className='row align-item-cener'>
                                        <div className='col-md-8'>
                                            <ul className='p-0'>
                                                <li className='sub-total'>Subtotal Price<span>$26,25</span></li>
                                                {formData?.selectedOption === 'Pickup' && (
                                        <>
                                                <li>TAX of $13 is included in the price</li>
                                                </>
                                                )}
                                            </ul>
                                        </div>
                                        <div className='col-md-4'>
                                            <button className="pay float-end" onClick={continueNext}> Continue </button>
                                        </div>
                                    </div>
                                    {/* <div class=" mt-2">
                                    <div className='sub-total d-flex align-items-center'>
                                    <p class="w-100">Subtotal Price<span>$26,25</span></p>
                                    </div>
                                    <p>TAX of $13 is included in the price</p>
                                  
                                <button className="pay float-end" onClick={continueNext}> Continue </button>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductDetailsModal;
