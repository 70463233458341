import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import search from '../../../assets/images/search.svg'
import profile from '../../../assets/images/chat-profile.svg'
import chtaprofile from '../../../assets/images/\chat-profile-2.svg'
import action from '../../../assets/images/three-dots.svg'


function CreatorChat() {
    const [classState, setClassState] = useState(false);

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };


    useEffect(() => {
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <div className="users meal-suggestion creator-chat">
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="RightSideDiv right-main">
                    <h2>
                        <button className="burger-menu" onClick={handleSidebarClick}>
                            <img src={burger} alt='burger menu' />
                        </button>
                        Message
                    </h2>
                    <div className='col-6 creators-margin'>
                                <div class="form-group">
                                    <div className="creators-input mt-1">
                                        <img  src={search} alt='' />
                                        <input type="text" className="search" placeholder="Search or start new conversation" />
                                    </div>
                                </div>
                            </div>
                    <div className='row'>
                        <div className='col-6'>
                     
                            <div class="list-group list-group-flush">
                                <a href="#" class="chat-group-item chat-group-item-action active">
                                    <div class="d-flex align-items-center">
                                        <span><img src={profile} alt='' class="rounded-circle me-3" /></span>
                                        <div className='w-100 my-2'>
                                            <h6 class="mb-0 pb-1">Marley Westervelt <span>Yoga Instructor</span></h6>
                                            <p class="">Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.
                                                Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.
                                                Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel facilisis volutpat est velitolm.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="list-group list-group-flush">
                                <a href="#" class="chat-group-item chat-group-item-action ">
                                    <div class="d-flex align-items-center">
                                        <span><img src={chtaprofile} alt='' class="rounded-circle me-3" /></span>
                                        <div className='w-100 my-2'>
                                            <h6 class="mb-0 pb-1">Marley Westervelt <span>Yoga Instructor</span></h6>
                                            <p class="">Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus vel
                                                facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus
                                                vel facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing elit semper dalaracc lacus
                                                vel facilisis volutpat est velitolm.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="list-group list-group-flush">
                                <a href="#" class="chat-group-item chat-group-item-action">
                                    <div class="d-flex align-items-center">
                                        <span><img src={chtaprofile} alt='' class="rounded-circle me-3" /></span>
                                        <div className='w-100 my-2'>
                                            <h6 class="mb-0 pb-1">Marley Westervelt <span>Yoga Instructor</span></h6>
                                            <p class="">Lorem ipsum dolor sit amet consecte tur adipiscing elit semper
                                                dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing elit semper
                                                dalaracc lacus vel facilisis volutpat est velitolm. Lorem ipsum dolor sit amet consecte tur adipiscing elit semper
                                                dalaracc lacus vel facilisis volutpat est velitolm.</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div class="sent-chat">
                            <div class="chat-header p-3 d-flex justify-content-between align-items-center position-relative">
                                <div class="d-flex align-items-center">
                                    <img src={chtaprofile} alt='' class="rounded-circle me-3" />
                                    <div>
                                        <h6 class="mb-0">Marley Westervelt</h6>
                                        <small class="text-muted">Yoga Instructor</small>
                                    </div>
                                </div>
                                <div class="dropdown-action  px-3 cursor-pointer">
                                <img src={action} alt='' class="" />

                                </div>
                                <div className='action-menu'>
                                    <ul className='p-0 m-0'>
                                        <li className='pt-0 pb-2'>Block user</li>
                                        <li>Delete chat</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="chat-body p-3">
                                {/* Chat messages will appear here */}
                            </div>

                            <div class="chat-footer p-3 ">
                                <form class="d-flex py-1">
                                    <input type="text" class="form-control border-0" placeholder="Write Your message..." />
                                    <button type="submit" class="btn  ml-2">Send</button>
                                </form>
                            </div> 
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CreatorChat;