import React from 'react'
import discoverIcon1 from '../../assets/images/discover-icon1.svg'
import discoverIcon2 from '../../assets/images/discover-icon2.svg'
import discoverIcon3 from '../../assets/images/discover-icon3.svg'
import discoverIcon4 from '../../assets/images/discover-icon4.svg'
import discoverIcon5 from '../../assets/images/discover-icon5.svg'

function UniqueOffering() {
  return (
    <>
      <ul>
        <li>
          <img src={discoverIcon1} />
          <h4>Wellness</h4>
        </li>
        <li>
          <img src={discoverIcon2} />
          <h4>Creativity</h4>
        </li>
        <li>
          <img src={discoverIcon3} />
          <h4>Personal Growth</h4>
        </li>
        <li>
          <img src={discoverIcon4} />
          <h4>Abundance</h4>
        </li>
        <li>
          <img src={discoverIcon5} />
          <h4>Parenting</h4>
        </li>
      </ul>
    </>
  )
}

export default UniqueOffering