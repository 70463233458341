import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import GoogleSignIn from '../Component/AuthComponent/googleSignIn';
import FacebookLoginButton from '../Component/AuthComponent/facebookSignIn';
import { postApi } from '../Services/apiService';
import { loginValidation } from '../Validations/formValidation';
import { LOGIN_URL } from '../Config/apiConfig';

const LoginPage = () => {
  const navigate = useNavigate();
  const initialValues = {
    name: '',
    email: ''
  };

  const onSubmit = async (values) => {

    const dataInsert = {
      email: values?.email,
      password: values?.password,
    }
    try {
      const response = await postApi(LOGIN_URL, dataInsert);
      // if(response){
      //   toast.success(response?.data?.message)
      // }
      localStorage.setItem('token', response.user_details.token);
      navigate('/');

      if (!response.ok) {
        throw new Error('Failed to schedule job');
      }

      const data = await response.json();
      localStorage.setItem('datas', JSON.stringify(data))
    } catch (error) {
      console.error('API Error: ', error.message);
    }
    navigate('/')
  };

  return (
    <div>
      <h2>Login</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={loginValidation}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <Field type="email" id="email" name="email" className="form-control" />
              <ErrorMessage name="email" component="div" className="error" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <Field type="password" id="password" name="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="error" />
            </div>
            <button type="submit" className="btn btn-primary">Login</button>
          </Form>
        )}
      </Formik>

      <GoogleSignIn />

      <FacebookLoginButton />
    </div>
  );
}

export default LoginPage;
