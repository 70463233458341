import React from 'react'
import checkArrow from '../../../assets/images/checkarrow.svg'

function ActiveLinks({ step, type,productType }) {
  // console.log("datadatadatadatadata", step)
  // console.log("type----", type)

  return (
    // <div class="active-links">
    <ul>
      {/* <li>
        <span> 1 </span>
        <h5>Define</h5>
      </li> */}
      <li className={step > 2 && 'active'|| step == 2 && 'firstactive'}>
        {step > 1 && <span className='check'><img src={checkArrow} alt='' /></span>}
        <span className='number'>1</span>
        <h5>{type === 'product' ? 'Define' : 'Define'}</h5>
      </li>
      {productType !== 'digital' &&
      <li className={step > 3 && 'active' || step == 3 && 'firstactive'}>
        {step > 2 && <span className='check'><img src={checkArrow} alt='' /></span>}
        <span className='number'> 2 </span>
        <h5>{type === 'product' ? 'Delivery and pick up' : 'Timing'}</h5>
      </li>}
      <li className={productType !== 'digital' ? step > 4 && 'active'|| step == 4 && 'firstactive' :step > 2 && 'active'}>
        {(productType !== 'digital' ? step > 3 && 'active' :step > 2) && <span className='check'><img src={checkArrow} alt='' /></span>}
        <span className='number'> {productType !== 'digital' ?3 :2} </span>
        <h5>{type === 'product' ? 'Publish' : 'Location'}</h5>
      </li>
      {type !== 'product' && (
        <>
          <li className={step > 5 && 'active'|| step == 5 && 'firstactive'}>
            {step > 4 && <span className='check'><img src={checkArrow} alt='' /></span>}
            <span className='number'> 4 </span>
            <h5>Customize</h5>
          </li>
          <li className={step > 6 && 'active'|| step == 6 && 'firstactive'}>
            {step > 5 && <span className='check'><img src={checkArrow} alt='' /></span>}
            <span className='number'> 5 </span>
            <h5>Publish</h5>
          </li>
        </>
      )}
    </ul>
    // </div>
  )
}

export default ActiveLinks