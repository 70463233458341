import React from 'react'
import checkCircle from '../../../assets/images/check-circle-2.svg'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { insertData } from '../../../Counter/signupSlice'
import Header from '../../CreatorComponents/CreatorJoin/header'

function CreatorSubmit({prevStep,submitForm,loader,admin,role}) {
  // const dispatch = useDispatch()
  // const onSubmit=async(values)=>{
  //   dispatch(insertData('end'));
  //   nextStep('end')
  // }
  return (
    <div className="detail-page congratulations signup-cs">
      <Header/>
        <div className="detail-main">
            <div className="congratulations">
                <span> <img src={checkCircle}/> </span>
                <h1>Congratulations!</h1>
                <p>
            {role === 'user'
              ? 'User, have successfully registered!'
              : `${admin === 'admin' ? 'Creator' : 'You'} have successfully registered!`}
            <br />
            We will be in touch with you shortly.
          </p>
              <h2>In the meantime, feel free to explore our platform and get acquainted with its features.</h2>
                {/* <Link to='/creator'> */}
                  <button className="button" onClick={()=>submitForm()} disabled={loader}>{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Go to {admin ==='admin'?'':'Creator'} Dashboard</button>
                  
                  {/* </Link> */}
                  <p className="bottom-p">*Please note: 
                  {role === 'user'
              ? ' User '
              : `${admin === 'admin' ? 'Creator ' : 'You '}`}
                  won't be able to publish an offer until
                  {role === 'user'
              ? ' they '
              : `${admin === 'admin' ? 'they ' : 'you '} .`}
              complete the verification process
                  </p>
                {/* <p className="bottom-p">*Please note: {admin ==='admin'?'Creator':'you'} won't be able to publish an offer until {admin ==='admin'?'they':'you'} complete the verification process.</p> */}
            </div>

        </div>
        <div className="back-button">
  <button className="button" onClick={()=>prevStep()}><img src={leftArrow}/> </button>
  </div>
    </div>
  )
}

export default CreatorSubmit