import React, { useState } from 'react'
// import cross from '../../../assets/images/cros.svg'
import cross from '../../../assets/images/cross-button.svg'

function PublishModal({ submitForm, handlePublish, closeModal, type }) {

  const [stateModal, setStateModal] = useState(false)
  const [draftLoader,setDraftLoader] = useState(false)
  const [publishLoader,setPublishLoader] = useState(false)


  const handleApi = async(types) =>{
    setStateModal(true)
    // if(type === 'product'){

    // } else{
      let resp;
    if(types === 'draft'){
      setDraftLoader(true)
       resp = await submitForm('draft')
       setDraftLoader(false)
    }
    else{
      setPublishLoader(true)
      resp = await submitForm('publish')
      setPublishLoader(false)
    } 
    if(resp === true){
      setStateModal(false)
      handlePublish('live')
    } else{
      setStateModal(false)
    }
  // }
  }
  return (
   
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
          <div className="ready-publish">
            <button className='close-button' onClick={() => closeModal('ready')}><img src={cross} alt='' /></button>
            <h3>Ready To Publish?</h3>
            <p>Once you publish your first product, your offer will go live. People will be able to see the offering page tab and purchase your products</p>
            <div className="bottom-button">
              <button className='draft' onClick={() => handleApi('draft')} disabled={stateModal && draftLoader}>{stateModal && draftLoader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save To Draft</button>
              <button className='publish' onClick={() => handleApi()} disabled={stateModal && publishLoader}>{stateModal && publishLoader&& <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Publish</button>
              {/* <button className='publish' onClick={() => handlePublish('live')}>Publish</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublishModal