import React, { useEffect, useState } from 'react';
import burger from '../../assets/images/burger-menu.svg';
import UserSidebar from '../../Layout/userSidebar';
import search from '../../assets/images/search.svg';
import circle from '../../assets/images/add-circle.svg';
import edit from '../../assets/images/edit.svg';
import del from '../../assets/images/delete.svg';
import { getApi, putApi, postApi, deleteApi } from '../../Services/apiService';
import Modal from 'react-modal';
import AdminBasics from './SettingComps/basics';
import AdminAccounts from './SettingComps/account';
import AdminEmailNotification from './SettingComps/emailNotification';
import AdminBillingPayout from './SettingComps/billingPayout';
import BasicsEmailNotification from '../CreatorComponents/CreatorSetting/emailNotification';

function Settings() {
    const [classState, setClassState] = useState(false);
    const [condition, setCondition] = useState('Basics');
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTab, setSelectedTab] = useState('Category');
    const [addCategoryModalIsOpen, setAddCategoryModalIsOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [newCategory, setNewCategory] = useState({ name: '', image: '' });
    const [imagePreview, setImagePreview] = useState('');
    const [categoryToDelete, setCategoryToDelete] = useState(null); // Store the ID of the category to delete
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [newTag, setNewTag] = useState({ name: '', description: '' }); // New state for tag data
    const [addTagModalIsOpen, setAddTagModalIsOpen] = useState(false); // New state for tag modal
    const [currentTag, setCurrentTag] = useState(null); // New state to keep track of the tag being edited
    const [entityToDelete, setEntityToDelete] = useState({ type: '', id: '' });
    const [searchQuery, setSearchQuery] = useState('');
    const [loader, setLoader] = useState(false)




    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [condition]);


    useEffect(() => {
        if (condition === 'Category/Popular tags') {
            if (selectedTab === 'Category') {
                fetchCategories();
            } else {
                fetchTags();
            }
        }
    }, [condition, selectedTab]);

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };

    const openModal = (category = null) => {
        if (category) {
            setCurrentCategory(category);
            setIsEditMode(true);
            setNewCategory({
                name: category.category_name || '',
                image: category.category_image || ''
            });
            setImagePreview(category.category_image || '');
        } else {
            setNewCategory({ name: '', image: '' });
            setImagePreview('');
            setIsEditMode(false);
        }
        setAddCategoryModalIsOpen(true);
    };

    const closeModal = () => {
        setAddCategoryModalIsOpen(false);
        setSuccessMessage('');
        setNewCategory({ name: '', image: '' });
        setImagePreview('');
        setIsEditMode(false);
    };

    const openDeleteModal = (entityType, entityId) => {
        setEntityToDelete({ type: entityType, id: entityId });
        setDeleteModalIsOpen(true);
    };


    const closeDeleteModal = () => {
        setDeleteModalIsOpen(false);
        setCategoryToDelete(null);
    };

    const handleDelete = async () => {
        setLoader(true)
        try {
            const { type, id } = entityToDelete;

            let response;
            if (type === 'category') {
                response = await deleteApi(`/admin/Category?categoryId=${id}`);
            } else if (type === 'tag') {
                response = await deleteApi(`/admin/Tag?tagId=${id}`);
            }

            if (response?.statusCode === 200) {
                setSuccessMessage(`${type.charAt(0).toUpperCase() + type.slice(1)} deleted successfully`);
                if (type === 'category') {
                    setLoader(false)
                    fetchCategories();
                } else {
                    setLoader(false)
                    fetchTags();
                }
                closeDeleteModal();
            } else {
                setLoader(false)
                console.error(`Failed to delete ${type}:`, response?.message || 'Unknown error');
            }
        } catch (error) {
            console.error('Error deleting:', error);
        }
    };
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (condition === 'Category/Popular tags') {
            if (selectedTab === 'Category') {
                fetchCategories(searchQuery);
            } else {
                fetchTags(searchQuery);
            }
        }
    }, [condition, selectedTab, searchQuery]);

    const fetchCategories = async (query = '') => {
        const response = await getApi(`/admin/Category?search=${query}`);
        if (response?.statusCode === 200) {
            setCategories(response?.data);
        } else {
            console.error('Error fetching categories:', response?.message || 'Unknown error');
        }
    };

    const fetchTags = async (query = '') => {
        const response = await getApi(`/admin/Tag?search=${query}`);
        if (response?.statusCode === 200) {
            setTags(response?.data);
        } else {
            console.error('Error fetching tags:', response?.message || 'Unknown error');
        }
    };

    const handleTagChange = (e) => {
        const { name, value } = e.target;
        setNewTag(prevState => ({ ...prevState, [name]: value }));
    };
    const openTagModal = (tag = null) => {
        if (tag) {
            setCurrentTag(tag);
            setIsEditMode(true);
            setNewTag({
                name: tag.tag_name || '',
                description: tag.tag_description || ''
            });
        } else {
            setNewTag({ name: '', description: '' });
            setCurrentTag(null); // Reset currentTag to null
            setIsEditMode(false);
        }
        setAddTagModalIsOpen(true);
    };


    const closeTagModal = () => {
        setAddTagModalIsOpen(false);
        setSuccessMessage('');
        setNewTag({ name: '', description: '' });
        setIsEditMode(false);
    };

    const handleTagSubmit = async (e) => {
        setLoader(true)
        e.preventDefault();
        const tagData = {
            tag_name: newTag.name,
            tag_description: newTag.description
        };

        let response;
        if (isEditMode && currentTag) {
            // Editing mode: include the tag ID in the URL
            response = await putApi(`/admin/Tag`, { ...tagData, tag_id: currentTag.id });
        } else {
            // Adding new tag: no tag ID needed
            response = await postApi('/admin/Tag', tagData);
        }

        if (response?.statusCode === 200) {
            setLoader(false)
            setSuccessMessage(`Tag ${isEditMode ? 'updated' : 'added'} successfully`);
            fetchTags();
            closeTagModal();
        } else {
            setLoader(false)
            console.error('Failed to save tag:', response?.message || 'Unknown error');
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCategory(prevState => ({ ...prevState, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewCategory(prevState => ({ ...prevState, image: file }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault();
        const formData = new FormData();
        formData.append('category_name', newCategory.name);
        formData.append('category_image', newCategory.image);
        if (isEditMode && currentCategory) {
            formData.append('categoryId', currentCategory.id);
        }
        let response;
        if (isEditMode && currentCategory) {
            // Editing mode: include the category ID in the URL
            response = await putApi(`/admin/Category`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } else {
            // Adding new category: no category ID needed
            response = await postApi('/admin/Category', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        }

        if (response?.statusCode === 200) {
            setLoader(false)
            setSuccessMessage(`Category ${isEditMode ? 'updated' : 'added'} successfully`);
            fetchCategories();
            closeModal();
        } else {
            // Handle error if needed
        }
    };


    return (
        <div className="users meal-suggestion">
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="RightSideDiv">
                    <div className="members-top">
                        <h2>
                            <button className="burger-menu" onClick={handleSidebarClick}>
                                <img src={burger} alt="burger menu" />
                            </button>
                            Settings
                        </h2>
                        {condition === 'Category/Popular tags' && (
                            <div className="category-tab">
                                <ul>
                                    <li>
                                        <a className={selectedTab === 'Category' ? 'active' : ''} onClick={() => setSelectedTab('Category')}>Category</a>
                                    </li>
                                    <li>
                                        <a className={selectedTab === 'Tags' ? 'active' : ''} onClick={() => setSelectedTab('Tags')}>Tags</a>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="setting-links">
                        <ul>
                            <li onClick={() => setCondition('Basics')}>
                                <a className={condition === 'Basics' ? 'active' : ''}>Basics</a>
                            </li>
                            <li onClick={() => setCondition('Account')}>
                                <a className={condition === 'Account' ? 'active' : ''}>Account</a>
                            </li>
                            <li onClick={() => setCondition('Email notification')}>
                                <a className={condition === 'Email notification' ? 'active' : ''}>Email notification</a>
                            </li>
                            <li onClick={() => setCondition('Billing and payouts')}>
                                <a className={condition === 'Billing and payouts' ? 'active' : ''}>Billing and payouts</a>
                            </li>
                            <li onClick={() => setCondition('Category/Popular tags')}>
                                <a className={condition === 'Category/Popular tags' ? 'active' : ''}>Category/Popular tags</a>
                            </li>
                        </ul>
                    </div>
                    {condition === 'Basics' && (
                       <AdminBasics></AdminBasics>
                    )}
                    {condition === 'Account' && (
                        <AdminAccounts></AdminAccounts>
                    )}
                    {condition === 'Email notification' && (
                    //   <AdminEmailNotification></AdminEmailNotification>
                    <BasicsEmailNotification/>
                    )}
                    {condition === 'Billing and payouts' && (
                     <AdminBillingPayout></AdminBillingPayout>
                    )}
                    {condition === 'Category/Popular tags' && (
                        <div className="popular-tag">
                            <div className="popular-search">
                                <button>
                                    <img src={search} alt="search" />
                                </button>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>

                            <button className="add-new" onClick={() => {
                                if (selectedTab === 'Category') {
                                    openModal(); // Opens category modal
                                } else if (selectedTab === 'Tags') {
                                    openTagModal(); // Opens tag modal
                                }
                            }}>
                                <img src={circle} alt="add" /> Add {selectedTab}
                            </button>

                            {/* <button className="add-new" onClick={() => openModal()}><img src={circle} alt="add new" /> Add new Category</button> */}
                            <div className='tables-cs'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='id'>#</th>
                                        <th scope="col" className='tag-name'>{selectedTab === 'Category' ? 'Category Image' : 'Tag Name'}</th>
                                        <th scope="col" className='categ-name'>{selectedTab === 'Category' ? 'Category Name' : 'Description'}</th>
                                        <th scope="col" className='action'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedTab === 'Category' ? (
                                        categories.length > 0 ? (
                                            categories.map((category, index) => (
                                                <tr key={category.id || index}>
                                                    <td className='id'>{index + 1}</td>
                                                    <td className='tag-name'>
                                                        <span><img src={category.category_image || circle} alt="category" /></span>
                                                    </td>
                                                    <td className='categ-name'>{category?.category_name || '-'}</td>
                                                    <td className='action'>
                                                        <button onClick={() => openModal(category)}>
                                                            <img src={edit} alt="edit" />
                                                        </button>
                                                        <button onClick={() => openDeleteModal('category', category.id)}>
                                                            <img src={del} alt="delete" />
                                                        </button>

                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No categories available</td>
                                            </tr>
                                        )
                                    ) : (
                                        tags.length > 0 ? (
                                            tags.map((tag, index) => (
                                                <tr key={tag.id || index}>
                                                    <td>{index + 1}</td>
                                                    <td><span>{tag?.tag_name || '-'}</span></td>
                                                    <td> {tag?.tag_description || '-'} </td>

                                                    <td>
                                                        <button onClick={() => openTagModal(tag)}>
                                                            <img src={edit} alt="edit" />
                                                        </button>
                                                        <button onClick={() => openDeleteModal('tag', tag.id)}>
                                                            <img src={del} alt="delete" />
                                                        </button>

                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">No tags available</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                               
                            </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Modal isOpen={addCategoryModalIsOpen} onRequestClose={closeModal} overlayClassName={`modal-overlay add-category`}>
                <h2>{isEditMode ? 'Edit Category' : 'Add New Category'}</h2>
                <form onSubmit={handleSubmit}>

                    <div className="setting-img">
                        <div className="setting-profile">
                            {imagePreview && <img src={imagePreview} alt="preview" style={{ width: '100px', height: '100px' }} />}

                        </div>
                        <div className="upload-btn-wrapper">
                            <button className="btn">Upload new photo</button>
                            <input type="file" accept="image/*" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Category Name</label>
                        <input type="text" name="name" id="name" placeholder="Enter category name" value={newCategory.name} onChange={handleChange} required className="form-control" />
                    </div>

                    {/* <label>
                                            Category Name
                                            <input type="text" name="name" value={newCategory.name} onChange={handleChange} required />
                                        </label> */}
                    {/* <label>
                                            Image
                                            <input type="file" accept="image/*" onChange={handleFileChange} />
                                            {imagePreview && <img src={imagePreview} alt="preview" style={{ width: '100px', height: '100px' }} />}
                                        </label> */}
                    <div className="modal-buttons">
                        <button type="button" onClick={closeModal} className="cancel-btn">Cancel</button>
                        <button type="submit" className="save-btn" disabled={loader}>
                            {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}{isEditMode ? 'Save & Update' : 'Add'}</button>
                    </div>

                </form>
                {successMessage && <p>{successMessage}</p>}
            </Modal>


            {/* Add/Edit Tag Modal */}
            <Modal isOpen={addTagModalIsOpen} onRequestClose={closeTagModal} overlayClassName={`modal-overlay add-category`} contentLabel="Add/Edit Tag">
                <div className="modal-header">
                    <h2>{isEditMode ? 'Edit Tag' : 'Add Tag'}</h2>
                </div>
                <form onSubmit={handleTagSubmit} className="modal-form">
                    <div className="form-group">
                        <label htmlFor="tagName">Tag Name</label>
                        <input
                            type="text"
                            id="tagName"
                            placeholder='Enter tag name'
                            name="name"
                            value={newTag.name}
                            onChange={handleTagChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tagDescription">Tag Description</label>
                        <input
                            type="text"
                            id="tagDescription"
                            placeholder='Enter tag description'
                            name="description"
                            value={newTag.description}
                            onChange={handleTagChange}
                            required
                            className="form-control"
                        />
                    </div>
                    <div className="modal-buttons">
                        <button type="button" onClick={closeTagModal} className="cancel-btn">Cancel</button>
                        <button type="submit" className="save-btn" disabled={loader}>
                            {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}
                            {isEditMode ? 'Update Tag' : 'Add Tag'}
                        </button>
                    </div>
                    {successMessage && <p className="success-message">{successMessage}</p>}
                </form>
            </Modal>
            {/* Delete Confirmation Modal */}
            <Modal
                isOpen={deleteModalIsOpen}
                onRequestClose={closeDeleteModal}
                ariaHideApp={false}
                contentLabel="Confirm Delete"
                shouldCloseOnOverlayClick={false}
                overlayClassName={`modal-overlay add-category delete`}
            >
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this {entityToDelete.type}?</p>
                <div className="modal-buttons">
                    <button type="button" onClick={closeDeleteModal} className="cancel-btn">Cancel</button>
                    <button type="button" onClick={handleDelete} className="save-btn" disabled={loader}>
                        {loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Delete</button>
                </div>
            </Modal>

        </div>
    );
}

export default Settings;
