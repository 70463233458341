import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import { useDispatch } from 'react-redux'
import leftArrow from '../../../assets/images/backarrow.svg'
import burger from '../../../assets/images/burger-menu.svg'
import UserSidebar from '../../../Layout/userSidebar'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'

function SessionService({ nextStep, prevStep, step, data }) {

    const dispatch = useDispatch();
    const [classMode, setClassMode] = useState(data?.classMode || '')
    const [timingZone, setTimingZone] = useState(data?.timingZone || '')
    const [invitationLink, setInvitationLink] = useState(data?.invitationLink || '')
    const [classState, setClassState] = useState(false)
    const [errors, setErrors] = useState({});

    console.log("classMode", classMode);
    console.log("timingZone", timingZone);
    console.log("invitationLink", invitationLink);

    const validateForm = () => {
        const newErrors = {};

        if (!classMode) {
            newErrors.classMode = 'Class mode is required';
        }

        if (!timingZone) {
            newErrors.timingZone = 'Timing zone is required';
        }

        if (!invitationLink) {
            newErrors.invitationLink = 'Invitation link is required';
        } else if (!/^https?:\/\/\S+$/.test(invitationLink)) {
            newErrors.invitationLink = 'Invalid link format';
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSave = () => {

        const values = {
            classMode: classMode,
            timingZone: timingZone,
            invitationLink: invitationLink,
        }
        if (validateForm()) {
            dispatch(insertOfferingData(values))
            nextStep();
        }
    }

    const handleSetMode = (type) => {
        // setClassMode(type)
        const values = {
            classMode: type,
        }
        dispatch(insertOfferingData(values))
        nextStep('types', type);
    }
    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [])
    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };


    return (
        <div>
            <UserSidebar classState={classState} />
                <div className='right-main'>
            <div class="session step-2 where">
                    <div class="header">
                        <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Session or Service</h1>
                        <div class="top-buttons">
                            <button class="save-draft">Save to Draft</button>
                            <button class="next" onClick={handleSave}>Next</button>
                        </div>
                    </div>
                    {/* <!-- active links --> */}
                    <div class="active-links">

                        <button className="back" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>

                        <ActiveLinks step={step} />
                    </div>





                    {/* <!-- Main --> */}
                    <div class="main">
                        <div class="row">
                            <div class="col-sm-7">
                                <div class="left">
                                    <h1>Where</h1>


                                    <div class="size-range bottom">
                                        <h6>How class will happen?</h6><img src="images/green-tick.svg" class="green-tick" alt='' />
                                        <div class="bottom-buttons">
                                            <label class="radio-check3">
                                                <input type="radio" checked={classMode === 'online'} name="radio" onClick={() => { setClassMode('online'); handleSetMode('online') }} />
                                                <span class="checkmark">Online</span>
                                            </label>
                                            <label class="radio-check3">
                                                <input type="radio" checked={classMode === 'in_person'} name="radio" onClick={() => { setClassMode('in_person'); handleSetMode('in_person') }} />
                                                <span class="checkmark">In Person</span>
                                            </label>
                                            <label class="radio-check3">
                                                <input type="radio" checked={classMode === 'hybrid'} name="radio" onClick={() => { setClassMode('hybrid'); handleSetMode('hybrid') }} />
                                                <span class="checkmark">Hybrid</span>
                                            </label>
                                        </div>
                                        {errors.classMode && <div className="error">{errors.classMode}</div>}
                                        <div class="of-offering">
                                            <h6>Timing Zone</h6>
                                            <form action="">
                                                <select name="cars" id="cars" value={timingZone} onChange={(e) => setTimingZone(e.target.value)}>
                                                    <option value="class">Class</option>
                                                    <option value="Workshop">Workshop</option>
                                                    <option value="Photoshoot">Photoshoot</option>
                                                    <option value="Coaching">Coaching</option>
                                                </select>
                                                {errors.timingZone && <div className="error">{errors.timingZone}</div>}
                                            </form>
                                        </div>
                                        <div class="invitation-link">
                                            <label htmlFor="invitation-link">Invitation link </label>
                                            <input type="text" id="invitation-link" placeholder="Enter link where class suppose to be" value={invitationLink} onChange={(e) => setInvitationLink(e.target?.value)} />
                                            {errors.invitationLink && <div className="error">{errors.invitationLink}</div>}


                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            <OfferingDetails data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionService