import axios from 'axios';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
    baseURL: 'https://microcosmstage.enactstage.com/microcosmapi/api/v1', //live server
    // baseURL: 'http://44.217.172.72:8002/api/v1', //development server
    timeout: 30000,
});
// const axiosInstance = axios.create({
//     baseURL: 'http://localhost:3001/api/v1',   //http://13.232.134.145:8002/api/v1
//     timeout: 30000,
// });

axiosInstance.interceptors.request.use(
    function (config) {
        const token = JSON.parse(localStorage.getItem('token'));
        if (token) {
            // config.headers['Authorization'] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImRiYmI1MzE1LTk4ZjAtNDc5Yi04NTJhLWNiOWFiOGViMDA2YyIsImlhdCI6MTcyNjczMzMyOSwiZXhwIjoxNzI5MzI1MzI5fQ.qMuc1_7mrbz0AkbTNTxmNLLPFkD1UXrRfcqlS6NfiI4`;
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            // toast.error(error?.response?.data?.message)
        }
        // else{
        //     toast.error(error?.response?.data?.message)
        // }
        return error?.response;
        // return Promise.reject(error);
    }
);

export default axiosInstance;
