import React, { useEffect, useState } from 'react'
import search from '../../../assets/images/search.svg'
import { getApi, postApi } from '../../../Services/apiService'
import { toast } from 'react-toastify'

function CreatorTags({ closeModal }) {
  const [tagData, setTagData] = useState([])
  const [tagName, setTagName] = useState('')
  const [interestName, setInterestName] = useState('')
  const [creatorTag, setCreatorTag] = useState([])
  const [popularTags, setPopularTags] = useState([])
  const [popularInterest, setPopularInterest] = useState([])
  const [filterValue, setFilterValue] = useState([])
  const [loader, setLoader] = useState(false)
  const [loadNumber, setLoadNumber] = useState(10)
  
  // const popularTags = ['Drawing', 'Painting', 'Photography', 'Music', 'Handcrafts', 'Arts', 'DIY Projects', 'Reading', 'Writing', 'Knitting', 'Fitness', 'Running']
  // const popularInterest = ['Fitness', 'Language Learning', 'Handmade', 'Health and Fitness', 'Cooking', 'Reading', 'Gardening', 'Reading', 'Gaming', 'Outdoor Activities', 'Fashion']

  // creatorTa
  useEffect(() => {
    fetchTagData()
  }, [])

  const fetchTagData = async () => {
    const response = await getApi('/creator/tag')
    if (response?.statusCode === 200) {
      setCreatorTag(response?.data?.creatorData)
      setPopularTags(response?.data?.tagData)
      setPopularInterest(response?.data?.popularData)
    }

  }
  const addTagData = async (val) => {
    let data;
    if (val === 'save') {
      data = {
        tagName: creatorTag
      }
    }
    else {
      setLoader(true)
      if (tagName === '') {
        setLoader(false)
        return toast.error('enter the value')
      }
      else {
        data = {
          tagName: [tagName]
        }
      }
    }
    setLoader(true)
    const response = await postApi('/creator/tag', data);
    if (response?.statusCode === 200) {
      fetchTagData()
      const response = await getApi('/creator/dashboard');
      if (response?.statusCode === 200) {
        localStorage.setItem('full_user_detail', JSON.stringify(response?.data))
        localStorage.setItem('user_role', response?.data?.roles[0]?.user_role)
        localStorage.setItem('user_social_links', JSON.stringify(response?.data?.social_links))
        // setUserData(response?.data);
      }
      closeModal()
      // else {
      //   localStorage.clear();
      //   navigate('/')
      // }
    }
    setLoader(false)
    fetchTagData()
  }

  const handleTagData = (newTag, type) => {
    if (type === 'add') {
      newTag = tagName
      setTagName('')
    }
    if(newTag !=='' && type === 'add' && newTag.trim().length !== 0){
    setCreatorTag((prevTagData) => {
      if (prevTagData.includes(newTag)) {
        return prevTagData.filter(tag => tag !== newTag);
      } else {
        return [...prevTagData, newTag];
      }
    });
  }
    if(type !== 'add'){
    setCreatorTag((prevTagData) => {
      if (prevTagData.includes(newTag)) {
        return prevTagData.filter(tag => tag !== newTag);
      } else {
        return [...prevTagData, newTag];
      }
    });
  }
  };
  const handleInterestData = (newTag) => {
    setInterestName((prevTagData) => {
      if (prevTagData.includes(newTag)) {
        return prevTagData.filter(tag => tag !== newTag);
      } else {
        return [...prevTagData, newTag];
      }
    });
  };

  useEffect(() => {
    // const intervalId = setInterval(() => {
      filterTagData(filterValue);
    // }, 500);

    // return () => clearInterval(intervalId);
  }, [filterValue]);

  const filterTagData = async (value) => {
    // setLoader(true)
    const response = await getApi(`/creator/tag?tagName=${value}`)
    if (response?.statusCode === 200) {
      // setLoader(false)
      // setCreatorTag(response?.data?.creatorData)
      setPopularTags(response?.data?.tagData)
      setPopularInterest(response?.data?.popularData)
    }
  }

  const handleShowData = () => {

    setLoadNumber(loadNumber + 10)
  }

  return (
    <div className="interest">
      <div className="header">
        <h1>Add New Interest</h1>
        <div className="top-buttons me-0">
          <button onClick={() => closeModal()} className="cancel">Cancel</button>
          <button onClick={() => addTagData('save')} className="save">{loader && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Save</button>
        </div>
      </div>
      <div className='modal-scroll'>
      <section className="added-tags">
        <h6>My Added Tags</h6>
        <ul>
          {creatorTag?.map((x, index) => (
            <li key={index}>
              <label className="radio-check3">
                <input
                  type="checkbox"
                  // checked={interestName.includes(x?.tag?.tag_name)}
                  checked={creatorTag.includes(x)}
                  onChange={() => handleInterestData(x)}
                  name="radio"
                />
                <span className="checkmark">{x}</span>
              </label>
            </li>
          ))}
        </ul>
      </section>
    
      <section className="added-tags popular-interest">
        <h6>Popular Interests</h6>
        <ul>
          {popularInterest?.map((x, index) => (
            <li key={index}>
              <label className="radio-check3">
                <input
                  type="checkbox"
                  // checked={interestName.includes(x)}
                  // onChange={() => handleInterestData(x?.tag_name)}
                  checked={creatorTag.includes(x?.tag_name)}
                  onChange={() => handleTagData(x?.tag_name, '')}
                  name="radio"
                />
                <span className="checkmark">{x?.tag_name}</span>
              </label>
            </li>
          ))}
        </ul>
      </section>
      <section className="more-tags">
        <h6>More Tags</h6>
        <div className="input-section">
          <input type="text" id="input" placeholder="Find Tag" onChange={(e) => setFilterValue(e.target.value)} />
          <img src={search} alt='' className="input-img" />
        </div>
      </section>
      <section className="added-tags all-tags">
        <h6>All Tags</h6>
        <ul>
          {popularTags?.map((x, index) => (
            index < loadNumber &&
            <li key={index} onClick={() => handleTagData(x?.tag_name, '')}>

              <label className="radio-check3">
                <input
                  type="checkbox"
                  checked={creatorTag.includes(x?.tag_name)}
                  onChange={() => handleTagData(x?.tag_name, '')}
                  name="radio"
                />
                <span className="checkmark">{x?.tag_name}</span>
              </label>
            </li>
          ))}
     
        </ul>
        <div className="load-more">

{loadNumber < popularTags.length && <button className='button' onClick={handleShowData}> Load more tags</button>}

</div>
      </section>
      <section className="personal-tag">
        <label htmlFor="personal-tag">Add Your Personal Tag</label>
        <input type="text" id="personal-tag" placeholder="skiing" value={tagName} onChange={(e) => setTagName(e?.target?.value)} />
        <button onClick={() => handleTagData('', 'add')} className="add" disabled={loader}>{loader === true && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Add</button>
        {/* <button onClick={addTagData} className="add" disabled={loader}>{loader === true && <div class="spinner-border" role="status"><span class="sr-only"></span></div>}Add</button> */}
      </section>
      </div>
    </div>
  )
}

export default CreatorTags