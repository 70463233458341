import React, { useEffect, useState } from 'react'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import share from '../../../assets/images/share.svg'
import heart from '../../../assets/images/heart2.svg'
import clock from '../../../assets/images/clock.svg'
import calendar from '../../../assets/images/calendar.svg'
import people from '../../../assets/images/people.svg'
import mapPin from '../../../assets/images/map-pin.svg'
import productImg from '../../../assets/images/product-img.png'
import wellness from '../../../assets/images/wellness.svg'
import arrow from '../../../assets/images/arrow.svg'
import Slider from 'react-slick';
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import { useParams } from 'react-router-dom'
import { getApi } from '../../../Services/apiService'
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import { useNavigate } from 'react-router-dom'
import location from '../../../assets/images/map-pin1.svg'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import discoveryCall from '../../../assets/images/discovery-call.svg'
import checkTravel from '../../../assets/images/check-travel.svg'
import session from '../../../assets/images/session-calender.svg'
import hearts from '../../../assets/images/heart.svg'
import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg'



function CreatorSingleOfferingData() {

    const [classState, setClassState] = useState(false)
    const [userDetails, setUserDetails] = useState([])
    const [similarClass, setSimilarClass] = useState([])
    const [isModal, setIsModal] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [filtersModal, setFiltersModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [showHideComp, setshowHideComp] = useState(false);

    const userROle = localStorage.getItem('user_role')


    const openModal = (src) => {
        setModalContent(src);
        setModalIsOpen(true);
    };

    const closeImgModal = () => {
        setModalIsOpen(false);
        setModalContent('');
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleText = () => {
        setIsExpanded(!isExpanded);
    };

    // Ensure description is defined and is a string
    const description = userDetails?.description || '';
    const isLongDescription = description.length > 300;
    const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;

    const params = useParams();
    console.log("params>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", params?.id)
    console.log("userdetailsssss>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", userDetails)
    console.log("other classes>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", userDetails.otherClassess)


    useEffect(() => {
        const currentUrl = window.location.href;

        // Check if the URL contains 'creator/offering'
        if (currentUrl.includes('creator/offering')) {
            setshowHideComp(true)
        }
        fetchDetails();
        similarClasses();
    }, [])

    const navigate = useNavigate();
    const singleService = () => {
        if (userROle === 'creator') {
            navigate(`/creator/offering/home`)
        }
        if (userROle === 'user') {
            navigate(`/user/offering/`)
        }
    }
    const fetchDetails = async () => {
        const response = await getApi(`/creator/Service?offerId=${params?.id}`)
        if (response?.statusCode === 200) {
            setUserDetails(response?.data)
        }
    }

    const similarClasses = async () => {
        const response = await getApi(`/creator/similar-class`)
        if (response?.statusCode === 200) {
            setSimilarClass(response?.data)
        }
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

  

    const closeModal = () => {
        setIsModal(false)
    }

    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [])

    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };


    return (
        <div class="creators-detail offering-user-side">
            <UserSidebar classState={classState} />
            <div className='discover-offering right-main min-height-pera'>
                <section class="creators-top">
                    <h2 onClick={() => singleService()}><span><img src={arrowBtn} /> {userDetails?.title}</span> </h2>

                    <div class="row">
                        <div class="col-xl-8 col-sm-7">
                            <div class="offering-left pt-4">
                                {/* <h3><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>{userDetails?.offer_name}</h3> */}
                                {userDetails?.tags && userDetails?.tags?.length > 0 && userDetails?.tags?.map((x, index) => {
                                    return (
                                        <label key={index}>{x?.tag_name}</label>
                                    )
                                })}
                                {/* <label>Yoga</label>
                                    <label>Fitness</label>
                                    <label>Meditation</label> */}

                                <div class="annete">
                                    <span><img src={userDetails?.userData?.user_profile_image || dummyProfile} alt='' /></span>
                                    <div class="annete-text">
                                        <h3>{userDetails?.userData && userDetails?.userData?.name}</h3>
                                        {userDetails?.userData?.specializations.length ? (
                                            <>
                                                <h4>
                                                    {userDetails?.userData?.specializations[0]?.area_of_work}
                                                </h4>
                                                <h5>
                                                    {userDetails?.userData?.specializations[0]?.experience} of experience
                                                </h5>
                                            </>) :
                                            <>
                                            </>
                                        }
                                        <button class="discovery">Discovery call <img src={discoveryCall} /></button>
                                    </div>
                                </div>
                                <div className="about-left">
                                    <h3>About Class</h3>
                                    <p>
                                        {isExpanded || !isLongDescription ? description : truncatedDescription}
                                        <br />
                                        {isLongDescription && (
                                            <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                                                {isExpanded ? 'Read less' : 'Read more'}
                                            </strong>
                                        )}
                                    </p>
                                </div>

                            </div>
                        </div>

                        <div class="col-xl-4 col-sm-5">
                            <div class="offering-top-right">
                                <h2>{userDetails?.offer_name || 'Morning Yoga'}</h2>
                                <div class="offering-share">
                                    <ul>
                                        <li><a href=""><img src={share} alt='' /></a></li>
                                        <li><a href=""><img src={heart} alt='' /></a></li>
                                    </ul>
                                </div>
                                <div class="week">
                                    <ul>
                                        <li><img src={clock} alt='' /> {userDetails?.session_duration} min</li>
                                        {(userDetails?.session_type === '1' && userDetails?.session_offer_type == 1) && (
                                            <li><img src={session} alt='' /> 1 session</li>
                                        )}
                                        {userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 && (
                                            <li><img src={calendar} alt='' /> {userDetails?.no_of_sessions}x per {userDetails?.package_period}, {userDetails?.package_timeframe} {userDetails?.package_period}</li>
                                        )
                                        }
                                    </ul>
                                </div>
                                <div class="week">
                                    <ul>
                                        <li><img src={people} alt='' /> {userDetails?.session_type === '1' && userDetails?.session_offer_type == 1 ? (
                                            <p>Private Session</p>
                                        ) : userDetails?.session_type === '1' && userDetails?.session_offer_type == 2 ? (
                                            <p>Private Multiple sessions</p>
                                        ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 1 ? (
                                            <p>{userDetails?.booked || '-'} booked, {userDetails?.max_group_size} max </p>
                                        ) : userDetails?.session_type === '2' && userDetails?.session_offer_type == 2 ? (
                                            <p>{userDetails?.booked || '-'} booked, {userDetails?.max_group_size} max </p>
                                        ) : null}</li>

                                        <li><img src={mapPin} alt='' /> Broome St, New York</li>
                                        <li><img src={checkTravel} alt='' /> Creator can travel</li>
                                    </ul>
                                </div>

                                <div class="offering-right-bottom">
                                <h4 className='pb-0 mb-0'>Price <strong>${userDetails?.price || userDetails?.minimum_price || userDetails?.maximum_price}</strong></h4>
                                    {!showHideComp && (
                                        <button onClick={() => setIsModal(true)}>Select time slots</button>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* {userDetails.offerMaterials?.length ? (
                            <> */}
                                <div className="col-sm-8 mt-4 publish-after-offer">
                                
                                        <div class="row">
                                            <div className='col-sm-10 col-xl-10 col-lg-10'>
                                            <div className="main-image offiering-img-cs">
                                            {userDetails.cover_image && userDetails.cover_image !=='' && userDetails.cover_image !==null ? (
                                                <>
                                                    {userDetails?.cover_image.endsWith('.png') ||
                                                        userDetails.cover_image.endsWith('.jpg') ||
                                                        userDetails.cover_image.endsWith('.jpeg') ||
                                                        userDetails.cover_image.endsWith('.svg') ? (
                                                        <img src={userDetails?.cover_image} alt="Main"
                                                            onClick={() => openModal(userDetails.cover_image)} />
                                                    ) : userDetails.cover_image.endsWith('.mp4') ? (
                                                        <video controls onClick={() => openModal(userDetails.cover_image)}>
                                                            <source src={userDetails.cover_image} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    ) : (
                                                        <p>No valid media found for the main gallery.</p>
                                                    )}
                                                </>
                                            ):
                                            <img src={''} alt="No Image found"/>
                                            }
                                        </div>
                                        </div>
                                        {userDetails.offerMaterials?.length>0 && (
                                            <>
                                            <div className='col-sm-2 col-xl-2 col-lg-2'>  {/*serviceMaterial */}
                                            <div className= {userDetails?.offerMaterials.length > 4 ? 'gallery-small-main offiering-multiple' : 'gallery-small-main offiering-multiple image'}>
                                            {userDetails.offerMaterials && Array.isArray(userDetails.offerMaterials) && userDetails.offerMaterials.map((material, index) => (
                                                typeof material?.file_url === 'string' ? (
                                                    material.file_url.endsWith('.png') ||
                                                        material.file_url.endsWith('.jpg') ||
                                                        material.file_url.endsWith('.jpeg') ||
                                                        material.file_url.endsWith('.svg') ? (
                                                        <div className="gallery-small-one" key={index} onClick={() => openModal(material?.file_url)}>
                                                            <img src={material?.file_url} alt={`Gallery ${index + 1}`} />
                                                        </div>
                                                    ) : material.file_url.endsWith('.mp4') ? (
                                                        <div className="gallery-small-one" key={index} onClick={() => openModal(material?.file_url)}>
                                                            <video controls>
                                                                <source src={material?.file_url} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>
                                                    ) : null
                                                ) : null
                                            ))}
                                        </div>
                                            </div>
                                            </>
                                        )}
                                        </div>
                                 
                                      
                                    </div>
                              
                             {/* </>

                     ) :
                         <></>
                     } */}





                    </div>

                </section>

                {userDetails?.id && userDetails?.otherClassess?.length > 0 && (
                <section class="other-class-main">

                    <h3 className='pt-3'>Other classes by {userDetails?.userData?.name}</h3>
                    <button class="see-all">See all offers<img src={arrow} alt='' /></button>
                    <Slider {...settings}>
                        {userDetails?.id && (
                            userDetails?.otherClassess.map((x, index) => (
                                index<12 &&
                                <div className="col-sm-4 p-2" onClick={() => singleService(x?.id, x)} key={index}>
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={x?.cover_image} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                <h5>Offline</h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{x?.name} </h3>
                                            <ul>
                                                {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                                                    <li className={i === 0 ? "active" : ''} key={i}>
                                                        {y?.tag_name}
                                                    </li>
                                                ))}
                                            </ul>
                                            <p>{x?.description}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={x?.user?.user_profile_image && x?.user?. user_profile_image || bannerRight}  alt='' /></span>
                                                <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Slider>


                </section>
                )}

{userDetails?.id && similarClass.length > 0  && (
                <section class="other-class-main">

                    <h3>Similar classes</h3>
                    <button class="see-all">See all offers<img src={arrow} /></button>

                    <Slider {...settings}>
                    {userDetails?.id && (
                            similarClass?.map((x, index) => (
                                index<12 &&
                                <div className="col-sm-4 p-2" onClick={() => singleService(x?.id, x)} key={index}>
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={x?.cover_image} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={x?.category && x?.category?.category_image} alt='' /></span>
                                                <h5>Offline</h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{x?.name} </h3>
                                            <ul>
                                                {x?.tags && x?.tags.length > 0 && x?.tags.map((y, i) => (
                                                    <li className={i === 0 ? "active" : ''} key={i}>
                                                        {y?.tag_name}
                                                    </li>
                                                ))}
                                            </ul>
                                            <p>{x?.description}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={x?.user?.user_profile_image && x?.user?. user_profile_image || bannerRight}  alt='' /></span>
                                                <h6><strong>{x?.user && x?.user?.name}</strong> {x?.user?.specializations && x?.user?.specializations[0]?.area_of_work}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Slider>

                </section>
)}

<Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}> <img src={cross} alt='' /></button>

                    {modalContent && (modalContent.endsWith('.png') || modalContent.endsWith('.jpg') || modalContent.endsWith('.jpeg') || modalContent.endsWith('.svg')) ? (
                        <span class="media-modalimg "><img src={modalContent} alt="Modal Content"/></span>
                    ) : modalContent.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={modalContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </Modal>


            </div>
        </div>
    )
}

export default CreatorSingleOfferingData