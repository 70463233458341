import React, { useEffect, useState } from 'react'
import Search from '../../../assets/images/search.svg'
import arrowUpRight from '../../../assets/images/arrow-up-right.svg'
import dummyProfile from '../../../assets/images/dummy-profile.jpg'
import UniqueOffering from '../../HomeComponent/uniqueOffering'
import { Link } from 'react-router-dom'
import jane from '../../../assets/images/jane.svg'
import { getApi } from '../../../Services/apiService'
import { toast } from 'react-toastify'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'

function List() {

    const [creatorData, setCreatorData] = useState([])
    const [searchName, setSearchName] = useState('')
    const [classState, setClassState] = useState(false)
    const [userData, setUserData] = useState([])
    const [selectedTagIds, setSelectedTagIds] = useState([]); // New state for selected tag IDs
    const [searchText, setSearchText] = useState(''); // New state for search text
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [totalCount, setTotalCount] = useState(0); // Total count of offerings
    const [tags, setTags] = useState([]);
    const pageSize = 9; // Number of offerings per page


    useEffect(() => {
        fetchCreatorData()
    }, [searchText, selectedTagIds, searchText, currentPage])

    useEffect(() => {
        fetchTags()
    }, [])

    useEffect(() => {
        const handleClick = () => setClassState(false);

        document.addEventListener('click', handleClick);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [])

    const fetchCreatorData = async () => {
        const tagIdsParam = selectedTagIds.join(',');
        const searchParam = `search=${encodeURIComponent(searchText || '')}`;

        const response = await getApi(`/creator-list?tag_ids=${tagIdsParam}&${searchParam}&page=${currentPage}&pageSize=${pageSize}`);
        // const response = await getApi(`/creator-list?userName=${searchName}`);
        if (response?.statusCode === 200) {
            if (currentPage === 1) {
                setCreatorData(response?.data); // Append new data to existing data
            }
            else {
                setCreatorData(prevData => [...prevData, ...response?.data]); // Append new data to existing data
            }
            setTotalCount(response?.totalCount); // Set the total count from response
        } else {
            toast.error(response?.message)
        }
    }
    const handleLoadData = () => {
        if (creatorData.length < totalCount) { // Check if more data is available
            setCurrentPage(prevPage => prevPage + 1); // Increment the page number
        }
    };
    const handleLimitSet = () => {
    }
    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };
    const fetchTags = async () => {
        const response = await getApi('/user/popular-tag');
        if (response?.statusCode === 200) {
            setTags(response?.data);
        }
    };
    const handleTagClick = (tagId) => {
        let updatedTagIds = [...selectedTagIds];
        if (updatedTagIds.includes(tagId)) {
            updatedTagIds = updatedTagIds.filter(id => id !== tagId);
        } else {
            updatedTagIds.push(tagId);
        }
        setSelectedTagIds(updatedTagIds);
        // setShouldFetch(true); // Trigger fetch when tags change
    };

    return (
        <>
            <div class="creators-detail offering-user-side home user-creator-list">
                <UserSidebar classState={classState} name={userData} />
                <div class="creators right-main min-height-pera creator-tab home-right-side">
                    <section class="creators-top">
                        <div class="">
                            <div class="section">
                                <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Creators</h1>
                                <div class="creators-input">
                                    <img src={Search} alt='' />
                                    <input type="text" class="search" placeholder="Find offers or creators" onChange={(e) => setSearchText(e?.target?.value)} />
                                </div>
                            </div>
                            <div className="links">
                                <h3>Popular tags</h3>
                                <ul>
                                    {tags && tags.length > 0 && tags.map((tag, index) => (
                                        <li key={index}>
                                            <a
                                                onClick={() => handleTagClick(tag.id)}
                                                className={selectedTagIds.includes(tag.id) ? 'active' : ''}
                                            >
                                                {tag?.tag_name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section class="discover category">
                        <h3 className='mb-0'>All Our Creators</h3>
                    </section>


                    <section class="creators-mid pt-0">
                        <div class="data-scroll">
                            <div class="row w-100">
                                {creatorData && creatorData?.length > 0 && creatorData.map((x, index) => {
                                    return (
                                        <>
                                            <div class="col-sm-6">
                                                <div class="section2-left w-100">
                                                    <div className='profile-tags-div'>
                                                        <label>Design</label>
                                                        <label>Graphic design</label>
                                                        <label>UX&UI Design</label>
                                                    </div>
                                                    <div class="sec2-main">
                                                        <div className='row'>
                                                            <div className='col-xl-4 col-sm-12 col-lg-12'>
                                                                <div class="profile-left-section">
                                                                    <span> <img src={x?.user_profile_image || dummyProfile} alt='' /></span>
                                                                    {/* <span> <img src={jane} alt=''/></span> */}
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-8 col-lg-12 col-sm-12'>
                                                            <div class="profile-right">
                                                            <h2>{x?.name}</h2>
                                                            {x?.specializations.length > 0 && (
                                                                <h3>{x?.specializations[0]?.experience !== '' && `Experience ${x?.specializations[0]?.experience}`}</h3>
                                                            )}
                                                            <p> {x?.about !== null && x?.about?.split(' ').slice(0, 20).join(' ') + (x?.about?.split(' ').length > 200 ? '...' : '')}</p>
                                                            <p className='mobile-pera'>Unlock your design potential with Graphic Design. Learn from experts and transform your creative vision into reality.</p>
                                                        </div>
                                                            </div>
                                                        </div>

                                                       

                                                    </div>
                                                    <Link to={`/creator/detail/${x?.id}`}><button class="button ">Explore  more  offerings  <img src={arrowUpRight} alt='' /></button></Link>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            {creatorData.length < totalCount &&
                                <button class="explore" onClick={handleLoadData}>Load more Creators</button>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default List