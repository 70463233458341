
function BasicsBilling({  }) {

    return (
        <div class="billing">
        <div class="section first">
            <h1>Billing schedule</h1>
            <p>Manage your billing and payouts here. Set up your payment preferences and manage invoicing. For example, if a member joins on 10th, they’ll be charged on the day, and then monthly on the 10th.</p>
        </div>

        <div class="section second">
            <div class="top">
            <h1>Pause upcoming billing cycle</h1>
            <p>Take a break from billing. Pause your upcoming billing cycle when you're not hosting sessions."</p>
        </div>
        <div class="bottom">
        <button class="pause-billing">Pause billing</button>
        </div>
    </div>

    <div class="section third">
        <div class="top">
        <h1>Payout method</h1>
        <p>Add a payout method to get paid!</p>
    </div>
    <div class="bottom">
    <button class="payment-method">Add payout method</button>
    </div>
</div>

<div class="section fourth">
    <div class="top">
    <h1>Payout method</h1>
    <p>Add a payment method to pay for sessions and products!</p>
</div>
<div class="middle">
<div class="image-content">
    <span><img src="images/Payment-icon.svg"/> </span>
    <div class="payment-content">
        <h5>Mastercard ****1234</h5>
        <h6>Expires 11/28</h6>
    </div>
    <div class="payment-buttons">
        <button class="edit">Edit</button>
        <button class="delete">Delete</button>
    </div>
</div>
</div>
<div class="bottom">
<button class="payment-method">Add new payment method</button>
</div>
</div>

{/* After billing payout  */}

<main class="business-status">
    {/* <!-- Business Setup Section --> */}
    <div class="section first">
        <h1 class="business">Business status</h1>
        <div class="radio-container">
            <h1>How are you set up?</h1>
            <label class="radio-label" for="setup-individual">
                I am an individual
                <input type="radio" id="setup-individual" name="businessSetup" checked />
                <span class="checkmark"></span>
            </label>
            <label class="radio-label" for="setup-corporation">
                I represent a corporation
                <input type="radio" id="setup-corporation" name="businessSetup" />
                <span class="checkmark"></span>
            </label>
        </div>

        <div class="radio-container">
            <h1>What’s your citizenship status?</h1>
            <label class="radio-label" for="citizen-us">
                I am a US citizen or resident
                <input type="radio" id="citizen-us" name="citizenshipStatus" checked />
                <span class="checkmark"></span>
            </label>
            <label class="radio-label" for="citizen-not-us">
                I am <strong>not</strong> a US citizen or resident
                <input type="radio" id="citizen-not-us" name="citizenshipStatus" />
                <span class="checkmark"></span>
            </label>
        </div>
    </div>

    {/* <!-- Footer Buttons for Section 1 --> */}
    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

    {/* <!-- Country Selection --> */}
    <div class="section second">
        <label for="country">Country</label>
        <select id="country">
            <option value="">Select your payout country</option>
            {/* <!-- Add more options --> */}
        </select>
    </div>

    {/* <!-- Footer Buttons for Section 2 --> */}
    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

    {/* <!-- Payout Country Detailed Selection --> */}
    <div class="section third">
        <label for="country-detailed">Payout Country</label>
        <select id="country-detailed">
            <option value="afghanistan">Afghanistan</option>
            <option value="albania">Albania</option>
            <option value="algeria">Algeria</option>
            {/* <!-- Add more options --> */}
        </select>
    </div>

    {/* <!-- Footer Buttons for Section 3 --> */}
    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>

    {/* <!-- Payment Method and 2nd Form (Payment Details) --> */}
    <div class="section fourth">
        <h1>How would you like to get paid?</h1>
        <label class="radio-label" for="payment-stripe">
            Direct Deposit via Stripe
            <input type="radio" id="payment-stripe" name="paymentMethod" checked />
            <span class="checkmark"></span>
        </label>
        <div class="payout-fee">
            <h4>Payout fee is USD $0.25 per payout <span>Learn more</span></h4>
        </div>

        {/* <!-- Payment Details Form (Second Form) --> */}
        <form>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="first-name">First Name</label>
                        <input type="text" id="first-name" class="form-control" placeholder="Type your name" />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="second-name">Second Name</label>
                        <input type="text" id="second-name" class="form-control" placeholder="Type your surname" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="address-1">Street address 1</label>
                        <input type="text" id="address-1" class="form-control" placeholder="Type your address" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="address-2">Street address 2</label>
                        <input type="text" id="address-2" class="form-control" placeholder="Type your address" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="city">City</label>
                        <input type="text" id="city" class="form-control" placeholder="Type your city" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="country">Country</label>
                        <input type="text" id="country" class="form-control" placeholder="Type your country" />
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label for="postcode">Postcode</label>
                        <input type="text" id="postcode" class="form-control" placeholder="Type your postcode" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="ssn">Individual SSN</label>
                        <input type="text" id="ssn" class="form-control" placeholder="Type your SSN" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="sort-code">Bank Sort Code</label>
                        <input type="text" id="sort-code" class="form-control" placeholder="Type your bank sort code" />
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="bank-account">Bank Account Number</label>
                        <input type="text" id="bank-account" class="form-control" placeholder="Type your bank account number" />
                    </div>
                </div>
            </div>
        </form>

        <label class="radio-label" for="payment-paypal">
            PayPal
            <input type="radio" id="payment-paypal" name="paymentMethod" />
            <span class="checkmark"></span>
        </label>
        <div class="payout-fee">
            <h4>Payout fee is 1% of the amount transferred, with a minimum of USD $0.25 and a maximum of USD $20 <span>Learn more</span></h4>
        </div>
    </div>

    {/* <!-- Footer Buttons for Section 4 --> */}
    <div class="footer-button">
        <button class="cancel">Cancel</button>
        <button class="save">Save</button>
    </div>
</main>

    </div>
    );
}

export default BasicsBilling;