import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../../Layout/userSidebar';
import burger from '../../../../assets/images/burger-menu.svg'
import ActiveLinks from '../activeLinks';
import leftArrow from '../../../../assets/images/backarrow.svg'
import { useNavigate } from 'react-router-dom';
import cardJewellery from '../../../../assets/images/card jwellery.svg'
import jewellery1 from '../../../../assets/images/jwellery1.svg'
import jewellery2 from '../../../../assets/images/jwellery2.svg'
import share from '../../../../assets/images/share.svg'
import heart from '../../../../assets/images/heart.svg'
import location from '../../../../assets/images/location1.svg'
import doubleBox from '../../../../assets/images/double-box.svg'
import marley from '../../../../assets/images/annete.png'
import whiteArrow from '../../../../assets/images/white-arrow.svg'
import LiveModal from '../liveModal';
import PublishModal from '../publishModal';
import { insertProductData } from '../../../../Counter/productSlice';
import Modal from 'react-modal';
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../../../assets/images/cross-button.svg'
import dummyImage from '../../../../assets/images/profile3.svg'

function SingleProductView({ }) {

  const [classState, setClassState] = useState(false);
  const [readyPublish, setReadyPublish] = useState(false)
  const [offeringLive, setOfferingLive] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const creatorDetail = JSON.parse(localStorage.getItem('full_user_detail'));
  const data = useSelector((state) => state.productCounter);

  const navigate = useNavigate()
  console.log("formStateformStateformStateformStateformStateformState", data);

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };
  const dispatch = useDispatch()

  const openModal = (src) => {
    setModalContent(src);
    setModalIsOpen(true);
  };
  useEffect(() => {
    // fetchData(searchQuery, selectedFilters); // Initial f../etch
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  // Ensure description is defined and is a string
  const description = data?.description || '';
  const isLongDescription = description.length > 300;
  const truncatedDescription = description.length > 300 ? `${description.slice(0, 300)}...` : description;
  const navBack = () => {
    navigate('/creator/offering/home')
  }
  return (
    <>
      <UserSidebar classState={classState} />
      <div className='right-main'>
        <div class="session after-upload-produt">
          <div class="header d-none">
            <h1> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>  </h1>
          </div>
          <div class="active-links mb-2">
            {/* <button className="back" onClick={() => { navBack() }}><img src={leftArrow} alt='' /> </button> */}
            {/* <div class="left"> */}
                  {/* <h1>{data?.name}</h1> */}
                {/* </div> */}
                <h2 onClick={() => { navBack() }} className='text-start'><img src={leftArrow} /> {data?.name}</h2>
          </div>

          <div class="main mt-2">
            <div class="row">
              <div class="col-sm-12 col-xl-7 col-lg-12">
               

                {data && data.tagName && data.tagName.length > 0 && (
                  <div className="choose-tags mb-2">
                    <ul>
                      {data.tagName.map((tag, index) => (
                        <li key={tag.id} className="custom-checkbox">
                          <input disabled
                            type="checkbox"
                            id={`option${index}`}
                          // You might want to manage checked state if needed
                          />
                          <label htmlFor={`option${index}`}>{tag.tag_name}</label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <div class="row">
                  <div class="col-lg-10 col-sm-10">
                    <div class="main-image">
                      <img src={data?.showImagePreview ? data.showImagePreview : cardJewellery} alt='' class="img-fluid " />
                    </div>
                  </div>

                  <div className="col-lg-2 col-sm-2">
                    <div className= {data.offerMaterials.length < 3 ? 'jewellery-img-height' : 'jewellery-img-height image'} >
                    <div className="row jw-cs">
                      {data?.offerMaterials && data.offerMaterials.length > 0 ? (
                        data.offerMaterials
                          .filter((item) => item.media_type === "image" || item.media_type === "video") // Filter for images and videos
                          .map((item, index) => (
                            <div className="col-6 col-lg-12 col-sm-12" key={index}>
                              <div className="jewellery-img">
                                <img src={item.file_url} alt={`additional-image-${index}`} className="img-fluid" />
                              </div>
                            </div>
                          ))
                      ) : (
                        <>

                        </>
                      )}
                    </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-9">
                  <div className='creator-detail-right creator-product'>
                    <h2 className='fw-bold'> About product</h2>
                    <p>
                      {isExpanded || !isLongDescription ? description : truncatedDescription}
                      <br />
                      {isLongDescription && (
                        <strong onClick={toggleText} style={{ cursor: 'pointer' }}>
                          {isExpanded ? 'Read less' : 'Read more'}
                        </strong>
                      )}
                    </p>
                  </div>
                </div>
               
              </div>
              <div class="col-sm-5 col-xl-5 col-lg-5">
                <div class="share-product share-product-rs">
                  <div class="profuct-head d-flex justify-content-between align-items-center ">
                    <h2>{data?.title}</h2>
                    <div class="">
                      <img src={share} alt='' class="px-3" />
                      <img src={heart} alt='' />
                    </div>
                  </div>
                  <div class="product-jwelley">
                    <p class="py-2" ><img src={doubleBox} alt='' class="pe-2" />89 available items</p>
                    <p className="py-2">
                      <img src={location} alt="" className="pe-2" />
                      {data?.selectedOption === 'Pick up' && 'Pick up in NY'}
                      {data?.selectedOption === 'Delivery' && 'Delivery in NY'}
                      {data?.selectedOption === 'both' && 'Pick up and Deliver in NY'}
                    </p>
                  </div>
                  <div class="border-grays py-2"></div>
                  <div class="row align-items-center">
                    <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12">
                      <p class="d-flex">Price <span className='fw-bold px-2'>${data?.discoveryPrice === 'variable_price' ? (data?.discoverMinPrice +  '-' + data?.discoverManPrice) : (data?.discoverMinPrice || data?.minimum_price)}</span></p>
                    </div>
                    <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 text-end">
                      <button class="cutom-invoice">Custom invoice</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-7 col-xl-7 col-lg-7'>
              <div className='row align-item-center res-creator-cs '>
                  <div className='col-sm-5 col-xl-3 col-lg-5'>
                    <div className='creator-detail-img'>
                    <span>
                    <img src={creatorDetail?.user_profile_image || dummyImage} alt='' />
                    </span>
                    </div>
                  </div>
                  <div className='col-sm-7 col-xl-9 col-lg-7'>
                    <div className='creator-detail-right create-dt-cs'>
                      <h2 className='fw-bold'>{creatorDetail?.name || ''}</h2>
                      <h4>{creatorDetail?.specializations[0]?.area_of_work || ''}</h4>
                      <p className='d-block'>{creatorDetail?.specializations[0]?.experience || ''} of experience</p>
                      <button class="discovery">Discovery call<img src={whiteArrow} alt='' class="px-1" /></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeImgModal}
                    contentLabel="Media-Modal"
                    portalClassName="Media-Modal"
                    ariaHideApp={false}
                >
                    <button className='close-button' onClick={() => closeImgModal()}><img src={cross} alt='' /></button>

                    {modalContent && (modalContent.endsWith('.png') || modalContent.endsWith('.jpg') || modalContent.endsWith('.jpeg') || modalContent.endsWith('.svg')) ? (
                        <img src={modalContent} alt="Modal Content"/>
                    ) : modalContent.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={modalContent} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
                </Modal> */}
    </>
  );
}

export default SingleProductView;
