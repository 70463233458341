import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import homemade from '../../../assets/images/home-made.svg';
import { createPaymentIntent, confirmPayment } from '../../../Config/stripeService';
import creditCard from '../../../assets/images/credit-cards.svg';
import paypal from '../../../assets/images/PayPal.svg';
import stripeImg from '../../../assets/images/Stripe.svg';
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

function PaymentModal({ setFiltersModal, setSuccessModal, type, itemDetails }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [isCardComplete, setIsCardComplete] = useState(false); // New state to track if card details are complete
  const [cardNumberError, setCardNumberError] = useState(''); 
  const [cvcError, setCvcError] = useState(''); 

  // const handlePay = async () => {

  //   if (!stripe || !elements) {
  //     return; // Stripe.js has not loaded yet.
  //   }

   


  //   setLoading(true);
  //   setError('');

  //   try {
  //     // Create payment intent on the backend and get the client secret
  //     const clientSecret = await createPaymentIntent(itemDetails?.minimum_price * 100);

  //     // Get the card details from CardElement
  //     const cardElement = elements.getElement(CardElement);

  //     // Confirm the payment
  //     const paymentIntent = await confirmPayment(stripe, cardElement, clientSecret);

  //     if (paymentIntent.status === 'succeeded') {
  //       setFiltersModal(false); // Close PaymentModal
  //       setSuccessModal(true);  // Open CheckoutSuccess Modal
  //     }
  //   } catch (paymentError) {
  //     setError(paymentError.message || 'Payment failed. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };





  const handlePay = () => {
    setFiltersModal(false); // Close PaymentModal
    setSuccessModal(true);  // Open CheckoutSuccess Modal
  };


   // Card Expiry Validation
   const handleExpiryChange = (event) => {
    if (!event.complete) {
      setExpiryError(event.error?.message || 'Invalid expiration date');
    } else {
      setExpiryError('');
    }
  };

  // Card Number Validation
  const handleCardChange = (event) => {
    if (!event.complete) {
      setCardNumberError('Invalid card number');
    } else {
      setCardNumberError('');
    }

    // Check if card is complete (no errors in all fields)
    setIsCardComplete(event.complete && !cardNumberError && !expiryError && !cvcError);
  };

  // CVC Validation
  const handleCvcChange = (event) => {
    if (!event.complete) {
      setCvcError('Invalid CVC');
    } else {
      setCvcError('');
    }
  };
  return (
    <>
      <div className='modal-header'>
        <button type="button" class="back-arrow" data-dismiss="modal" aria-label="back-arrow" onClick={() => setFiltersModal(false)}>
          <span><img src={arrowBtn} /> </span>
        </button>

        {/* <button className="close-modal" onClick={() => setFiltersModal(false)}>
              <img src={cross} alt="close" />
            </button> */}
      </div>
      <div className="payment-modal">
        <div className="modal-body">
          <div class="main">

            <div className='main-starts'>
              <div className='main-first'>
                <h1>Checkout</h1>
                <div class="top">
                  <label className='mb-2'>Choose payment method</label>
                  <div class="row">

                    <div class="col-sm-4"> <label class="radio-check">
                      <input type="radio" checked="checked" name="radio" />
                      <span class="checkmark credit"><img src={creditCard} /><p>Credit/Debit card</p></span>
                    </label></div>

                    <div class="col-sm-4"> <label class="radio-check">
                      <input type="radio" checked="checked" name="radio" />
                      <span class="checkmark pay-pal"><img src={paypal} /></span>
                    </label></div>

                    <div class="col-sm-4"> <label class="radio-check">
                      <input type="radio" checked="checked" name="radio" />
                      <span class="checkmark stripe"><img src={stripeImg} /></span>
                    </label></div>

                  </div>
                </div>
                <div class="bottom">
                  
                  {/* <CardElement /> */}
                  <div className='row'>
                    <div className='col-sm-6'>
                  <label className='mb-2' htmlFor="card-number">Card Number</label>
                  </div>
                  <div className='col-sm-3'>
                  <label htmlFor="expiry-date">Exp to</label>
                  </div>
                  <div className='col-sm-3'>
                  <label htmlFor="cvc">CVC</label>
                  </div>
                  </div>
                  <div className='row'>
                  <div className="col-sm-6">
                   
                    <CardNumberElement
                      onChange={handleCardChange}
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            letterSpacing: '0.025em',
                            fontFamily: 'Source Code Pro, monospace',
                            // '::placeholder': {
                            //   color: '#aab7c4',
                            // },
                            placeholder: {
                              color: '#aab7c4', // Placeholder color
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                      className="custom-card-number"
                      />
                      {cardNumberError && <div className="error-message">{cardNumberError}</div>}
                  </div>

                  <div className="col-sm-3">
                    {/* <label htmlFor="expiry-date">Exp to</label> */}
                    <CardExpiryElement
                      onChange={handleExpiryChange}
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            letterSpacing: '0.025em',
                            fontFamily: 'Source Code Pro, monospace',
                            border: '1px solid #c3c3c3 !important',
                            placeholder: { color: '#aab7c4' },
                          },
                          invalid: { color: '#9e2146' },
                        },
                      }}
                      className="custom-card-expiry"
                    />

                    {expiryError && <div className="error-message">{expiryError}</div>}
                  </div>

                  <div className="col-sm-3 ">
                    {/* <label htmlFor="cvc">CVC</label> */}
                    <CardCvcElement
                    onChange={handleCvcChange}
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: '#424770',
                            letterSpacing: '0.025em',
                            fontFamily: 'Source Code Pro, monospace',
                            placeholder: {
                              color: '#aab7c4', // Placeholder color
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                      className="custom-card-cvc"
                    />
                    {cvcError && <div className="error-message">{cvcError}</div>}

                  </div>
                  </div>
                </div>
                <div class="payment-method">
                  <label class="check3"> Save this payment method
                    <input type="checkbox" checked="checked" />
                    <span class="checkmark"></span>
                  </label>

                </div>
                {/* <button
              className="pay"
              onClick={() => handlePay()}
            >
              Pay
            </button> */}
                 <button
                  className={`pay ${( cardNumberError || expiryError || cvcError) ? 'disabled' : ''}`}
                  onClick={handlePay}
                  disabled={loading || cardNumberError || expiryError || cvcError}
                >
                  {loading ? 'Processing...' : 'Pay'}
                </button>
              </div>
              {(type === 'digital' || type === 'physical') && (
                <div className='main-pay'>
                  <h3><span><img src={homemade} /></span>Home Training Manual</h3>
                  <div class="price-details">
                    <h6>Price Details</h6>
                    <ul className='ps-0'>
                      <li><span>Home Training Manual</span> <strong>${itemDetails?.minimum_price}</strong> </li>
                      <li><span>Marketplace fee</span> <strong>${itemDetails?.minimum_price}</strong> </li>
                    </ul>
                    <div className='sub-total mt-2'>
                      <p className='w-100'>Subtotal Price<span>${itemDetails?.minimum_price}</span></p>
                    </div>
                  </div>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default PaymentModal;
