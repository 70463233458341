import React from 'react'
import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { Link } from 'react-router-dom'
import { insertUserData } from '../../../Counter/userSignUpSlice'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { creatorAboutValidation } from '../../../Validations/formValidation'
import Header from '../../CreatorComponents/CreatorJoin/header'

function AboutDetails({ nextStep, prevStep, data ,admin}) {
  const initialValues = {
    workArea: data?.workArea || '',
    experience: data?.experience?.match(/\d+/)[0] || '',
    experienceData: data?.experienceData  || '',
    link: data?.link || '',
    country: data?.location && data?.location!=='' && data?.location.split(',')[0] || '',
    city: data?.location && data?.location!=='' && data?.location.split(',')[1] || '',
  }
  const dispatch = useDispatch();
  const onSubmit = async (value) => {
    const values ={
      workArea:value?.workArea,
      link: value?.link,
      experience : value?.experience +' '+ value?.experienceData,
      experienceData: value?.experienceData,
      location: `${value?.country},${value?.city}`
    }
    dispatch(insertUserData(values));
    nextStep()
  }

  return (
    <div className='detail-page tell-us signup-cs'>
       <Header/>
      <h2>Smooth Sailing into Membership: Just Two Simple Steps!</h2>
      <div className="detail-main">
        <h1>Tell {admin === 'admin' ? 'about Creator' :'us about yourself'}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={creatorAboutValidation}
          onSubmit={onSubmit}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="workArea"> {admin === 'admin' ? 'Field': 'Area'} of work</label>
              <Field
                type="text"
                id="workArea"
                name="workArea"
                className="form-control"
                placeholder={admin === 'admin'? 'eg: photographer, therapist, life coach, home baker, artist etc.' :'eg: photographer, therapist, life coach, home baker, artist etc.'}
              />
              <ErrorMessage name="workArea" component="div" className="text-danger" />
            </div>

            {/* <div className="form-group">
              <label htmlFor="experience">Experience</label>
              <Field
                type="text"
                id="experience"
                name="experience"
                className="form-control"
                placeholder="How long you do it"
              />
              <ErrorMessage name="experience" component="div" className="text-danger" />
              <Field as="select" name="experienceData" className="form-control" id="experienceData" placeholder='Years/Months'>
                <option value="">Select experience</option>
                <option value="months">Months</option>
                <option value="years">Years</option>
              </Field>
              <ErrorMessage name="experienceData" component="div" className="text-danger" />
            </div> */}
             <div className="form-group">
              <label htmlFor="experience">Experience</label>
              <div className='row'>
                <div className='col-sm-6'>
                  <Field
                    type="number"
                    id="experience"
                    name="experience"
                    className="form-control"
                    placeholder="How long have you been in this field?"
                    min='0'
                  />
                  <ErrorMessage name="experience" component="div" className="text-danger" />
                </div>
                <div className='col-sm-6'>
                  <Field as="select" name="experienceData" className="form-control" id="experienceData" placeholder='Years/Months'>
                    <option value="">Select experience</option>
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                  </Field>
                  <ErrorMessage name="experienceData" component="div" className="text-danger" />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="link">Link</label>
              <Field
                type="text"
                id="link"
                name="link"
                className="form-control"
                placeholder="Enter Social media or Website link"
              />
              <ErrorMessage name="link" component="div" className="text-danger" />
            </div>

            <div className="form-group">
           
              <div className='row'>
              <div className='col-sm-6'>
              <label htmlFor="country">Country</label>
                  <Field
                    type="text"
                    id="country"
                    name="country"
                    className="form-control"
                    placeholder="USA"
                    // min='0'
                  />
                  <ErrorMessage name="country" component="div" className="text-danger" />
               </div>
               <div className='col-sm-6'>
                  <div className="form-group">
              <label htmlFor="city">City</label>
              
                  <Field
                    type="text"
                    id="city"
                    name="city"
                    className="form-control"
                    placeholder="Austin"
                    // min='0'
                  />
                  <ErrorMessage name="city" component="div" className="text-danger" />
                
            </div>
            </div>
            </div>
              </div>
            <button type="submit" className="button">
              Continue
            </button>
          </Form>
        </Formik>
      </div>
      <div className="back-button" onClick={() => prevStep()}>
        {/* <Link to='/creator/detail'> */}
        <button className="button"><img src={leftArrow} alt='' /> </button>
        {/* </Link> */}
      </div>
    </div>
  )
}

export default AboutDetails