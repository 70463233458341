import React, { useEffect, useState, useCallback } from 'react';
import cross from '../../../assets/images/cross-button.svg';
import creditCard from '../../../assets/images/credit-cards.svg';
import paypal from '../../../assets/images/PayPal.svg';
import stripe from '../../../assets/images/Stripe.svg';
import arrowBtn from '../../../assets/images/arrow-btn.svg';
import checkCircle from '../../../assets/images/check-mark.svg';
import message2 from '../../../assets/images/msg-blank.svg';
import check from '../../../assets/images/success.svg';
import remove from '../../../assets/images/close-circle.svg';
import pin from '../../../assets/images/pin-download.svg';


function CheckoutSuccess({ setFiltersModal, type }) {

    return (
        <><div className='modal-header'>
            {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => setSuccessModal(false)}>
                <span><img src={cross} /> </span>
            </button> */}
        </div><div>
                <div className="payment-modal">
                    {/* <button className="close-modal" onClick={() => setSuccessModal(false)}>
    <img src={cross} alt="close" />
  </button> */}
                </div>
            </div><div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel"></h5>

                    </div>
                    <div class="modal-body">
                        <div class="thanks">
                            <div class="top">
                                <span><img src={checkCircle} /> </span>
                                <div className='text-start'>
                                <h1>Thanks for Your Payment</h1>
                                <p>Your reservation was completed successfully</p>
                                </div>
                            </div>
                            {!type && (
                                <div class="mid">
                                    <label>Reserved Time: <span>9:00 AM</span></label>
                                    <label>Reserved Date: <span>29/03/2024 (FR)</span></label>
                                    <p>We have sent detailed information about the reservation to</p>
                                    <a>anette.black@example.com</a>
                                </div>

                            )}

                            {(type === 'digital' || type === 'physical') && (
                                <div class="mid">
                                    <label>Payment Time: <span>9:00 AM</span></label>
                                    <label>Payment Date: <span>29/03/2024 (FR)</span></label>
                                    <p>We have sent detailed information about the reservation to</p>
                                    <a>anette.black@example.com</a>
                                </div>
                            )}

                            <div class="bottom">
                                {!type && (
                                    <>
                                        <h1>Connect with creator</h1>
                                        <p> You can now connect with the creator to discuss details, ask clarifying questions</p>
                                        <button class="button pe-5"><img className='pe-3' src={message2} />Message creator </button>
                                    </>
                                )}

                                { type === 'physical' && (
                                    <>
                                        <h1>Product details</h1>
                                        <label>Approximate Delivery date: <span>April 17</span></label>
                                        <label>Delivery address: <span>70 Red River St, Austin</span></label>
                                        <p> You can now connect with the creator to discuss details, ask clarifying questions</p>
                                        <button class="button pe-5 msg-creator"><img className='pe-3' src={message2} />Message creator </button>
                                    </>
                                )}

                                {type === 'digital' && (
                                    <>
                                        <button class="button pe-5"><img className='pe-3' src={pin} />Download product </button>
                                        <button class="button pe-5 msg-creator"><img className='pe-3' src={message2} />Message creator </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    );
}

export default CheckoutSuccess;