import React, { useState } from 'react';
import clock from '../../../../assets/images/clock3.svg'

const TimePicker = ({values,handleInputChange,index,timeLimit,val,num,strin,label,position,manageCalendar,showManage,isOpen,setIsOpen}) => {
  const [times, period] = values && values?.split(' ');

// Split the hour and minute
const [hour, minute] = values && times?.split(':');

  const [time, setTime] = useState({
    hour: hour || '00',
    minute: minute || '00',
    period: period || 'AM',
  });

  

  // const [isOpen, setIsOpen] = useState(false); // Control if dropdowns are open


  const handleChange = (type, value) => {
    const updatedTime = {
      ...time,
      [type]: value,
    };
    setTime(updatedTime);
    // setTime((prev) => ({
    //   ...prev,
    //   [type]: value,
    // }));
    handleInputChange(index, timeLimit,`${updatedTime.hour}:${updatedTime.minute} ${updatedTime.period}`, num, strin, label, position)
  };

  const toggleDropdowns = (position) => {
    // setIsOpen(!isOpen);
    // const newIsOpen = isOpen.map((open, i) => (i === position ? !open : false)); // Toggle only the clicked one, close others
    setIsOpen();
  };
  

  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString());
  const minutes = ['00', '30'];
  const periods = ['AM', 'PM'];

  // Inline CSS Styles
  const styles = {
    timePicker: {
      width: '100%',
      position: 'relative',
    },
    dropdownContainer: {
      cursor: 'pointer',
      border: '1px solid #ccc',
      padding: '12px 40px',
      height: '48px',
      borderRadius: '12px',
      userSelect: 'none',
      width: '100%',
    },
    dropdownHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dropdowns: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      position: 'absolute',
      top: '100%',
      left: '0',
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      zIndex: 1000,
      width: '100%',

    },
    dropdownList: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      textAlign: 'center',
      fontSize: '15px', 

    },
    dropdownItem: {
      padding: '8px',
      cursor: 'pointer',
    },
    selected: {
      fontWeight: 'bold',
      backgroundColor: '#e0e0e0',
      borderRadius:'4px',
      cursor: 'pointer',
      background: '#007F73',
      color:'#fff'
    },
    dropdownItemHover: {
      backgroundColor: '#f0f0f0',
    },
  };

  return (
    <div style={styles.timePicker} className='time'>
      {/* <label htmlFor="time">Select Time: </label> */}
<img src={clock} alt=''/>
      <div style={styles.dropdownContainer} onClick={()=>{((manageCalendar===true && showManage===true) || (manageCalendar===false && showManage===false)) && toggleDropdowns()}} className='time-set'>
        <div className="dropdown" tabIndex={0}>
          <div style={styles.dropdownHeader}>
            { `${time.hour}:${time.minute} ${time.period}`}
          </div>
          {isOpen && (
            <div style={styles.dropdowns} className='time-drop-down'>
              {/* Hours Dropdown */}
              <div style={styles.dropdownList} className='hour'>
                {hours.map((hour) => (
                  <div 
                    key={hour}
                    style={{
                      ...styles.dropdownItem,
                      ...(time.hour === hour ? styles.selected : {}),
                    }}
                    onClick={() => {((manageCalendar===true && showManage===true) || (manageCalendar===false && showManage===false)) && handleChange('hour', hour)}}
                  >
                    {hour}
                  </div>
                ))}
              </div>

              {/* Minutes Dropdown */}
              <div style={styles.dropdownList}>
                {minutes.map((minute) => (
                  <div
                    key={minute}
                    style={{
                      ...styles.dropdownItem,
                      ...(time.minute === minute ? styles.selected : {}),
                    }}
                    onClick={() =>{((manageCalendar===true && showManage===true) || (manageCalendar===false && showManage===false)) && handleChange('minute', minute)}}
                  >
                    {minute}
                  </div>
                ))}
              </div>

              {/* AM/PM Dropdown */}
              <div style={styles.dropdownList}>
                {periods.map((period) => (
                  <div
                    key={period}
                    style={{
                      ...styles.dropdownItem,
                      ...(time.period === period ? styles.selected : {}),
                    }}
                    onClick={() => {((manageCalendar===true && showManage===true) || (manageCalendar===false && showManage===false)) && handleChange('period', period)}}
                  >
                    {period}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* <p>Selected Time: {`${time.hour}:${time.minute} ${time.period}`}</p> */}
    </div>
  );
};

export default TimePicker;
