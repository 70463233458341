import React, { useState } from 'react'
import { insertOfferingData } from '../../../Counter/offeringSLice'
import { useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { deleteApi, postApi } from '../../../Services/apiService';
import * as Yup from 'yup';
import { travelAddress } from '../../../Validations/formValidation';
import crossIcon from '../../../assets/images/cros.svg'

function NewAddress({ closeModal, getAddress, setAddressForm,setAddressState,addressState,setClassHappen,classHappen,classMode }) {
  const initialValues = {
    country: '',
    state: '',
    zipCode: '',
    city: '',
    // travelArea: '',
    address: '',
  };
  const dispatch = useDispatch();
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipcode] = useState('')
  const [city, setCity] = useState('')
  const [travelArea, setTravelArea] = useState('')
  const [address, setAddress] = useState('')
  const [addressId, setAddressId] = useState('')
  const [addressIdData, setAddressIdData] = useState('')
  const [addressValidation, setAddressValidation] = useState(false)
  // const [a]

  const handleSave = async (value) => {
    console.log("valuevaluevaluevaluevaluevaluevaluevalue", value);
    const insertValue = {
      country: value?.country || addressIdData?.country,
      state: value?.state || addressIdData?.state,
      zip_code: value?.zipCode || addressIdData?.zipCode,
      city: value?.city || addressIdData?.city,
      travel_area: value?.travelArea || addressIdData?.travelArea,
      address: value?.address || addressIdData?.address
    }
    let response;
    if (!addressValidation) {
      response = await postApi('/address', insertValue)
      // setClassHappen(!classHappen)
      console.log("Addressresponse", response);
    }
    const values = {
      address: response?.data?.id || addressId,
      locationInput: insertValue,
      classMode:classMode
      // address: {
      //   country: value?.country,
      //   state: value?.state,
      //   zipCode: value?.zipCode,
      //   yourCity: value?.city,
      //   travelArea: value?.travelArea,
      //   Address: value?.address
      // }
    }
    setAddressForm(insertValue)

    dispatch(insertOfferingData(values))

    closeModal()
  }
  // const validationSchema = Yup.object({
  //   country: Yup.string().required('Country is required'),
  //   state: Yup.string().required('State is required'),
  //   zipCode: Yup.string().required('Zip code is required').matches(/^\d{5}$/, 'Invalid zip code'),
  //   city: Yup.string().required('City is required'),
  //   travelArea: Yup.string().required('Travel area is required'),
  //   address: Yup.string().required('Address is required'),
  // });

  const handleAddressSet = (id,e) => {
   
    e.stopPropagation()
    setAddressId(id?.id);
    setAddressIdData(id)
    setAddressValidation(true)
    setClassHappen(!classHappen)
    // closeModal()
  }

  const handleDeleteAddress = async(x) =>{
// export const deleteApi = async (url, data) => {
  const values = {
    id:x?.id
  }
  
  const response = await deleteApi(`/address?addressId=${x?.id}`)
  setAddressState(!addressState)
  // if(response.status === 200){
  //   setAddressState(!addressState)
  //   alert('deleted')
  // }
  // else{
  //   alert("not deleted")
  // }
  }

  return (
    <>
      {/* <!-- Button trigger modal --> */}
      {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#new-address">
        Launch demo modal
      </button> */}

      {/* <!-- Modal --> */}
      {/* <div class="modal fade" id="new-address" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
      <div class="" id="new-address" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            {/* <div class="modal-body">
              <div class="new-address">
                <div class="header">
                  <h1>New address</h1>
                  <div class="top-buttons">
                    <button class="cancel" onClick={closeModal}>Cancel</button>
                    <button type="button" class="save"  >Save</button>
                  </div>
                </div>
                <div className="main">
                  <h2 className="add-new">Add new address</h2>
                  <Formik 
                  initialValues={initialValues}
                  //  validationSchema={validationSchema}
                    onSubmit={handleSave}
                    >
                    <Form className="row">
                      <div className="col-sm-6">
                        <label htmlFor="country">Country</label>
                        <Field as="select" id="country" name="country">
                          <option value="">Select Country</option>
                          <option value="USA">USA</option>
                          <option value="India">India</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                        </Field>
                        <ErrorMessage name="country" component="div" className="error" />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="state">State</label>
                        <Field as="select" id="state" name="state">
                          <option value="">Select State</option>
                          <option value="a">a</option>
                          <option value="b">b</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                        </Field>
                        <ErrorMessage name="state" component="div" className="error" />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="zipCode">Zip code</label>
                        <Field type="text" id="zipCode" name="zipCode" placeholder="12345689" />
                        <ErrorMessage name="zipCode" component="div" className="error" />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="city">Your city</label>
                        <Field as="select" id="city" name="city">
                          <option value="">Select City</option>
                          <option value="a">a</option>
                          <option value="b">b</option>
                          <option value="C">C</option>
                          <option value="D">D</option>
                        </Field>
                        <ErrorMessage name="city" component="div" className="error" />
                      </div>
                      <div className="col-sm-12">
                        <label htmlFor="travelArea">*Travel area</label>
                        <Field type="text" id="travelArea" name="travelArea" placeholder="Type your area (Downtown Austin, North Austin, South Austin etc.)" />
                        <ErrorMessage name="travelArea" component="div" className="error" />
                      </div>
                      <div className="col-sm-12">
                        <label htmlFor="address">*Address</label>
                        <Field type="text" id="address" name="address" placeholder="Street Name and Number, apartment number" />
                        <ErrorMessage name="address" component="div" className="error" />
                      </div>
                      <div className="col-sm-12">
                        <button type="submit">Submit</button>
                      </div>
                    </Form>
                  </Formik>
                  <p className="please-note">*Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                </div>
                <div class="bottom-section">
                  <h3>Your saved addresses</h3>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="address-content">
                        <label class="custom-radio"><p>Address 1</p>
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                        <h3 class="address-data">
                          13 Kopengagen Street, Anytown
                        </h3>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="address-content">
                        <label class="custom-radio"><p>Address 2</p>
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                        <h3 class="address-data">
                          13 Kopengagen Street, Anytown
                        </h3>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="address-content">
                        <label class="custom-radio"><p>Address 3</p>
                          <input type="radio" name="radio" />
                          <span class="checkmark"></span>
                        </label>
                        <h3 class="address-data">
                          13 Kopengagen Street, Anytown
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> */}
            <div className="modal-body">
              <div className="new-address">
                <div className="header">
                  <h1>New Location</h1>
                  {/* <div className="top-buttons"> */}
                    
                    
                    
                    {/* Save button will call submitForm */}
                    <Formik
                      initialValues={initialValues}
                      validationSchema={addressValidation === false && travelAddress}
                      onSubmit={(value) => handleSave(value)}
                    >
                      {({ submitForm }) => (
                        <>
                        <div className='address-btn'>
                        <button className="cancel" onClick={closeModal}>Cancel</button>
                          <button className="save" type="button" onClick={submitForm}>Save</button>
                          </div>
                          <div className="top-buttons top-btns-set">
                          <div className="main">
                            <h2 className="add-new">Add new address</h2>
                            <Form className="row">
                            <div className="col-sm-12">
                                <label htmlFor="address">*Address</label>
                                <Field type="text" id="address" name="address" placeholder="Street Name and Number, apartment number" />
                                <ErrorMessage name="address" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="country">Country</label>
                                <Field as="select" id="country" name="country">
                                  <option value="">Select Country</option>
                                  <option value="USA">USA</option>
                                  <option value="India">India</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                </Field>
                                <ErrorMessage name="country" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="state">State</label>
                                <Field as="select" id="state" name="state">
                                  <option value="">Select State</option>
                                  <option value="a">a</option>
                                  <option value="b">b</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                </Field>
                                <ErrorMessage name="state" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="zipCode">Zip code</label>
                                <Field type="text" id="zipCode" name="zipCode" placeholder="12345689" />
                                <ErrorMessage name="zipCode" component="div" className="error" />
                              </div>
                              <div className="col-sm-6">
                                <label htmlFor="city">Your city</label>
                                <Field as="select" id="city" name="city">
                                  <option value="">Select City</option>
                                  <option value="a">a</option>
                                  <option value="b">b</option>
                                  <option value="C">C</option>
                                  <option value="D">D</option>
                                </Field>
                                <ErrorMessage name="city" component="div" className="error" />
                              </div>
                              <div className='col-md-12'>
                                <p className='py-3'>*Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                              </div>
                              {/* <div className="col-sm-12">
                                <label htmlFor="travelArea">*Travel area</label>
                                <Field type="text" id="travelArea" name="travelArea" placeholder="Type your area (Downtown Austin, North Austin, South Austin etc.)" />
                                <ErrorMessage name="travelArea" component="div" className="error" />
                              </div> */}
                              {/* <div className="col-sm-12">
                                <label htmlFor="address">*Address</label>
                                <Field type="text" id="address" name="address" placeholder="Street Name and Number, apartment number" />
                                <ErrorMessage name="address" component="div" className="error" />
                              </div> */}
                              {/* <div className="col-sm-12">
                                                <button type="submit">Submit</button>
                                            </div> */}
                            </Form>
                          </div>
                          </div>
                        </>
                      )}
                    </Formik>
                  {/* </div> */}
                </div>
                {getAddress && getAddress.length > 0 &&
                <div className="bottom-section">
                  <h3>Saved Location</h3>
                  <div className="row">
                    {getAddress && getAddress.length > 0 && getAddress.map((x, index) => {
                      return (
                        index <3 &&
                        <div className="col-sm-4" key={index} >
                          {/* <div className="address-content" onClick={() => handleAddressSet(x)}> */}
                          <div className="address-content" >
                          {/* <div className="address-content" onClick={(e) => handleAddressSet(x,e)}> */}
                            <span className='close' onClick={()=>handleDeleteAddress(x)}><img src={crossIcon} alt="" /></span>
                            <label className="custom-radio" onClick={(e) => handleAddressSet(x,e)}>
                              <input type="radio" name="radio" checked={x?.id === addressId}/>
                              <span className="checkmark"></span>
                            </label>
                              <p>Address {index + 1}</p>
                            <h3 className="address-data">
                              {/* 13 Kopengagen Street, Anytown */}
                              {x?.address + ' ' + x?.country}
                              {/* {x?.travel_area + ' ' + x?.address + ' ' + x?.country} */}
                            </h3>
                          </div>
                        </div>
                      )
                    })}
                    {/* <div className="col-sm-4">
                            <div className="address-content">
                                <label className="custom-radio">
                                    <p>Address 1</p>
                                    <input type="radio" name="radio" />
                                    <span className="checkmark"></span>
                                </label>
                                <h3 className="address-data">
                                    13 Kopengagen Street, Anytown
                                </h3>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="address-content">
                                <label className="custom-radio">
                                    <p>Address 2</p>
                                    <input type="radio" name="radio" />
                                    <span className="checkmark"></span>
                                </label>
                                <h3 className="address-data">
                                    13 Kopengagen Street, Anytown
                                </h3>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="address-content">
                                <label className="custom-radio">
                                    <p>Address 3</p>
                                    <input type="radio" name="radio" />
                                    <span className="checkmark"></span>
                                </label>
                                <h3 className="address-data">
                                    13 Kopengagen Street, Anytown
                                </h3>
                            </div>
                        </div> */}
                  </div>
                </div>
}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewAddress