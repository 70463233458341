import React from 'react'
import logo from '../../../assets/images/logo.svg'

function Header() {
  return (
    <>
      <header>
        <span><img src={logo} alt=''/> </span>
      </header>
    </>
  )
}

export default Header