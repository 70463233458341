import React, { useState } from 'react'
import Modal from 'react-modal';
import cross from '../../../assets/images/gray-closebtn12.svg'

function Gallery({ galleryImages }) {
  console.log('this is gallery images', galleryImages);
  const [imgModal, setImgModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (image) => {
    setSelectedImage(image);
    setImgModal(true);
  };

  const closeModal = () => {
    setImgModal(false);
    setSelectedImage('');
  };

  return (
    <>
      <section className="gallery">
        <h3>Gallery</h3>
        <div className="row">
          <div className="col-sm-8">
            <div className="gallery-left" onClick={() => openModal(galleryImages && galleryImages[0]?.file_url)}>
              <img src={galleryImages && galleryImages[0]?.file_url} alt="Main Gallery" />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="row">
              { galleryImages && galleryImages.slice(1).map((image, index) => (
                <div className="col-sm-6" key={index}>
                  <div className="gallery-small" onClick={() => openModal(image.file_url)}>
                    <img src={image.file_url} alt={`Gallery ${index + 1}`} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="dots">
          <ul>
            <li className="active"></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div> */}

        <Modal
          isOpen={imgModal}
          onRequestClose={closeModal}
          contentLabel="Media-Modal"
		  portalClassName="Media-Modal"
		  ariaHideApp={false}

        >
          <button className='close-button' onClick={closeModal}><img src={cross} alt='Close' /></button>
          {/* <img src={selectedImage} alt="Selected Gallery" /> */}
		  {selectedImage && (selectedImage.endsWith('.png') || selectedImage.endsWith('.jpg') || selectedImage.endsWith('.jpeg') || selectedImage.endsWith('.svg')) ? (
                        <img src={selectedImage}/>
                    ) : selectedImage.endsWith('.mp4') ? (
                        <video controls style={{ width: '100%' }}>
                            <source src={selectedImage} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : null}
        </Modal>
      </section>
    </>
  );
}

export default Gallery;
