import React, { useState } from 'react'
import Header from '../../CreatorComponents/CreatorJoin/header'
import mailLogo from '../../../assets/images/mail-logo.svg'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { changePasswordValidation, loginValidation } from '../../../Validations/formValidation'
import { postApi } from '../../../Services/apiService'
import { useNavigate, useParams } from 'react-router-dom'
import eye from '../../../assets/images/Eye.svg'
import eyeClosed from '../../../assets/images/Eye-closed.svg'
import EmailVerify from '../../CreatorComponents/CreatorJoin/emailVerify'
import Modal from 'react-modal';
import cross from '../../../assets/images/cross-button.svg'
import { toast } from 'react-toastify'
import ResetModal from './resetModal'

function ChangePassword({ nextStep, prevStep,setUserModalIsOpen }) {
  const [formValues, setFormValues] = useState(null);
  const [step, setStep] = useState(5);

  const id = useParams();
  console.log("idididid",id?.id)
  const token = id?.id

  const [showPassword, setShowPassword] = useState(false);
  const [loader,setLoader] = useState(false)
  const [congratulationModal,setCongratulationModal] = useState(false)
  const [forgotEmail,setForgotEmail] = useState('')
  const [sendEmailAgain, setSendEmailAgain] = useState(false)
  const [loaders,setLoaders] = useState(false)
  const [newPassword,setNewPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const onSubmit = async (values) => {
      setLoader(true);
      if(values?.newPassword === values?.repeatPassword){
          const value = {
              new_password: values?.newPassword,
              confirm_password:values?.repeatPassword
            };
            // setFormValues(value); // Store the form values in the state
            
    const response = await postApi(`/reset-password/${token}`, value);
    // const response = {statusCode:200}
    if (response?.statusCode === 200) {
        setCongratulationModal(true)
      setLoader(false);
    }
    else{
        setLoader(false)
    }
} else{
    setLoader(false);
    toast.error("New password and Repeat password does not match")
}
  };
  
  const initialValues = {
    newPassword: '',
    repeatPassword: ''
  }

  return (
    <div className='detail-page login'>
        <div className="detail-main">
          <h2>Change Password</h2>
          <p>Please enter your new password</p>
          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordValidation}
            onSubmit={onSubmit}
          >
            <Form>
              <div className="form-group">
                <label htmlFor="email">New password</label>
                <Field
                  type={newPassword ? 'text' : 'password'}
                  id="newPassword"
                  name="newPassword"
                  className="form-control"
                  placeholder="Enter your password"
                />
                <span className="eye-icon" onClick={()=>setNewPassword(!newPassword)}>
                  {newPassword ? <img src={eye} alt='' /> : <img src={eyeClosed} alt='' />}
                </span>
                <ErrorMessage name="newPassword" component="div" className="text-danger" />
              </div>
              <div className="form-group">
                <label htmlFor="password">Repeat password</label>
                <Field
                  type={showPassword ? 'text' : 'password'}
                  id="repeatPassword"
                  name="repeatPassword"
                  className="form-control"
                  placeholder="Rewrite your password"
                />
                <span className="eye-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <img src={eye} alt='' /> : <img src={eyeClosed} alt='' />}
                </span>
                <ErrorMessage name="repeatPassword" component="div" className="text-danger" />
              </div>
              <button type="submit" className="button" disabled={loader}>
                {loader && <div className="spinner-border" role="status"><span className="sr-only"></span></div>}
                Continue
              </button>
            </Form>
          </Formik>
          <h5>Don't have an account yet? <strong onClick={() => navigate()}><u>Sign up</u></strong> </h5>
        </div>
        <Modal
        isOpen={congratulationModal}
        onRequestClose={() => setCongratulationModal(false)}
        contentLabel="Tag Modal"
        shouldCloseOnOverlayClick={true}
        portalClassName="logout-modal-lg forget-pass"
      >
         <button className='close-button' onClick={() => setCongratulationModal(false)}><img src={cross} alt='' /></button>
         <ResetModal prevStep={''} submitForm={''} loader={''} loginStatus={'googleLogin'}/>
      </Modal>
    </div>
  );
  
  
}

export default ChangePassword