import React, { useEffect, useState, useCallback } from 'react';
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import addtag from '../../../assets/images/add-tag.svg'
import search from '../../../assets/images/search.svg'
import bannerRight from '../../../assets/images/dummy-profile.jpg'
import hearts from '../../../assets/images/heart.svg'



function SessionList() {
    const [classState, setClassState] = useState(false);
    const handleSidebarClick = (event) => {
        event.stopPropagation();
        setClassState(!classState);
    };


    useEffect(() => {
        const handleClick = () => setClassState(false);
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);
    return (
        <div>
            <div className="HomeMainDiv">
                <UserSidebar classState={classState} />
                <div className="creator-offering home min-height-pera">
                    <div className="RightSideDiv right-main">
                        <div className='header'>
                            <button className="burger-menu" onClick={handleSidebarClick}>
                                <img src={burger} alt='burger menu' />
                            </button>
                            <h2>My Sessions</h2>
                        </div>
                        <div className='top-buttons'>
                            <div className='input-container'>
                                <img src={search} alt="Add tag" />
                                <input
                                    type="text"
                                    placeholder='Find Offerings or Creators'
                                />
                            </div>
                        </div>
                        <div className='top-buttons'>
                            <div class="creator-offering-tab"
                            >  <ul>
                                    <li><a className={''} >
                                        Upcoming
                                    </a> </li>
                                    <li><a className={''} >
                                        Past
                                    </a> </li>
                                </ul>
                            </div>

                        </div>
                        <div className="data-scroll">
                            <h3>Monday 01.04.2024</h3>
                            <div className="row">
                                <div className="col-sm-4" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={bannerRight} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h5>
                                                    Physical
                                                </h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{'Name'} </h3>
                                            <ul className='product-data-tags'>
                                                <li className={''}>
                                                    {'tag name'}
                                                </li>
                                            </ul>
                                            <p>{'description write here'}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h6><strong>{'user name'}</strong> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={bannerRight} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h5>
                                                    Physical
                                                </h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{'Name'} </h3>
                                            <ul className='product-data-tags'>
                                                <li className={''}>
                                                    {'tag name'}
                                                </li>
                                            </ul>
                                            <p>{'description write here'}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h6><strong>{'user name'}</strong> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={bannerRight} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h5>
                                                    Physical
                                                </h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{'Name'} </h3>
                                            <ul className='product-data-tags'>
                                                <li className={''}>
                                                    {'tag name'}
                                                </li>
                                            </ul>
                                            <p>{'description write here'}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h6><strong>{'user name'}</strong> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={bannerRight} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h5>
                                                    Physical
                                                </h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{'Name'} </h3>
                                            <ul className='product-data-tags'>
                                                <li className={''}>
                                                    {'tag name'}
                                                </li>
                                            </ul>
                                            <p>{'description write here'}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h6><strong>{'user name'}</strong> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={bannerRight} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h5>
                                                    Physical
                                                </h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{'Name'} </h3>
                                            <ul className='product-data-tags'>
                                                <li className={''}>
                                                    {'tag name'}
                                                </li>
                                            </ul>
                                            <p>{'description write here'}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h6><strong>{'user name'}</strong> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            <img src={bannerRight} alt='' />
                                            <div className="product-image-data">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h5>
                                                    Physical
                                                </h5>
                                                <button><img src={hearts} alt='' /></button>
                                            </div>
                                        </div>
                                        <div className="product-data">
                                            <h3>{'Name'} </h3>
                                            <ul className='product-data-tags'>
                                                <li className={''}>
                                                    {'tag name'}
                                                </li>
                                            </ul>
                                            <p>{'description write here'}</p>
                                            <div className="product-data-bottom">
                                                <span><img src={bannerRight} alt='' /></span>
                                                <h6><strong>{'user name'}</strong> </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button className="explore">
                            Load more Offerings
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SessionList;
