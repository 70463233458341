import React from 'react'
import { Link } from 'react-router-dom'

function SessionServiceCharge() {
  return (
    <div class="session step-2 where">
    <div class="header">
        <h1>Session or Service</h1>
        <div class="top-buttons">
            <Link to ='/creator/offering/sessionwhere'><button class="save-draft">Save to Draft</button></Link>
            <Link to='/creator/offering/sessiondiscovery'><button class="next">Next</button></Link>
        </div>
    </div>
    {/* <!-- active links --> */}
    <div class="active-links">
        <ul>
            <li>
                <span> 1 </span>
                <h5>Define</h5>
            </li>
             <li>
                <span> 2 </span>
                <h5>Timing</h5>
            </li>
             <li>
                <span> 3 </span>
                <h5>Location</h5>
            </li>
             <li>
                <span> 4 </span>
                <h5>Customize</h5>
            </li>
             <li>
                <span> 5 </span>
                <h5>Publish</h5>
            </li>
        </ul>
    </div>
    
    {/* <!-- Main --> */}
     <div class="main">
        <div class="row">
            <div class="col-sm-7">
                <div class="left">
                    <h1>Where</h1>
                       
        
                        <div class="size-range bottom">
                            <h6>How class will happen?</h6><img src="images/green-tick.svg" class="green-tick" alt=''/>
                            <div class="bottom-buttons">
                            <label class="radio-check3">
                                <input type="radio" checked="checked" name="radio"/>
                                <span class="checkmark">Online</span>
                              </label>
                              <label class="radio-check3">
                                <input type="radio" checked="checked" name="radio"/>
                                <span class="checkmark">In Person</span>
                              </label>
                              <label class="radio-check3">
                                <input type="radio" checked="checked" name="radio"/>
                                <span class="checkmark">Hybrid</span>
                              </label>
                              </div>
                            {/* <!--  --> */}
                               <h6>Where in person?</h6>
                            <div class="container-3">
                               
                                <div class="custom-radio">
                                    <input type="radio" id="radio1" name="customRadio"/>
                                    <label htmlFor="radio1">At my place</label>
                                </div>
                                <div class="custom-radio">
                                    <input type="radio" id="radio2" name="customRadio"/>
                                    <label htmlFor="radio2">I can travel</label>
                                </div>
                                <div class="custom-radio">
                                    <input type="radio" id="radio3" name="customRadio"/>
                                    <label htmlFor="radio3">Both</label>
                                </div>
                            </div>
                            <div class="add-address">
                                <h1>Add address</h1>
                                <p>Please note: Your precise address will be visible to users only after purchasing the offer, ensuring safety.</p>
                            </div>
                        </div>
                        <div class="switch-content">
                                    <div class="switch-button">
                                     <label class="switch">
                                         <input type="checkbox"/>
                                         <span class="slider"></span>
                                     </label>
                                     </div>
                                     <h3>Same start time</h3>
                                     </div>
                             <div class="how-you">
                                <p>How you charge</p>
                                <input type="text" placeholder="Enter travel price details"/>
                             </div>
                         
                </div>
            </div>
            <div class="col-sm-5">
                <div class="right">
                    <h1>Offering details</h1>
                  <ul>
                    <div class="detail-top">
                        <li><span>Category</span> <strong>Wellness</strong> </li>
                        <li><span>Type</span> <strong>Workshop</strong> </li>
                        <li><span>Tags</span> <strong>Health and Fitness, Meditation, Yoga</strong> </li>
                        <li><span>Group</span> <strong>Private session</strong> </li>
                    </div>
                    <div class="detail-middle">
                        <li><span>Repeat</span> <strong>Every Mo and Fr, up to 15 June</strong> </li>
                        <li><span>Time</span><strong></strong> </li>
                        <li><span>Session duration</span> <strong></strong> </li>
                    </div>
                    <div class="detail-bottom">
                        <li><span>How</span> <strong></strong> </li>
                        <li><span>Place</span> <strong></strong> </li>
                    </div>
                  </ul>
                </div>
            </div>
        </div>
     </div>
   </div>
  )
}

export default SessionServiceCharge