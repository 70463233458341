import React, { useEffect, useState } from 'react'
import coaching from '../../../assets/images/Coaching2.png'
// import yoga from '../../../assets/images/yoga.png'
// import fitness from '../../../assets/images/fitness.png'
// import drawing from '../../../assets/images/drawing.png'
// import accessories from '../../../assets/images/assessories.png'
// import eBook from '../../../assets/images/e-book.png'
// import cake from '../../../assets/images/cake.png'
// import bags from '../../../assets/images/bags.png'
// import UserSidebar from '../../../Layout/userSidebar'
import { Link } from 'react-router-dom'
import burger from '../../../assets/images/burger-menu.svg'
import UserSidebar from '../../../Layout/userSidebar'
// import leftArrow from '../../../assets/images/left-arrow_black.svg'
import { useNavigate } from 'react-router-dom';
import coaching1 from '../../../assets/images/coaching1.png'
import yoga from '../../../assets/images/Dance2.png'
import fitness from '../../../assets/images/photography2.png'
import drawing from '../../../assets/images/Yoga2.png'
import accessories from '../../../assets/images/Ebook2.png'
import eBook from '../../../assets/images/Paint2.png'
import cake from '../../../assets/images/Cake2.png'
import bags from '../../../assets/images/accessories2.png'
import { clearProductData, insertProductData } from '../../../Counter/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { insertOfferingData } from '../../../Counter/offeringSLice'


function NewOffering({ nextStep,formState,draftData,craetorId }) {
  const [classState, setClassState] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const [draftState,setDraftState] = useState(true)
  useEffect(() => {
    const handleClick = () => setClassState(false);

    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])
  useEffect(()=>{
    if(formState?.step !== null && formState?.step !== undefined){
      if(draftData===false){
        
        nextStep('','',formState?.step)
      }
    }
  },[])

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const values = {
    creator_id:craetorId
  }
  const handleNext = () => {
    dispatch(insertOfferingData(values))
    nextStep()
  }

  const createProduct = () => {
    dispatch(clearProductData())
   
    dispatch(insertProductData(values))
    navigate('/creator/offering/product')
  };

  return (
    <div>
      <UserSidebar classState={classState} />

        <div className='right-main'>
      <div class="new-offer">
          <div class="header">
            <h3> <button className="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button>Create New Offer</h3>
            <div class="top-buttons">
              {/* <Link to='/creator/home'><button class="cancel">Cancel</button></Link> */}
              {/* <Link to='/creator/offering/define'> */}
              {/* <button class="next" onClick={handleNext}>Next</button> */}
              {/* </Link> */}
            </div>
          </div>
          <div class="main main-css">
            <h2>What are you creating?</h2>
            <div class="row">
              {/* <!-- left side start --> */}
              <div class="col-sm-6">
                <div class="creating-main">
                  <div class="row">
                    <div class="col-sm-6 padding" >
                      <span className='border-r-top'><img src={coaching} alt='' />
                        <div class="img-text">Coaching</div>
                      </span>
                    </div>
                    <div class="col-sm-6 px-1" >
                      <span className='border-l-top'><img src={yoga} alt='' />
                        <div class="img-text">Dance Lessons</div></span>
                    </div>
                    <div class="col-sm-6 padding">
                      <span className='border-r-botmm'><img src={fitness} alt='' />
                        <div class="img-text">Photography</div> </span>
                    </div>
                    <div class="col-sm-6 px-1">
                      <span className='border-l-botmm'><img src={drawing} alt='' />
                        <div class="img-text">Yoga</div> </span>
                    </div>
                  </div>
                  <div class="creating-content">
                    <p>From  photography  to  therapy - sessions  and  services involve the  gift  of  your  time  and  presence.</p>
                    <button className='content-btn' onClick={handleNext}>Session or Service</button>
                  </div>
                </div>
              </div>
              {/* <!-- left side close --> */}
              <div class="col-sm-6">
                <div class="creating-main">
                  <div class="row">
                    <div class="col-sm-6 padding " >
                      <span className='border-r-top'><img src={accessories} alt='' />
                        <div class="img-text">Ebooks</div>
                      </span>
                    </div>
                    <div class="col-sm-6 px-1" >
                      <span className='border-l-top'><img src={eBook} alt='' />
                        <div class="img-text">Digital Art</div></span>
                    </div>
                    <div class="col-sm-6 padding" >
                      <span className='border-r-botmm'><img src={cake} alt='' />
                        <div class="img-text">Cakes</div> </span>
                    </div>
                    <div class="col-sm-6 px-1" >
                      <span className='border-l-botmm'><img src={bags} alt='' />
                        <div class="img-text">Accessories</div> </span>
                    </div>
                  </div>
                  <div class="creating-content">
                    <p>Sell  physical products  -  like  handcrafted jewellery or home baked  goodies,  sell digital  art,  ebooks and  more.</p>
                    <button className='content-btn' onClick={()=>createProduct()}>Product</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="back-button">
        <button className="button" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>
      </div> */}
        </div>

      </div>
    </div>
  )
}

export default NewOffering