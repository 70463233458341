import React, { useEffect, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar';
import burger from '../../../assets/images/burger-menu.svg';
import search from '../../../assets/images/search.svg'
import orderdata from '../../../assets/images/order-box.svg'
import orderimage from '../../../assets/images/order-image.svg'
import righttick from '../../../assets/images/right-tick-mark.svg'
import crosstick from '../../../assets/images/cross-tick-image.svg' 
import graycross from '../../../assets/images/gray-cross.svg'
import Modal from 'react-modal'
import AcceptModal from './acceptModal';
import DeleteModal from './DeleteModal';
import DeclineModals from './declineModals';
import doubblebox from '../../../assets/images/double-box.svg'
import setting from '../../../assets/images/setting-screw.svg'
import declineorder from '../../../assets/images/order-decline.svg'
import pickup from '../../../assets/images/pickup-img.svg'
import smiley from '../../../assets/images/sm-smile.svg'

function Processing() {
  const [classState, setClassState] = useState(false);
  const [isModal,setIsModal] = useState(false)
  const [isModalDelete,setIsModalDelete] = useState(false)
  const [declineModal,setDeclineModal] = useState(false)

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };


  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  return (
    <div className="users meal-suggestion creator-chat">
      <div className="creator-offering home min-height-pera">
        <UserSidebar classState={classState} />
        <div className="RightSideDiv right-main order-page">
          <div className='header'>

            <button className="burger-menu" onClick={handleSidebarClick}>
              <img src={burger} alt='burger menu' />
            </button>
            <h2>  My orders
            </h2>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='top-buttons'>
                <div className='input-container'>
                  <img src={search} alt="Add tag" />
                  <input
                    type="text"
                    placeholder='Find Offerings or Creators'
                  />
                </div>
              </div>

            </div>

            <div className='col-md-12'>
              <div class="creator-offering-tab">
                <ul>
                  <li><a className=''> New(0)</a> </li>
                  <li><a className='active'>Processing(0)</a> </li>
                  <li><a className=''>Cancelled(0)</a></li>
                  <li><a className=''>Completed(0)</a></li>
                  <li><a className=''>All(0)</a></li>
                </ul>
              </div>
            </div>
            {/* blank data */}
            {/* <div className='col-md-12'>
            <div className='fav-div-sec'>
                    <div className=''>
                        <div className='mb-2'>
                       <img src={orderdata} alt='' />
                       </div>
                        <p>You don't have any orders yet.</p>
                    </div>
                    </div>
            </div> */}
            {/* end */}

            {/* ------------------------------new tab data------------ */}
            <div className='col-md-12 '>
              <div className='my-order-data'>
              <div className='define-left'>
                <div className='row'>
                  <div className='col-sm-2'>
                    <span className='product-imgs'>
                    <img src={orderimage} alt="order-img" />
                    </span>
                  </div>
                  <div className='col-sm-6'>
                    <div class="order-info">
                      <h5>Necklace</h5>
                      <p>Quantity <span className='fw-bold ps-2'>2</span></p>
                      <p className='mb-2'><span className='fw-bold pe-2'>Order #</span>  232456789</p>
                      <p>Customer<span className='fw-bold ps-2'>Jenny Wilson</span></p>
                      <p className='pick-up' >Pick up from <span className='fw-bold ps-2'>123 Main Street, Anytown</span></p>
                      <p>Ordered<span className='fw-bold ps-2'>06 March 2024</span> </p>
                    
                      <p class="text-success">Payment successful</p>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                   <div className='order-details-list'>
                     <ul className='processing-list ps-0 text-end'>
                        {/* <li><button className='btn'><img src={doubblebox} alt="order-img" className='pe-2' />Ready for delivery</button> 
                         <div className='proces-status'>
                            <p>Change order status</p>
                         </div>
                         </li> */}
                          {/* <li><button className='btn order-pro'><img src={setting} alt="order-img" className='pe-2' />Order processing</button> 
                         <div className='proces-status'>
                            <p>Change order status</p>
                         </div>
                         </li> */}
                            <li>
                                <button className='btn order-dine text-start'><span><img src={declineorder} alt="order-img" className='px-2' /></span>Order declined</button> 
                             <div className='proces-status'>
                            <p>Change order status</p>
                         </div>
                         </li>
                     </ul>
                     {/* ------list on click button */}
                     <div className='process-on-click'>
                     <p>Change to</p>
                     <ul  className='processing-list ps-0'>
                     <li className='text-start mb-2'><button className='btn order-dine'><img src={declineorder} alt="order-img" className='pe-2' />Order declined</button></li>
                     <li className='text-start mb-2'><button className='btn order-pro'><img src={setting} alt="order-img" className='pe-2' />Order processing</button></li>
                     <li className='text-start mb-2'><button className='btn ready-to-pick'><img src={pickup} alt="order-img" className='pe-2' />Ready to pick up</button></li>
                     <li className='text-start mb-2'><button className='btn order-complete'><img src={smiley} alt="order-img" className='pe-2' />Completed</button></li>
                     </ul>
                     </div>
                    {/* ------end list on click button */}
                      <div className='rsn text-end'>
                        <p>Reason for decline: don’t have time</p>
                      </div>
                     </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
           
    </div>
    </div>
    </div>
    </div>
  )
}

export default Processing

