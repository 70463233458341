import React, { useEffect, useState } from 'react'
import UserSidebar from '../../../Layout/userSidebar'
import burger from '../../../assets/images/burger-menu.svg'
import 'react-datepicker/dist/react-datepicker.css';
import { datetime, RRule, RRuleSet } from 'rrule';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import leftArrow from '../../../assets/images/backarrow.svg'
import ActiveLinks from './activeLinks'
import OfferingDetails from './OfferingDetails'
import Calendar from 'react-calendar';
import add from '../../../assets/images/add-slot.svg'
import { insertOfferingData } from '../../../Counter/offeringSLice';

const daysOfWeek = [
  { label: 'Sunday', value: RRule.SU, name: 'SU' },
  { label: 'Monday', value: RRule.MO, name: 'MO' },
  { label: 'Tuesday', value: RRule.TU, name: 'TU' },
  { label: 'Wednesday', value: RRule.WE, name: 'WE' },
  { label: 'Thursday', value: RRule.TH, name: 'TH' },
  { label: 'Friday', value: RRule.FR, name: 'FR' },
  { label: 'Saturday', value: RRule.SA, name: 'SA' },
];


function AvailabilityOffer({ nextStep, prevStep, step, data,submitForm }) {

  console.log("data>>>>>>>>>>>>>>>>>>>>>>>>>>", data);

  const formState = useSelector((state) => state.offerCounter);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [classState, setClassState] = useState(false)
  const [offeringType, setOfferingType] = useState(data?.offeringType || '')
  const [sameTime, setSameTime] = useState(data?.sameTime || false)
  const [recurrences, setRecurrences] = useState([])
  // const [recurrences, setRecurrences] = useState(data?.recurrences || [])
  const [sessionType, setSessionType] = useState(data?.sessionType || '')
  const [specifyTime, setSpecifyTime] = useState(data?.specifyTime || '')
  const [value, onChange] = useState(new Date());
  const [availabilityPeriod, setAvailabilityPeriod] = useState(data?.availabilityPeriod || '')
  const [selectedDayLabel, setSelectedDayLabel] = useState([]);
  // const [selectedDayLabel, setSelectedDayLabel] = useState(data?.selectedDayLabel || []);
  const [dayLabel, setDayLabel] = useState([]);
  // const [dayLabel, setDayLabel] = useState(data?.dayLabel || []);
  const [key, setKey] = useState(0);
  const [moreDiv, setMoreDiv] = useState([])
  const [sessionRepeat, setSessionRepeat] = useState(data?.sessionRepeat || '')
  const [typeValue, setTypeValue] = useState(data?.typeValue || {})
  const [monthRepeatValue, setMonthRepeatValue] = useState('')
  const [sessionDuration, setSessionDuration] = useState(data?.sessionDuration || '')
  const [sameTimeDuratioon, setSameTimeDuration] = useState({ startTime: '', endTime: '' })
  const [sessionMultipleType, setSessionMultipleType] = useState(data?.sessionMultipleType || '')
  const [sessionNumber, setSessionNumber] = useState(data?.sessionPackage || '')
  const [timeFrame, setTimeFrame] = useState(data?.timeFrame || { number: '', months: '' })
  const [errors, setErrors] = useState({});
  const [markedDates, setMarkedDates] = useState([]);
  const [addDates,setAddDates] = useState([])
  console.log("sameTimeDuratioon", sameTimeDuratioon)
  console.log("selectedDayLabel", selectedDayLabel)
  console.log("typeValue", typeValue)
  console.log("recurrencesrecurrences", recurrences)
  console.log("addDatesaddDates",addDates)

  useEffect(() => {
    const handleClick = () => setClassState(false);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  const handleSidebarClick = (event) => {
    event.stopPropagation();
    setClassState(!classState);
  };

  const validateForm = () => {
    let formErrors = {};

    if (!sessionType) {
      formErrors.sessionType = 'Please select a session type.';
    }

    if (sessionType === 'multiple_session' && !sessionMultipleType) {
      formErrors.sessionMultipleType = 'Please select a package type.';
    }

    if (sessionType === 'multiple_session' && !sessionNumber) {
      formErrors.sessionNumber = 'Please specify the number of sessions.';
    }

    if (sessionMultipleType && sessionMultipleType!== 'custom' && (timeFrame.number === '' || timeFrame.months === '')) {
      formErrors.timeFrame = 'Please specify the timeframe of the package.';
    }

    if ((data?.sessionTypes!== 'group_session' && !specifyTime)) {
      formErrors.specifyTime = 'Please specify the time when it will happen.';
    }

    if (specifyTime === 'exact' && !sessionRepeat) {
      formErrors.sessionRepeat = 'Please specify how often the session should repeat.';
    }
    if(availabilityPeriod === '' && sessionRepeat !== 'one'){
      formErrors.availabilityPeriod = 'Please select the availability period';
    }

    if (availabilityPeriod === 'date' && !typeValue.date) {
      formErrors.availabilityPeriod = 'Please specify the end date of your availability period.';
    }

    if (availabilityPeriod === 'after' && !typeValue.after) {
      formErrors.availabilityPeriod = 'Please specify the number of times after which the period ends.';
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const isValidForm = () => {
    if (sameTime) {
      return sameTimeDuratioon.startTime && sameTimeDuratioon.endTime;
    } else {
      return selectedDayLabel.every((dates, index) => {
        return dates[index]?.startTime && dates[index]?.endTime;
      });
    }
  };
  

//   const generateRecurrenceStrings = (date, dayOfWeek, isMarked, type) => {
//     // if (!isValidForm()) {
//     //   alert("Please fill in all required fields before generating the recurrence strings.");
//     //   return;
//     // }
//     const startDate = new Date(date).getDate();
//       const fullyear = new Date(date).getFullYear();
//       const month = new Date(date).getMonth()+1;
//     setRecurrences([])
//     const dayMap = {
//       Sunday: RRule.SU,
//       Monday: RRule.MO,
//       Tuesday: RRule.TU,
//       Wednesday: RRule.WE,
//       Thursday: RRule.TH,
//       Friday: RRule.FR,
//       Saturday: RRule.SA,
//     };

//     const newRecurrenceRules = [];
//     let weekDays = [];

//     selectedDayLabel.forEach((x) => {
//       let ruleOptions;
//       if (offeringType === 'Monthly') {
//         ruleOptions = {
//           freq: RRule.MONTHLY,
//           dtstart: new Date(),
//           bysetpos: monthRepeatValue,  //formState?.position,
//           byweekday: [dayMap[x?.label]],
//         };
//       } else {
//         ruleOptions = {
//           freq: RRule.WEEKLY,
//           dtstart: new Date(),
//           byweekday: [dayMap[x?.label]],
//           // count: 14,
//         };
//       }

//       if (availabilityPeriod === 'date') {
//         ruleOptions.until = new Date(typeValue?.date);
//       } else if (availabilityPeriod === 'after') {
//         ruleOptions.count = typeValue?.after;
//       }
//       if(sessionRepeat === 'one'){
//         ruleOptions.count = 1;
//       }

//       const rru = new RRuleSet();
//   rru.rrule(new RRule(ruleOptions));
//   const rdate = new Date(fullyear, month - 1, startDate, 0, 0); 
// if(x?.name === dayOfWeek){
//   if (!isMarked && isMarked !== undefined) {
//     rru.rdate(rdate);
//   } else if (isMarked && isMarked !== undefined) {
//     rru.exdate(rdate);
//   }
// }

//       let obj;
//       if (sameTime ?
//         obj = {
//           dayOfWeek: x?.name,
//           sessionDuration: 60,
//           timeSlots: [sameTimeDuratioon],
//           rruleString: rru.toString(),
//         }
//         :
//         obj = {
//           dayOfWeek: x?.name,
//           sessionDuration: 60,
//           timeSlots: x?.dates,
//           rruleString: rru.toString(),
//         }
//       )
//         newRecurrenceRules.push(obj);
//     });
//     setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
//     updateMarkedDates(newRecurrenceRules);
//     console.log("recurrenceString", newRecurrenceRules);
//   };

  const generateRecurrenceStrings = (date, dayOfWeek, isMarked, type) => {
    const startDate = new Date(date).getDate();
    const fullyear = new Date(date).getFullYear();
    const month = new Date(date).getMonth() ;
    const dayName = new Date(date).getDay();
   

    setRecurrences([]);

    const dayMap = {
      Sunday: RRule.SU,
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
    };

    const newRecurrenceRules = [];

    selectedDayLabel.forEach((x) => {
      let ruleOptions;
      if (offeringType === 'Monthly') {
        ruleOptions = {
          freq: RRule.MONTHLY,
          dtstart: new Date(),
          bysetpos: monthRepeatValue,
          byweekday: [dayMap[x?.label]],
        };
      } else {
        ruleOptions = {
          freq: RRule.WEEKLY,
          dtstart: new Date(),
          byweekday: [dayMap[x?.label]],
        };
      }

      if (availabilityPeriod === 'date') {
        ruleOptions.until = new Date(typeValue?.date);
      } else if (availabilityPeriod === 'after') {
        ruleOptions.count = typeValue?.after;
      }

      if (sessionRepeat === 'one') {
        ruleOptions.count = 1;
      }

      const rru = new RRuleSet();
      rru.rrule(new RRule(ruleOptions));
      const rdate = new Date(fullyear, month - 1, startDate, 0, 0); 
      if (x?.name === dayOfWeek) {
        if (!isMarked && isMarked !== undefined) {
          rru.rdate(rdate);
        } else if (isMarked && isMarked !== undefined) {
          rru.exdate(rdate);
        }
      }

      // Create a new recurrence rule for a new date
      // const isNewDate =  newRecurrenceRules.length>0 && !newRecurrenceRules.some(rule => 
      //   rule.timeSlots.some(slot => new Date(slot).getTime() === new Date(date).getTime())
      // );

     

      let obj = sameTime
        ? {
            dayOfWeek: x?.name,
            sessionDuration: 60,
            timeSlots: [sameTimeDuratioon],
            rruleString: rru.toString(),
          }
        : {
            dayOfWeek: x?.name,
            sessionDuration: 60,
            timeSlots: x?.dates,
            rruleString: rru.toString(),
          };

      newRecurrenceRules.push(obj);
    });
    console.log('newdate',date)
    if (isMarked === false || isMarked === true) {
      let newRuleStrings;
      // addDates.map()
      let newRule ;
      // = {
      //   dayOfWeek: dayOfWeek,
      //   sessionDuration: 60,
      //   dtstart: new Date(date),
      //   timeSlots: [date],
      //   rruleString: rru.toString(),
      // };
      const validDate = new Date(date);
      if (isNaN(validDate)) {
          console.error("Invalid date provided:", date);
          return; // Exit the function if the date is invalid
      }
      const getDayNum = validDate.getDay();
      const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const getDay = dayNames[getDayNum];
      if (offeringType === 'Monthly') {
        newRule = {
          freq: RRule.MONTHLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart: validDate,
          bysetpos: monthRepeatValue,
          // byweekday: [dayMap[getDay]],
          count:1,
        };
      } else {
        newRule = {
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(fullyear, month, startDate, 0, 0)),
          // dtstart: validDate,
          // byweekday: [dayMap[getDay]],
          count:1
        };
      }
      const rrus = new RRuleSet();
    rrus.rrule(new RRule(newRule));
let newRuleString = {
  dayOfWeek: dayOfWeek,
  sessionDuration: 60,
  timeSlots: [[{ startTime: '10:00', endTime: '12:00' }]],
  rruleString: rrus.toString(),
};
// let newRRuleString = addDates.length>0 ? addDates : []
// console.log("newRuleString>>>>>>>",newRuleString)
// setAddDates(prev => {
//   if (!prev.includes(newRuleString)) {
//     return [...prev, newRuleString];
//   }
//   return prev;
// });
// newRRuleString.push(newRuleString);
//       newRecurrenceRules.push(newRRuleString);
let newRRuleString = addDates.length > 0 ? [...addDates] :[];

console.log("newRRuleString before adding:", newRRuleString);

// Ensure the date is unique before adding to addDates
// setAddDates(prev => {
//   if (!prev.includes(newRuleString)) {
//     return [...prev, newRuleString];
//   }
//   return prev;
// });

// // Only push to newRRuleString if it does not already contain newRuleString
// if (!newRRuleString.includes(newRuleString)) {
//   newRRuleString.push(newRuleString);
// }
setAddDates(prev => {
  // Create a new array to avoid modifying the previous state directly
  const updatedDates = [...prev];

  // Check if newRuleString is already in addDates
  if (!updatedDates.includes(newRuleString)) {
    // If it doesn't exist, add it to updatedDates
    updatedDates.push(newRuleString);
  }
  
  // Remove duplicates by converting to a Set and back to an array
  return Array.from(new Set(updatedDates));
});

// Ensure that newRRuleString maintains uniqueness
newRRuleString = Array.from(new Set([
  ...newRRuleString, 
  ...(newRRuleString.includes(newRuleString) 
    ? [] 
    : [newRuleString]
  )
]));

console.log("newRRuleString after adding:", newRRuleString);

// Ensure newRecurrenceRules contains unique values
const uniqueNewRRuleString = newRRuleString.filter((item, index, self) =>
  index === self.findIndex(t => t === item)
);

// newRecurrenceRules.push(...uniqueNewRRuleString);
// uniqueNewRRuleString && uniqueNewRRuleString.length>0 && uniqueNewRRuleString.map((x,index)=>{
newRRuleString && newRRuleString.length>0 && newRRuleString.map((x,index)=>{
  newRecurrenceRules.push(x);
})
    }
    setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
    updateMarkedDates(newRecurrenceRules);
    console.log("recurrenceString", newRecurrenceRules);
};


  const generateRecurrenceString = (date, dayOfWeek, isMarked, type) => {
    const dayMap = {
      Sunday: RRule.SU,
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
    };
    const dayMaps = {
      SUN: RRule.SU,
      MON: RRule.MO,
      TUE: RRule.TU,
      WED: RRule.WE,
      THU: RRule.TH,
      FRI: RRule.FR,
      SAT: RRule.SA,
    };

    const newRecurrenceRules = [];

    if (type == 'single') {
      const newDate = new Date(date);
      //   // newDate.setDate(newDate.getDate() - 1);
      newDate.setHours(0, 0, 0, 0);
      const startDate = new Date(date).getDate();
      const fullyear = new Date(date).getFullYear();
      const month = new Date(date).getMonth();
      const ruleSet = new RRuleSet();
      ruleSet.rrule(
        new RRule({
          freq: RRule.WEEKLY,
          dtstart: newDate,
          byweekday: [dayMaps[dayOfWeek]],
          count: 1,
        })
      )
      // const ruleOptions = {
      //   freq: RRule.WEEKLY,
      //   dtstart: newDate,
      //   byweekday: [dayMaps[dayOfWeek]],
      //   count: 1,
      // };

      // let rrule = new RRule(ruleOptions);
      // ruleSet.rrule(rrule);
      if (!isMarked) {
        ruleSet.rdate(datetime(fullyear, month, startDate, 0, 0));
      } else {
        ruleSet.exdate(datetime(fullyear, month, startDate, 0, 0));
      }

      // const allDates = ruleSet.all()s
      return {
        rruleString: ruleSet.toString(),
        exdates: ruleSet.exdates().map(date => date.toISOString()), // Return exdates separately
      };
      // return rrule.toString();
    }
    else {

      selectedDayLabel.forEach((x) => {
        const dayOfWeek = dayMap[x?.label];
        const newDate = new Date();
        newDate.setHours(0, 0, 0, 0);

        let ruleOptions = {};
        if (offeringType === 'Monthly') {
          ruleOptions = {
            freq: RRule.MONTHLY,
            dtstart: newDate,
            bysetpos: formState?.position,
            byweekday: [dayOfWeek],
          };
        } else {
          ruleOptions = {
            freq: RRule.WEEKLY,
            dtstart: newDate,
            byweekday: [dayOfWeek],
            count: 14,
          };
        }

        const rrule = new RRule(ruleOptions);
        const ruleSet = new RRuleSet();
        ruleSet.rrule(rrule);

        // Handle exclusions or markings if necessary
        // if (formState?.isMarked !== undefined) {
        //   const isMarked = formState.isMarked; // Adjust based on your application logic
        //   if (isMarked) {
        //     ruleSet.exdate(newDate);
        //   } else {
        //     ruleSet.rdate(newDate);
        //   }
        // }

        const obj = {
          dayOfWeek: x?.name,
          sessionDuration: 60, // Or use x?.duration if available
          startTime: x?.startTime,
          endTime: x?.endTime,
          rruleString: rrule.toString(),
          exdates: ruleSet.exdates().map(date => date.toISOString()), // Return exdates separately
        };

        newRecurrenceRules.push(obj);
      });
    }

    setRecurrences((prev) => [...prev, ...newRecurrenceRules]);
    console.log("recurrenceString", newRecurrenceRules);
  };

  const handleDayChange = (day, label, name) => {
    if (sessionRepeat === 'one') {
      setDayLabel([label]);
      setSelectedDayLabel([{ label: label, name: name, dates: [{ startTime: '', endTime: '' }] }])
    }
    if (sessionRepeat === '' || sessionRepeat === 'daily' || sessionRepeat === 'weekly') {
      setDayLabel(prevDays =>
        prevDays.includes(label)
          ? prevDays.filter(d => d !== label)
          : [...prevDays, label]
      );

      setSelectedDayLabel(prevDays => {
        const labelExists = prevDays.some(d => d.label === label);
        if (labelExists) {
          return prevDays.filter(d => d?.label !== label);
        } else {
          return [...prevDays, { label: label, name: name, dates: [{ startTime: '', endTime: '' }] }];
        }
      });
    }
  };

  const parseRRule = (rruleString) => {
    console.log('rruleString',rruleString)
    if (typeof rruleString !== 'string') {
      console.error('Expected a string but got:', typeof rruleString, rruleString);
      return []; // Return an empty array if rruleString is not a string
    }
    // Split the rruleString into lines to handle RDATE separately
    const lines = rruleString.split('\n');
    let rruleLine = '';
    let rdates = [];
    let dtstart = [];

    // Separate the RRULE line from RDATE lines
    // lines.forEach(line => {
    //   if (line.startsWith('RRULE')) {
    //     rruleLine = line;
    //   } else if (line.startsWith('RDATE')) {
    //     const rdateLine = line.split(':')[1];
    //     rdates = rdateLine.split(',').map(dateStr => new Date(dateStr));
    //   }
   
    // });
    // lines.forEach(line => {
    //   if (line.startsWith('RRULE')) {
    //     rruleLine = line.split(':')[1]; // Extract the RRULE part after ':'
    //   } else if (line.startsWith('DTSTART')) {
    //     const dtstart = line.split(':')[1]; // Extract the DTSTART part after ':'
    //     const dtstartDate = dtstart.split('T')[0]; // Get the date part before 'T'
    //     rruleLine += `;DTSTART=${dtstartDate}`; // Append DTSTART date to RRULE
    //   } else if (line.startsWith('RDATE')) {
    //     const rdateLine = line.split(':')[1];
    //     rdates = rdateLine.split(',').map(dateStr => new Date(dateStr));
    //   }
    // });
    for (let i = lines.length - 1; i >= 0; i--) {
      const line = lines[i];
      
      
      if (line.startsWith('RRULE')) {
        rruleLine = line.split(':')[1]; // Extract the RRULE part after ':'
      } else if (line.startsWith('DTSTART')) {
        const dtstart = line.split(':')[1]; // Extract the DTSTART part after ':'
        const dtstartDate = dtstart.split('T')[0]; // Get the date part before 'T'
        rruleLine += `;DTSTART=${dtstartDate}`; // Append DTSTART date to RRULE
      } else if (line.startsWith('RDATE')) {
        const rdateLine = line.split(':')[1];
        rdates = rdateLine.split(',').map(dateStr => new Date(dateStr));
      }
    }
    
    // Parse the RRULE string
    console.log('rruleLine',rruleLine)
    // const rruleLine1= 'FREQ=WEEKLY;COUNT=1;DTSTART=20240831'
    const rule = RRule.fromString(rruleLine);
    console.log('rulelllll',rule)
    const rruleDates = rule.all(); // Get all dates generated by the RRULE
    // Combine the RRULE dates with the RDATEs
    // const rruleDates = rule.after(rule, true); // Use `true` to include the clickedDate itself if it matches
    
    console.log("rruleDatesrruleDates",rruleDates)


    const allDates = [...rruleDates, ...rdates];
console.log("allDates",allDates)
    // Format the dates as YYYY-MM-DD
    const formattedDate = allDates.map(date => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getUTCDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    });

    console.log("formattedDate", formattedDate);
    return formattedDate;
  };

  // const parseRRule = (rruleString) => {
  //   const { rrulestr } = require('rrule'); // Ensure the rrule package is correctly imported
  
  //   const rule = rrulestr(rruleString); // Parse the rrule string
  //   const dates = rule.all(); // Get all occurrences
  //   console.log("formattedDates",dates)
  //   return dates;
  // };
  


  // const markedDates = recurrences.length > 0
  //   ? recurrences.reduce((acc, occurrence) => {
  //     const dates = parseRRule(occurrence.rruleString);

  //     return [...acc, ...dates];
  //   }, [])
  //   : [];
  const updateMarkedDates = (updatedRecurrences) => {
    console.log("updatedRecurrences",updatedRecurrences)
    const newMarkedDates = updatedRecurrences.reduce((acc, occurrence) => {
      console.log('ifffffff');
      // const abc= 'DTSTART:20240831T183000Z\nRRULE:FREQ=WEEKLY;COUNT=1';
      const dates = parseRRule(occurrence?.rruleString);
      console.log("datesrecurrence",dates)
      return [...acc, ...dates];
    }, []);
    console.log("newMarkedDatesnewMarkedDates",newMarkedDates);
    
    setMarkedDates(newMarkedDates);
  };


  const handleDateClick = (date, isMarked) => {
    const formattedDate = date.toLocaleDateString('en-CA'); // Format as YYYY-MM-DD

    let dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase(); // Get the day of the week in 'SU', 'MO', etc.
    dayOfWeek = dayOfWeek.slice(0, 2);
    // if (isMarked) {
    //   // Remove recurrence
    //   setRecurrences(prev => prev.filter(rec => !rec.rruleString.includes(dayOfWeek)));
    // } else {
    // Add recurrence
    // setAddDates(prev => {
    //   if (!prev.includes(formattedDate)) {
    //     return [...prev, formattedDate];
    //   }
    //   return prev;
    // });
    const newRRuleString = generateRecurrenceStrings(date, dayOfWeek, isMarked, 'single');
    console.log('newRRuleString', newRRuleString)
    const newRecurrence = {
      dayOfWeek: dayOfWeek.slice(0, 2),
      sessionDuration: 60,
      startTime: undefined,
      endTime: undefined,
      rruleString: newRRuleString?.rruleString,
    };
    console.log('newRecurrence', newRecurrence)



    // setRecurrences(prev => [...prev, newRecurrence]);
    // updateMarkedDates(updatedRecurrences);
    setKey(prevKey => prevKey + 1);
  };

  const handleMorediv = (label) => {
    setMoreDiv((prevMoreDiv) => {
      const existingLabel = prevMoreDiv.find((item) => item.label === label);
      if (existingLabel) {
        return prevMoreDiv.map((item) =>
          item.label === label ? { ...item, count: item.count + 1 } : item
        );
      } else {
        return [...prevMoreDiv, { label: label, count: 1 }];
      }
    });
  }

  const handleTImeSession = (type) => {
    setSessionRepeat(type)
    setDayLabel([])
    setSelectedDayLabel([])
    if (type === 'weekday') {
      setDayLabel(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'])
      setSelectedDayLabel([
        { label: 'Monday', name: 'MO', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Tuesday', name: 'TU', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Wednesday', name: 'WE', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Thursday', name: 'TH', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Friday', name: 'FR', dates: [{ startTime: '', endTime: '' }] }
      ])
    }
    if (type === 'weekend') {
      setDayLabel(['Saturday', 'Sunday'])
      setSelectedDayLabel([
        { label: 'Saturday', name: 'SA', dates: [{ startTime: '', endTime: '' }] },
        { label: 'Sunday', name: 'SU', dates: [{ startTime: '', endTime: '' }] }
      ])
    }
  }

  const handleChangeData = (type, e) => {
    setAvailabilityPeriod(type)
    setTypeValue({ [type]: e?.target?.value })
  }
  const handleInputChange = (index, field, value, typenumber) => {
    const updatedDays = [...selectedDayLabel];
    // if (!updatedDays[index].dates) {
    //   // updatedDays[index].dates = [{}, {}];
    //   updatedDays[index].dates = [];
    // }
    // while (updatedDays[index].dates.length < 2) {
    //   updatedDays[index].dates.push({});
    // }
    // Ensure the dates array has at least one object
    if (typenumber >= 1 && updatedDays[index].dates.length < 1) {
      updatedDays[index].dates.push({});
    }

    // Only create the second object if typenumber is 2
    if (typenumber === 2 && updatedDays[index].dates.length < 2) {
      updatedDays[index].dates.push({});
    }

    let targetDateObject = updatedDays[index].dates[typenumber - 1];
    targetDateObject[field] = value;
    setSelectedDayLabel(updatedDays);
  };


  const handleNext = (type) => {
    const values = {
      sessionType: sessionType,
      specifyTime: specifyTime,
      sessionRepeat: sessionRepeat,
      recurrences: recurrences,
      availabilityPeriod: availabilityPeriod,
      typeValue: typeValue,
      // offeringType: offeringType,
      // offeringTime: timeStatus,
      // recurrenceRule: newRecurrenceRules,
      // SelectedDayss: selectedDayss,
      classMode: data?.classMode || 'online',
      sameTime: sameTime,
      sessionPackage: sessionNumber,
      timeFrame: timeFrame,
      sessionMultipleType: sessionMultipleType,
      selectedDayLabel:selectedDayLabel,
      dayLabel:dayLabel,
      sessionDuration:sessionDuration
    };
    dispatch(insertOfferingData(values))
    if(validateForm()){
      // if (!isValidForm()) {
      //   alert("Please fill in all required fields before generating the recurrence strings.");
      //   return;
      // }
      if(type=== 'draft'){
        submitForm('draft',values)
    }
    else{
        nextStep();
    }
    }
  }


  return (
    <div>
      <UserSidebar classState={classState} />
      <div className='right-main'>
        <div className="session step-2">
          <div className="header">
            <h1><button class="burger-menu" onClick={handleSidebarClick}><img src={burger} alt='' /></button> Session or Service</h1>
            <div className="top-buttons">
              <button className="save-draft" onClick={()=>handleNext('draft')}>Save to Draft</button>
              <button className="next" onClick={handleNext}>Next</button>
            </div>
          </div>
          <div class="active-links">

            <button className="back" onClick={() => prevStep()}><img src={leftArrow} alt='' /> </button>

            <ActiveLinks step={step} />
          </div>




          <div className="main">
            <div className="row">
            {/* {errors.sessionType && <div className="error">{errors.sessionType}</div>}
      {errors.sessionMultipleType && <div className="error">{errors.sessionMultipleType}</div>}
      {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
      {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}
      {errors.specifyTime && <div className="error">{errors.specifyTime}</div>}
      {errors.sessionRepeat && <div className="error">{errors.sessionRepeat}</div>}
      {errors.availabilityPeriod && <div className="error">{errors.availabilityPeriod}</div>} */}
              <div className="col-sm-7">
                <div className='define-left'>
                  <div className='define-top'>
                    <h3>How would you like to package your offering?</h3>
                    <div className='session'>
                      <label class="session-check" onClick={() => setSessionType('single_session')}>
                        <input type="radio" checked={sessionType === 'single_session'} name="session-type" onChange={() => setSessionType('single_session')} />
                        <span class="checkmark"></span>
                      </label>
                      <div className='session-check-text' >
                        <h4>Single Session </h4>
                        <p>Allow users to book one session at a time.</p>
                      </div>
                    </div>

                    <div className='session'>
                      <label class="session-check" onClick={() => setSessionType('multiple_session')}>
                        <input type="radio" checked={sessionType === 'multiple_session'} name="session-type" onChange={() => setSessionType('multiple_session')} />
                        <span class="checkmark"></span>
                      </label>
                      <div className='session-check-text' >
                        <h4>Multiple Sessions</h4>
                        <p>Offer a package of sessions (e.g., weekly, monthly, or a set number of sessions).</p>
                      </div>
                    </div>
                    {errors.sessionType && <div className="error">{errors.sessionType}</div>}
                  </div>

                  {sessionType === 'multiple_session' &&
                    <div className='define-top'>
                      <h3>What type package do you offer</h3>
                      <div className='session'>
                        <label class="session-check" onClick={() => setSessionMultipleType('weekly')}>
                          <input type="radio" checked={sessionMultipleType === 'weekly'} name="mulsession-type" onChange={() => setSessionMultipleType('weekly')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Weekly Package </h4>
                          {sessionMultipleType === 'weekly' &&
                            <>
                              <p>Set a number of sessions per week.</p>
                              <div>
                                <h3>Specify the number of sessions per week.</h3>
                                <input type="text" placeholder='2 Times' value={sessionNumber} onChange={(e) => setSessionNumber(e?.target?.value)} />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                              </div>
                              <div>
                                <h3>Specify timeframe of the package.</h3>
                                <input type="text" placeholder='2' value={timeFrame?.number} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))} />
                                <input type="text" placeholder='Weeks' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} />
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}
                              </div>
                            </>
                          }
                        </div>
                      </div>

                      <div className='session'>
                        <label class="session-check" onClick={() => setSessionMultipleType('monthly')}>
                          <input type="radio" checked={sessionMultipleType === 'monthly'} name="mulsession-type" onChange={() => setSessionMultipleType('monthly')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Monthly Package</h4>
                          {sessionMultipleType === 'monthly' &&
                            <>
                              <p>Provide session over a month.</p>
                              <div>
                                <h3>Specify the number of sessions per week.</h3>
                                <input type="text" placeholder='2 Times' value={sessionNumber} onChange={(e) => setSessionNumber(e?.target?.value)} />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                              </div>
                              <div>
                                <h3>Specify timeframe of the package.</h3>
                                <input type="text" placeholder='2' value={timeFrame?.number} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, number: e.target.value }))} />
                                <input type="text" placeholder='Months' value={timeFrame?.months} onChange={(e) => setTimeFrame(prevState => ({ ...prevState, months: e.target.value }))} />
                                {errors.timeFrame && <div className="error">{errors.timeFrame}</div>}

                              </div>
                            </>
                          }
                        </div>
                      </div>
                      {data?.sessionTypes !== 'group_session' &&
                      <div className='session'>
                        <label class="session-check" onClick={() => setSessionMultipleType('custom')}>
                          <input type="radio" checked={sessionMultipleType === 'custom'} name="mulsession-type" onChange={() => setSessionMultipleType('custom')} />
                          <span class="checkmark"></span>
                        </label>
                        <div className='session-check-text' >
                          <h4>Custom Package</h4>
                          {sessionMultipleType === 'custom' &&
                            <>
                              <p class="py-2">Create a package with a specific number of sessions..</p>
                              <div>
                                <h3>Specify number of session in the package.</h3>
                                <input type="text" placeholder='2 Times' value={sessionNumber} onChange={(e) => setSessionNumber(e?.target?.value)} />
                                {errors.sessionNumber && <div className="error">{errors.sessionNumber}</div>}
                              </div>
                            </>
                          }
                        </div>
                      </div>}
                      {errors.sessionMultipleType && <div className="error">{errors.sessionMultipleType}</div>}
                    </div>
                  }


                  <div className='define-top time'>
                    {sessionType !== '' && data?.sessionTypes !== 'group_session' &&
                      <>
                        <h3>Time when will it happen?</h3>
                        <div className='session'>
                          <label class="session-check" onClick={() => setSpecifyTime('exact')}>
                            <input type="radio" checked={specifyTime === 'exact'} name="specify_time"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text'>
                            <h4>Specify exact time</h4>
                            <p>Set a fixed time for each session (e.g., Mon at 11:00 AM, Fri at 9:00 AM). This option is suitable for both individual and group sessions.</p>
                          </div>
                        </div>
                        <div className='session'>
                          <label class="session-check" onClick={() => { setSpecifyTime('availability'); setSessionRepeat('') }}>
                            <input type="radio" checked={specifyTime === 'availability'} name="specify_time"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text' >
                            <h4>Share availability </h4>
                            <p>You share your availability so users can select a convenient slot.</p>
                          </div>
                        </div>
                        {errors.specifyTime && <div className="error">{errors.specifyTime}</div>}
                      </>
                    }
                    {(specifyTime === 'exact' || data?.sessionTypes === 'group_session') && (
                      <div className='session-week'>
                        <h3>How often should this session repeat?</h3>
                        <ul>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("one")}>
                              <input type="radio" checked={sessionRepeat === 'one'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>One-time session</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("daily")}>
                              <input type="radio" checked={sessionRepeat === 'daily'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Daily</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("weekly")}>
                              <input type="radio" checked={sessionRepeat === 'weekly'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Weekly</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("monthly")}>
                              <input type="radio" checked={sessionRepeat === 'monthly'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Monthly</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("weekday")}>
                              <input type="radio" checked={sessionRepeat === 'weekday'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Every weekday(from Monday to Friday)</h4>
                          </li>
                          <li >
                            <label class="session-check" onClick={() => handleTImeSession("weekend")} >
                              <input type="radio" checked={sessionRepeat === 'weekend'} name="sessiontime"></input>
                              <span class="checkmark"></span>
                            </label>
                            <h4>Every weekends(Saturday and Sunday)</h4>
                          </li>

                        </ul>
                        {errors.sessionRepeat && <div className="error">{errors.sessionRepeat}</div>}
                      </div>
                    )
                    }


                  </div>


                  <>
                    <div className='select-day'>
                      {((specifyTime === 'exact' && sessionRepeat !== '') || specifyTime === 'availability' || data?.sessionTypes ==='group_session') && (
                        <>
                          {sessionRepeat === 'monthly'
                            ?
                            <>
                              <h3>Select Day to repeat</h3>
                              <input type='text' onChange={(e) => setMonthRepeatValue(e?.target?.value)} />
                            </>
                            :
                            <h3>{(specifyTime === 'exact' || data?.sessionTypes === 'group_session') ? 'Select Recurring Days' : 'Select Days'}</h3>
                          }
                          <ul>
                            {daysOfWeek.map(day => (
                              <li key={day?.value} onClick={() => handleDayChange(day.value, day?.label, day?.name)} className={dayLabel.includes(day?.label) && 'active'} >
                                {day.name}
                              </li>
                            ))}
                          </ul>
                        </>
                      )
                      }
                    </div>

                    {(specifyTime !== '' || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== ''))) &&
                      <div className='define-top time period'>
                        <h3>{(specifyTime === 'exact' || data?.sessionTypes === 'group_session' )? 'Recurred Period Duration' : 'Availability Period'}</h3>
                        <p>Please specify when your availability period ends.</p>

                        <div className='session margin' onClick={() => setAvailabilityPeriod('indefinitely')}>
                          <label class="session-check">
                            <input type="radio" checked={availabilityPeriod === 'indefinitely'} name="availability_periods"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text'>
                            <h4>Indefinitely</h4>
                          </div>
                        </div>
                        <div className='session margin mar-bott' onClick={() => setAvailabilityPeriod('date')}>
                          <label class="session-check">
                            <input type="radio" checked={availabilityPeriod === 'date'} name="availability_periods"></input>
                            <span class="checkmark"></span>
                          </label>
                          <div className='session-check-text until'>
                            <h4>Until a specific date</h4>
                            <input type='date' className='form-control' value={typeValue?.date} onChange={(e) => handleChangeData('date', e)} />
                          </div>
                        </div>
                        {(specifyTime === 'exact' || (data?.sessionTypes === 'group_session' && (sessionRepeat !== 'one' && sessionRepeat !== '')) )&&
                          <div className='session margin mar-bott' onClick={() => setAvailabilityPeriod('after')}>
                            <label class="session-check">
                              <input type="radio" checked={availabilityPeriod === 'after'} name="availability_periods"></input>
                              <span class="checkmark"></span>
                            </label>
                            <div className='session-check-text until'>
                              <h4>After</h4>
                              <input type='text' className='form-control' placeholder='12 times' value={typeValue?.after} onChange={(e) => handleChangeData('after', e)} />
                            </div>
                          </div>
                        }
                      {errors.availabilityPeriod && <div className="error">{errors.availabilityPeriod}</div>}
                      </div>
                    }
                  </>
                </div>

                {selectedDayLabel && selectedDayLabel.length > 0 &&
                  <div className='define-left'>
                    <div className='define-top'>
                      <h3>Available time slot</h3>
                      <div className="switch-content">
                        <div className="switch-button">
                          <label className="switch">
                            <input type="checkbox" checked={sameTime} onClick={() => setSameTime(!sameTime)} />
                            <span className="slider"></span>
                          </label>
                        </div>
                        <h3>Same for all days</h3>
                      </div>
                    </div>



                    <div className='time-slot'>
                      {sameTime ?
                        <div className='row'>
                          <div className='col-sm-5'>
                            <div className='form-group'>
                              <label>From</label>
                              <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, startTime: e.target.value }))} required />
                            </div>
                          </div>
                          <div className='col-sm-5'>
                            <div className='form-group'>
                              <label>To</label>
                              <input type='time' className='form-control' onChange={(e) => setSameTimeDuration(prevState => ({ ...prevState, endTime: e.target.value }))} required/>
                            </div>
                          </div>
                          <div className='col-sm-2'>
                            <div className='form-group'>
                              <button className='add-btn'><img src={add} alt='' /></button>
                            </div>

                          </div>
                        </div>
                        :
                        <>
                          {selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                            return (
                              <div key={index}>
                                <h4>{x?.label}</h4>
                                <div className='row'>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>From</label>
                                      <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 1)} required/>
                                    </div>
                                  </div>
                                  <div className='col-sm-5'>
                                    <div className='form-group'>
                                      <label>To</label>
                                      <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 1)} required />
                                    </div>
                                  </div>
                                  <div className='col-sm-2'>
                                    <div className='form-group'>
                                      <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                        <img src={add} alt='' />
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {moreDiv && moreDiv.length > 0 && moreDiv.map((y, ind) => {
                                  if (x?.label === y?.label) {
                                    if (ind < y?.count) {
                                      return (
                                        <div className='row' key={ind}>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>From</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'startTime', e.target.value, 2)} required/>
                                            </div>
                                          </div>
                                          <div className='col-sm-5'>
                                            <div className='form-group'>
                                              <label>To</label>
                                              <input type='time' className='form-control' onChange={(e) => handleInputChange(index, 'endTime', e.target.value, 2)} required/>
                                            </div>
                                          </div>
                                          <div className='col-sm-2'>
                                            <div className='form-group'>
                                              <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                                <img src={add} alt='' />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                  return null; // Return null if the labels don't match or condition is not met
                                })}

                                {/* {moreDiv && moreDiv.length > 0 && selectedDayLabel && selectedDayLabel.length > 0 && selectedDayLabel.map((x, index) => {
                                // Find the matching item in moreDiv
                                const matchingItem = moreDiv.find(y => y.label === x?.label);

                                return (
                                  <div key={index}>
                                    <h4>{x?.label}</h4>

                                    {matchingItem && [...Array(matchingItem.count)].map((_, ind) => (
                                      <div className='row' key={ind}>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>From</label>
                                            <input type='date' className='form-control' />
                                          </div>
                                        </div>
                                        <div className='col-sm-5'>
                                          <div className='form-group'>
                                            <label>To</label>
                                            <input type='date' className='form-control' />
                                          </div>
                                        </div>
                                        <div className='col-sm-2'>
                                          <div className='form-group'>
                                            <button className='add-btn' onClick={() => handleMorediv(x?.label)}>
                                              <img src={add} alt='' />
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                );
                              })} */}
                              </div>
                            );
                          })}
                        </>
                      }
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='form-group'>
                            <label>Enter Session Duration</label>
                            <input type='text' className='form-control' placeholder='60min' onChange={(e) => setSessionDuration(e?.target?.value)} required/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button onClick={generateRecurrenceStrings}>Generate</button>


                  </div>
                }
                <div className='define-left schedule'>
                  <h3>Current Availability Schedule</h3>
                  <button className='manage'>Manage </button>
                  <h4>You can individually edit your availability schedule.</h4>
                  <div className="calender">
                    <Calendar
                      onChange={onChange}
                      value={value}
                      tileClassName={({ date }) => {
                        // const formattedDate = date.toISOString().split('T')[0];
                        const formattedDate = date.toLocaleDateString('en-CA'); 
                        if (markedDates.includes(formattedDate)) {
                          return 'highlight';
                        }
                        return null;
                      }}
                      tileContent={({ date }) => {
                        const formattedDate = date.toLocaleDateString('en-CA');;
                        const isMarked = markedDates.includes(formattedDate);
                        return (
                          <span
                            onClick={() => handleDateClick(date, isMarked)}
                            style={{ cursor: 'pointer', userSelect: 'none' }}
                          >
                            {isMarked ? '-' : '+'}
                          </span>
                        );
                      }}
                    />
                  </div>
                </div>


              </div>
              <OfferingDetails data={data} />
            </div>

          </div>
        </div>
      </div>
    </div >
  )
}

export default AvailabilityOffer